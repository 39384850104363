import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useInteractions, useListItem } from '@floating-ui/react';

import { getExplorationType } from '@/core/exploration';

import { Icon } from '../../components/icon';
import { DefaultHiddenLabels, formatLabels, isModel } from './utils';
import { ExplorationNode } from '.';
import { CellList } from './cell-list';

import styles from './exploration-search.module.scss';

interface ExplorationListProps {
  items: ExplorationNode[];
  activeIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  disabled?: boolean;
  collapsedItems?: Set<string>;
  hideSectionLabels?: boolean;
}

export const ExplorationList = (props: ExplorationListProps) => {
  const {
    items,
    activeIndex,
    getItemProps,
    disabled = false,
    collapsedItems = new Set(),
    hideSectionLabels = false,
  } = props;
  return (
    <ol>
      {items.map((item, i) => (
        <li key={i}>
          <ListItem
            item={item}
            activeIndex={activeIndex}
            getItemProps={getItemProps}
            disabled={disabled}
            isCollapsed={collapsedItems.has(item.exploration.explorationId)}
            hideSectionLabel={hideSectionLabels}
          />
        </li>
      ))}
    </ol>
  );
};

interface ListItemProps {
  item: ExplorationNode;
  activeIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  disabled?: boolean;
  isCollapsed?: boolean;
  hideSectionLabel?: boolean;
}

const ListItem = (props: ListItemProps) => {
  const {
    item,
    activeIndex,
    getItemProps,
    disabled = false,
    isCollapsed = false,
    hideSectionLabel = false,
  } = props;
  const { exploration, url, children = [] } = item;
  const { name, description, labels } = exploration;
  const type = getExplorationType(exploration);

  const { ref, index } = useListItem();

  // useListItem returns -1 the first time around so we can't check if the item is collapsed or not
  // which causes unnecessary animations if we render it in the wrong state
  const shouldRenderChildren = index !== -1;

  return (
    <>
      <Link
        to={url}
        className={classNames(styles.listItem, { [styles.active]: activeIndex === index })}
        ref={ref}
        aria-disabled={disabled}
        {...getItemProps({
          onClick: item.onClick,
        })}>
        <Icon name={type === 'model' ? 'Model' : 'Exploration'} size={32} className={styles.icon} />
        <div className={styles.name}>{name}</div>
        {description !== undefined && (
          <div className={styles.description} title={description ?? undefined}>
            {description}
          </div>
        )}
        {isModel(labels) ? (
          <ul className={styles.labels}>
            {formatLabels(
              labels,
              hideSectionLabel ? [...DefaultHiddenLabels, 'section'] : DefaultHiddenLabels,
            ).map((label, idx) => (
              <li key={idx}>{label}</li>
            ))}
          </ul>
        ) : (
          <div className={styles.sectionCount}>Blocks: {exploration.view.cells.length}</div>
        )}
        {children.length > 0 && (
          <div className={styles.expandIcon}>
            <Icon name={isCollapsed ? 'ChevronDown' : 'ChevronUp'} size={24} />
          </div>
        )}
      </Link>
      {children.length > 0 && shouldRenderChildren && (
        <div
          className={classNames(styles.cellList, {
            [styles.collapsed]: isCollapsed,
          })}
          inert={isCollapsed ? true : undefined}>
          <CellList
            exploration={exploration}
            activeIndex={isCollapsed ? -1 : activeIndex}
            items={children}
            disabled={disabled || isCollapsed}
            getItemProps={getItemProps}
          />
        </div>
      )}
    </>
  );
};
