import { Fragment } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand } from '@visx/scale';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';
import { AxisLeft } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { ScaleLinear, ScaleOrdinal } from 'd3-scale';
import { truncate } from 'lodash';

import { common, model } from '@gosupersimple/types';

import { useState } from 'react';

import { ValueClickHandler } from '@/explore/types';
import { formatPropertyValue } from '@/explore/utils/format';

import { ChartTooltipSingleValue, renderGridColumns } from '../common';
import { CategoryData } from '../grouped-chart/types';
import { increaseBrightness } from '../utils';

import commonStyles, { gridLineColor } from '../charts.module.scss';
import styles from './horizontal-bar-chart.module.scss';

type TooltipData = {
  categoryLabel: string;
  formattedCategoryValue: string;
  valueLabel: string;
  valueKey: string;
  value: number | null;
  color: string;
  type: model.PropertyType | null;
  format?: common.PropertyValueFormat;
};

interface HorizontalBarChartProps {
  categoryFormat: {
    precision?: string;
  };
  onValueClick?: ValueClickHandler;
  data: CategoryData;
  width: number;
  valueScale: ScaleLinear<number, number>;
  colorScale: ScaleOrdinal<string, string>;
  height?: number;
}

const MaxHeight = 600;
const GroupWidth = parseInt(commonStyles.widthPerGroup);
const CategoryPadding = 0.3;
const BarsInnerPadding = 0.4;
const BarMinHeight = 3;
const BarMaxHeight = 30;
const CategoryLabelMaxLength = 20; // Should be adjusted with the group width

export const HorizontalBarChart = ({
  data,
  width,
  colorScale,
  valueScale,
  onValueClick,
  height: chartHeight,
}: HorizontalBarChartProps) => {
  const { showTooltip, hideTooltip, tooltipOpen, tooltipLeft, tooltipTop, tooltipData } =
    useTooltip<TooltipData>();
  const [hoveredCategoryValue, setHoveredCategoryValue] = useState<string | null>(null);

  const handleMouseOver = (event: any, data: TooltipData) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    if (coords === null) {
      return false;
    }
    showTooltip({ tooltipLeft: coords.x, tooltipTop: coords.y, tooltipData: data });
  };

  const barsCount = data.items.length * data.series.length;
  const height = chartHeight ?? Math.min(barsCount * BarMaxHeight, MaxHeight);

  const categoryScale = scaleBand({
    domain: data.items.map((d) => d.formattedCategoryValue),
    padding: CategoryPadding,
    range: [0, height],
  });

  const scaleBandWidth = categoryScale.bandwidth();
  const maxBarsInCategory = Math.max(...data.items.map((item) => Object.keys(item.values).length));
  const barHeight = Math.min(
    BarMaxHeight,
    Math.max(BarMinHeight, scaleBandWidth / maxBarsInCategory / (1 + BarsInnerPadding)),
  );
  const keys = data.series.map(({ key }) => key);

  return (
    <div className={commonStyles.graph}>
      {tooltipData !== undefined ? (
        <ChartTooltipSingleValue
          tooltipTop={tooltipTop}
          tooltipLeft={tooltipLeft}
          tooltipData={{
            label: tooltipData.formattedCategoryValue,
            value: tooltipData.value,
            type: tooltipData.type,
            format: tooltipData.format,
          }}
          tooltipOpen={tooltipOpen}
          categoryLabel={tooltipData.categoryLabel}
          valueLabel={tooltipData.valueLabel}
          seriesColor={colorScale(tooltipData.valueKey)}
        />
      ) : null}
      <svg width={width} height={height}>
        <Group left={GroupWidth}>
          <GridColumns
            width={width}
            height={height}
            scale={valueScale}
            stroke={gridLineColor}
            // eslint-disable-next-line react/no-children-prop
            children={(lines) => renderGridColumns(lines, { width, strokeColor: gridLineColor })}
          />
          {data.items.flatMap((item, idx) => {
            const keysWithValues = keys.filter((key) => item.values[key] !== undefined);
            const valuesCount = keysWithValues.length;
            const barsHeight =
              barHeight * valuesCount + BarsInnerPadding * (valuesCount - 1) * barHeight;
            const groupY =
              (categoryScale(item.formattedCategoryValue) ?? 0) +
              scaleBandWidth / 2 -
              barsHeight / 2;

            return keysWithValues.map((key, barIdx) => {
              const value = item.values[key];
              if (value === null) {
                return null;
              }
              const series = data.series.find((s) => s.key === key);
              const y = groupY + barIdx * (barHeight + BarsInnerPadding * barHeight);
              if (series === undefined) {
                throw new Error(`Series not found for bar chart key: ${key}`);
              }
              return (
                <Fragment key={`${idx}-${key}`}>
                  <BarRounded
                    x={0}
                    y={y}
                    width={valueScale(value)}
                    height={barHeight}
                    radius={4}
                    right
                    fill={
                      hoveredCategoryValue === item.formattedCategoryValue
                        ? increaseBrightness(colorScale(key), 10)
                        : colorScale(key)
                    }
                    onMouseMove={(e) => {
                      return handleMouseOver(e, {
                        value,
                        valueKey: key,
                        categoryLabel: item.label ?? '',
                        formattedCategoryValue: item.formattedCategoryValue ?? '',
                        valueLabel: series?.label ?? key,
                        color: colorScale(key),
                        type: series?.type ?? 'Number',
                        format: series?.format,
                      });
                    }}
                    onMouseOver={() => setHoveredCategoryValue(item.formattedCategoryValue)}
                    onMouseOut={() => {
                      hideTooltip();
                      setHoveredCategoryValue(null);
                    }}
                    onClick={(e) => {
                      onValueClick?.(e.clientX, e.clientY, item.key, item.formattedCategoryValue);
                    }}
                  />
                  {series.showValues && (
                    <text
                      className={styles.valueLabel}
                      x={valueScale(value)}
                      y={y}
                      dx={7}
                      dy={1 + barHeight / 2}
                      fill={increaseBrightness(colorScale(key), 20)}>
                      {formatPropertyValue(value, {
                        type: series.type,
                        format: series.format,
                        style: 'compact',
                      })}
                    </text>
                  )}
                </Fragment>
              );
            });
          })}
          <AxisLeft
            scale={categoryScale}
            stroke={gridLineColor}
            hideTicks
            tickComponent={({ formattedValue, ...tickProps }) => {
              const dataItem = data.items.find(
                (item) => item.formattedCategoryValue === formattedValue,
              );
              return dataItem !== undefined ? (
                <text
                  {...tickProps}
                  className={styles.axisLabel}
                  onClick={(e) =>
                    onValueClick?.(
                      e.clientX,
                      e.clientY,
                      dataItem.key,
                      dataItem.formattedCategoryValue,
                    )
                  }>
                  <title>{formattedValue}</title>
                  {truncate(formattedValue, { length: CategoryLabelMaxLength })}
                </text>
              ) : null;
            }}
            tickValues={categoryScale.domain()} // Always render all tick values
          />
        </Group>
      </svg>
    </div>
  );
};
