import {
  getNodes,
  QuerySortBy,
  useDeleteExplorationMutation,
  useExplorationDataLazyQuery,
  useUpsertExplorationMutation,
} from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';

import { generateExplorationId } from '@/core/exploration';

import { exportExploration } from '../output';
import { DereferencedPipeline, Exploration, Field, QueryVariables } from '../types';
import { convertRecordTypeTypes } from '../input';
import { countRecords, NestedList } from '../grouping';

export const useExplorationUpsert = () => {
  const account = useSelectedAccount();
  const [mutation, { loading, error }] = useUpsertExplorationMutation();

  const upsertExploration = async (exploration: Exploration) => {
    const newExplorationId =
      exploration.options?.explorationForModelId === exploration.options?.explorationSourceId
        ? generateExplorationId()
        : (exploration.options?.explorationSourceId ?? exploration.explorationId);

    const input = exportExploration({ ...exploration, explorationId: newExplorationId });

    const result = await mutation({
      variables: { accountId: account.accountId, input },
      refetchQueries: ['Explorations', 'Navigation'],
    });

    if (error !== undefined) {
      throw new Error(error.message);
    }

    const explorationId = result.data?.upsertExploration.explorationId;
    if (explorationId === undefined) {
      throw new Error('Failed to upsert exploration');
    }

    return { explorationId };
  };

  return { upsertExploration, loading, error };
};

export const useExplorationDelete = () => {
  const account = useSelectedAccount();
  const [mutation, { loading, error }] = useDeleteExplorationMutation();

  const deleteExploration = async (explorationId: string) => {
    await mutation({
      variables: { accountId: account.accountId, explorationId },
      refetchQueries: ['Explorations'],
    });

    if (error !== undefined) {
      throw new Error(error.message);
    }
  };

  return { deleteExploration, loading, error };
};

type CachePolicy = 'no-cache';
type Records = NestedList;

const MaxBatchRecords = 2000; // Maximum number of records to fetch in a single batch
const MaxFetchCount = 10; // Maximum number of fetches to prevent infinite loop

export const usePipelineDataLazyQuery = ({ cachePolicy }: { cachePolicy: CachePolicy }) => {
  const [fetchExplorationData, { loading }] = useExplorationDataLazyQuery({
    fetchPolicy: cachePolicy,
  });

  const fetch = async ({
    accountId,
    pipeline,
    sort,
    variables,
    first = 100,
    after,
    fetchedRecords = [],
    fetchCount = 0,
  }: {
    accountId: string;
    pipeline: DereferencedPipeline;
    sort?: QuerySortBy[];
    variables?: QueryVariables;
    first?: number;
    after?: string;
    fetchedRecords?: Records;
    fetchCount?: number;
  }): Promise<{
    records: Records;
    grouping: string[];
    fields: Field[];
    hasMore: boolean;
    endCursor: string | null;
  }> => {
    const { data } = await fetchExplorationData({
      variables: {
        accountId,
        baseModelId: pipeline.baseModelId,
        pipeline: pipeline.operations,
        sort,
        variables,
        first: Math.min(first, MaxBatchRecords),
        after,
      },
    });

    const records = fetchedRecords.concat(getNodes(data?.account?.query) as Records);
    const hasMore = data?.account?.query?.pageInfo.hasNextPage ?? false;
    const endCursor = data?.account?.query?.pageInfo.endCursor ?? null;
    const recordCount = countRecords(records);

    if (fetchCount < MaxFetchCount && hasMore && recordCount < first) {
      return fetch({
        accountId,
        pipeline,
        sort,
        variables,
        first,
        after: endCursor!,
        fetchedRecords: records,
        fetchCount: ++fetchCount,
      });
    }

    const grouping = (data?.account?.query?.grouping ?? []).map((group) => group.key);
    const fields = convertRecordTypeTypes(data?.account?.query.recordType) ?? [];

    return {
      records,
      grouping,
      fields,
      hasMore,
      endCursor,
    };
  };

  return { fetch, loading };
};
