import { useEffect, useState } from 'react';
import { Annotation, EditorState, StateEffect, type Extension } from '@codemirror/state';
import { EditorView, ViewUpdate, keymap, placeholder } from '@codemirror/view';
import { closeBrackets } from '@codemirror/autocomplete';
import { syntaxHighlighting, HighlightStyle } from '@codemirror/language';
import { standardKeymap, history, historyKeymap } from '@codemirror/commands';
import { tags } from '@lezer/highlight';

import syntaxColor from './syntax-highlighting.module.scss';

export const External = Annotation.define<boolean>();

export interface UseCodemirrorProps {
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onInitialize?: (view: EditorView) => void;
  className?: string;
  autoFocus?: boolean;
  placeholder?: string;
  extensions?: Extension[];
}

const supCodemirrorStyle = HighlightStyle.define([
  { tag: tags.bool, color: syntaxColor.booleanColor },
  { tag: tags.comment, color: syntaxColor.commentColor },
  { tag: tags.keyword, color: syntaxColor.identifierColor },
  { tag: tags.null, color: syntaxColor.nullColor },
  { tag: tags.number, color: syntaxColor.numberColor },
  { tag: tags.bracket, color: syntaxColor.punctuationColor },
  { tag: tags.punctuation, color: syntaxColor.punctuationColor },
  { tag: tags.string, color: syntaxColor.stringColor },
]);

export function useCodeMirror(props: UseCodemirrorProps & { container: HTMLDivElement | null }) {
  const { value, onChange, autoFocus } = props;
  const [container, setContainer] = useState<HTMLDivElement | null>();
  const [view, setView] = useState<EditorView>();
  const [state, setState] = useState<EditorState>();

  const extensions = [
    EditorView.updateListener.of((update) => {
      if (update.docChanged && !isExternalUpdate(update)) {
        onChange(update.state.doc.toString());
      }
    }),
    // Base config for all SUP editors
    EditorState.tabSize.of(2),
    closeBrackets(),
    history(),
    keymap.of(standardKeymap),
    keymap.of(historyKeymap),
    EditorView.domEventHandlers({ keydown: props.onKeyDown }),
    // Do not bubble up undo events
    keymap.of([{ key: 'Mod-z', run: () => true, preventDefault: true, stopPropagation: true }]),
    EditorView.lineWrapping,
    placeholder(props.placeholder ?? ''),
    // Base styling for all SUP editors
    EditorView.theme({}, { dark: true }),
    syntaxHighlighting(supCodemirrorStyle),
    props.extensions ?? [],
  ];

  useEffect(() => {
    if (container && !state) {
      const stateCurrent = EditorState.create({ doc: value, extensions });
      setState(stateCurrent);
      if (!view) {
        const viewCurrent = new EditorView({ state: stateCurrent, parent: container });
        setView(viewCurrent);

        if (props.onInitialize !== undefined) {
          props.onInitialize(viewCurrent);
        }
      }
    }

    return () => {
      if (view) {
        setState(undefined);
        setView(undefined);
      }
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, state]);

  useEffect(() => setContainer(props.container), [props.container]);

  useEffect(
    () => () => {
      if (view) {
        view.destroy();
        setView(undefined);
      }
    },
    [view],
  );

  useEffect(() => {
    if (autoFocus === true && view !== undefined) {
      view.focus();
    }
  }, [autoFocus, view]);

  useEffect(() => {
    if (view === undefined) {
      return;
    }

    view.dispatch({ effects: StateEffect.reconfigure.of(extensions) });
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.extensions, onChange]);

  useEffect(() => {
    if (view === undefined) {
      return;
    }
    const currentValue = view.state.doc.toString();
    if (value !== currentValue) {
      view.dispatch({
        changes: { from: 0, to: currentValue.length, insert: value || '' },
        annotations: [External.of(true)],
      });
    }
  }, [value, view]);

  return { state, setState, view, setView, container, setContainer };
}

const isExternalUpdate = (update: ViewUpdate) =>
  update.transactions.some((tr) => tr.annotation(External) ?? false);
