import { useAccountContext } from '@/lib/accounts/context';

import { mapSort, useExplorationDescribeQueryQuery } from '../../../graphql';
import { dereferencePipeline } from '../../pipeline/utils';
import { Exploration, Pipeline, Sort } from '../../types';
import { useExplorationContext } from '../exploration-context';
import { filterVariablesForPipeline } from '../../utils/variable';

import { CodePreview } from '../code-preview';

interface SqlPreviewProps {
  pipeline: Pipeline;
  exploration: Exploration;
  sort?: Sort;
  onClose: () => void;
}

export const SqlPreview = (props: SqlPreviewProps) => {
  const { account, isFeatureEnabled } = useAccountContext();
  const { getVariables } = useExplorationContext();

  const sort = mapSort(props.sort);
  const pipeline = dereferencePipeline(props.pipeline, props.exploration);

  const { data, loading, error } = useExplorationDescribeQueryQuery({
    variables: {
      accountId: account.accountId,
      baseModelId: pipeline.baseModelId,
      pipeline: pipeline.operations,
      sort,
      variables: filterVariablesForPipeline(pipeline, getVariables()),
      options: { optimize: isFeatureEnabled('optimizeQuery') },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  return (
    <CodePreview
      code={data?.account?.describeQuery.sql}
      language="SQL"
      isLoading={loading}
      hasError={error !== undefined}
      onClose={props.onClose}
    />
  );
};
