import { Node, nodeInputRule } from '@tiptap/react';

export interface VideoOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      addVideo: (src: string) => ReturnType;
    };
  }
}

const VIDEO_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const Video = Node.create({
  name: 'video',
  group: 'block',
  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('src'),
        renderHTML: (attrs) => ({ src: attrs.src }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'video',
        getAttrs: (el) => ({ src: (el as HTMLVideoElement).getAttribute('src') }),
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'video',
      { controls: 'true', style: 'width: 100%', ...HTMLAttributes },
      ['source', HTMLAttributes],
    ];
  },
  addCommands() {
    return {
      addVideo:
        (src: string) =>
        ({ commands }) =>
          commands.insertContent(`<video controls="true" style="width: 100%" src="${src}" />`),
    };
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: VIDEO_INPUT_REGEX,
        type: this.type,
        getAttributes: (match) => {
          const [, , src] = match;

          return { src };
        },
      }),
    ];
  },
});
