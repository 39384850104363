import { useMemo } from 'react';
import { get, omit, set } from 'lodash';

import { useTrackEvent } from '@/lib/analytics';

import { SidebarSection } from '../exploration/sidebar-section';
import { VariableDefinitionForm } from './variable-definition-form';
import { Exploration, VariableCell } from '../types';
import { useExplorationContext } from '../exploration/exploration-context';
import { getVariableDefinitions, replaceDefinition, replaceVariableForExploration } from '../utils';

interface EditVariableCellProps {
  cell: VariableCell;
  setExploration: (exploration: Exploration) => void;
}

export const EditVariableCell = (props: EditVariableCellProps) => {
  const { cell } = props;

  const { exploration, setExploration, parameters } = useExplorationContext();
  const trackEvent = useTrackEvent();

  const existingVariableKeys = useMemo(
    () => getVariableDefinitions(exploration).map((definition) => definition.key),
    [exploration],
  );

  const handleSubmit = (definition: VariableCell['definition']) => {
    trackEvent('Variable Updated', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      definition: definition,
    });

    const previousKey = cell.definition.key;
    const nextKey = definition.key;

    // handle setting default value for enum
    if (
      definition.kind === 'enum' &&
      (parameters[nextKey] === '' ||
        definition.options.every((option) => option.value !== parameters[nextKey]))
    ) {
      // FIXME: Direct mutation
      // eslint-disable-next-line react-compiler/react-compiler
      parameters[nextKey] = definition.defaultValue;
    }

    setExploration(
      replaceVariableForExploration(
        replaceDefinition(definition, exploration, cell.id),
        previousKey,
        nextKey,
      ),
      set(omit(parameters, previousKey), nextKey, get(parameters, previousKey)),
    );
  };

  return (
    <SidebarSection title="Variable">
      <VariableDefinitionForm
        key={cell.definition.key}
        definition={cell.definition}
        existingKeys={existingVariableKeys.filter((key) => key !== cell.definition.key)}
        onSubmit={handleSubmit}
      />
    </SidebarSection>
  );
};
