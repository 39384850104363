import { Section } from '../common';

export const CliSection = () => {
  return (
    <Section title="Command Line Interface">
      <p>
        Download Supersimple CLI tool to easily manage data models. Read more in the{' '}
        <a
          href="https://docs.supersimple.io/guides/managing-the-data-model/using-the-cli"
          target="_blank"
          rel="noreferrer noopener">
          CLI documentation
        </a>
        .
      </p>
    </Section>
  );
};
