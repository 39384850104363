import { Diagnostic, linter } from '@codemirror/lint';
import {
  isInvalidArgumentError,
  isPenguinoError,
  translateToPrql,
  matchReturnType,
  parseT,
} from '@gosupersimple/penguino';

import { invalidArgumentErrorMessage } from '@/explore/utils/penguino';

import { getPenguinoState } from './state';

export const penguinoLinter = linter((view): Diagnostic[] => {
  const value = view.state.doc.toString();

  if (value.trim() === '') {
    return [];
  }

  const { fields, variables, requiredType } = getPenguinoState(view.state);

  try {
    const { returnType } = translateToPrql(value, { fields, variables });

    if (requiredType !== undefined && !matchReturnType(returnType, parseT(requiredType))) {
      return [
        {
          from: 0,
          to: value.length,
          severity: 'error',
          message: `Expected type ${requiredType}, got ${returnType}`,
        },
      ];
    }

    return [];
  } catch (e) {
    if (isInvalidArgumentError(e)) {
      return (e.closestMatch?.arguments ?? [])
        .filter(({ matchingType }) => !matchingType)
        .map((arg) => ({
          message: invalidArgumentErrorMessage(arg),
          severity: 'error',
          from: arg.node?.expression.revert().start ?? 0,
          to: arg.node?.expression.revert().end ?? value.length,
        }));
    }

    if (isPenguinoError(e)) {
      return [
        {
          message: e.message,
          severity: 'error',
          from: e.start,
          to: e.end,
        },
      ];
    }

    if (e instanceof Error) {
      return [{ message: e.message, severity: 'error', from: 0, to: value.length }];
    }

    return [{ message: 'Unknown syntax error', severity: 'error', from: 0, to: 0 }];
  }
});
