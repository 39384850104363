import { ConversationCell, Exploration, Cell, InvalidCell } from '@/explore/types';
import { generateUUID } from '@/lib/utils';

import { unlinkConversationCell } from '../cell/conversation-cell';
import { buildEmptyChatCell } from '../cell';

export function insertConversationStart(
  exploration: Exploration,
  cell: Exclude<Cell, InvalidCell>,
  cellIndex: number,
) {
  const conversationId = generateUUID();
  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: exploration.view.cells.reduce<Cell[]>((result, c, idx) => {
        if (idx !== cellIndex) {
          result.push(c);
          return result;
        }

        result.push(
          {
            ...cell,
            conversationId,
          },
          buildEmptyChatCell(conversationId),
        );

        return result;
      }, []),
    },
  };
}

export function removeConversationEditFlow(
  exploration: Exploration,
  chatCellId: string,
  cellToEdit?: ConversationCell,
) {
  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: exploration.view.cells
        .filter((c) => c.id !== chatCellId)
        .map((c) => {
          if (cellToEdit && cellToEdit.id === c.id) {
            return unlinkConversationCell(cellToEdit);
          }
          return c;
        }),
    },
  };
}
