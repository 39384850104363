import classNames from 'classnames';
import { common } from '@gosupersimple/types';

import { Field } from '../types';
import { Input } from '../../components/form/input';
import { SortingEditor } from './sorting-editor';
import { useEnsureFieldsExist } from './hooks/use-ensure-fields-exist';
import { getSortingKeys } from '../pipeline/operation';

import form from '../../components/form/form.module.scss';

interface SlicingEditorProps {
  fields: Field[];
  slice: common.Slice;
  setSlice(slice: common.Slice): void;
  autoFocus?: boolean;
}

export const SlicingEditor = (props: SlicingEditorProps) => {
  const { slice, setSlice, autoFocus } = props;
  const fields = useEnsureFieldsExist(props.fields, getSortingKeys(slice.sort ?? []));
  return (
    <div className={form.formHorizontal}>
      <div className={classNames(form.formRow)}>
        <label className={form.formLabel}>Take</label>
        <Input
          type="number"
          placeholder="0"
          value={slice.limit ?? ''}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            return setSlice({
              ...slice,
              limit: isNaN(value) ? undefined : value,
            });
          }}
          autoFocus={autoFocus}
        />
      </div>
      <div className={classNames(form.formRow)}>
        <label className={form.formLabel}>skip the first</label>
        <Input
          type="number"
          placeholder="0"
          value={slice.offset ?? ''}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            return setSlice({
              ...slice,
              offset: isNaN(value) ? undefined : value,
            });
          }}
        />
      </div>
      {slice.sort !== undefined && (
        <div className={classNames(form.formRow)}>
          <label className={form.formLabel}>sorted by</label>
          <SortingEditor
            fields={fields}
            sorting={slice.sort}
            setSorting={(sort) => {
              setSlice({
                ...slice,
                sort,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
