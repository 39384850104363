import { Fragment } from 'react';

import classNames from 'classnames';

import { Dropdown, DropdownMenuItem } from '@/components/dropdown';

import { GroupedLegendData, LegendData, LegendItem } from './types';

import styles from './legend.module.scss';

type LegendProps = {
  data: LegendData;
  buildSeriesMenu?: (seriesKey: string) => DropdownMenuItem[];
};
export const Legend = ({ data, buildSeriesMenu }: LegendProps) =>
  data.type === 'basic' ? (
    <BasicLegend items={data.items} buildSeriesMenu={buildSeriesMenu} />
  ) : data.type === 'grouped' ? (
    <GroupedLegend data={data} buildSeriesMenu={buildSeriesMenu} />
  ) : null;

type BasicLegendProps = {
  items: LegendItem[];
  buildSeriesMenu?: (seriesKey: string) => DropdownMenuItem[];
};
const BasicLegend = ({ items, buildSeriesMenu }: BasicLegendProps) => {
  return (
    <div className={styles.legend}>
      {items.map((item, idx) => (
        <Dropdown
          key={idx}
          align="left"
          trigger={(isOpen, setIsOpen) => (
            <div
              className={classNames(styles.legendItem, {
                [styles.clickable]: buildSeriesMenu !== undefined,
              })}
              title={item.label}
              onClick={() => setIsOpen(!isOpen)}>
              <div className={styles.legendColor} style={{ backgroundColor: item.color }} />
              <div className={styles.legendLabel}>{item.label}</div>
            </div>
          )}
          items={buildSeriesMenu?.(item.key) ?? []}
        />
      ))}
    </div>
  );
};

type GroupedLegendProps = {
  data: GroupedLegendData;
  buildSeriesMenu?: (seriesKey: string) => DropdownMenuItem[];
};

export const GroupedLegend = ({ data, buildSeriesMenu }: GroupedLegendProps) => (
  <div className={styles.groupedLegend}>
    {data.groups.map((group, groupIdx) =>
      'items' in group ? (
        <div className={styles.groupItem} key={groupIdx}>
          <div className={styles.groupLabel} title={group.label}>
            {group.label}
          </div>
          <BasicLegend items={group.items} buildSeriesMenu={buildSeriesMenu} />
        </div>
      ) : (
        <div className={styles.groupItem} key={groupIdx}>
          <div className={styles.groupLabel} title={group.label}>
            {group.label}
          </div>
          <div className={styles.valueItems}>
            {group.groups.map(({ label, items }, groupIdx) => (
              <Fragment key={groupIdx}>
                <div className={styles.valueLabel} title={label}>
                  {label}
                </div>
                <BasicLegend items={items} buildSeriesMenu={buildSeriesMenu} />
              </Fragment>
            ))}
          </div>
        </div>
      ),
    )}
  </div>
);
