import { useEffect, useMemo, useState, MouseEvent, useCallback, RefObject } from 'react';
import classNames from 'classnames';
import { first } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useInteractions } from '@floating-ui/react';

import { useExplorationsSearchQuery } from '@/graphql';
import { useBuildAccountUrl, useSelectedAccount } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { useIsScrolled } from '@/lib/hooks/use-is-scrolled';
import { isReplicableCell } from '@/core/cell';
import {
  buildExplorationFromCells,
  getExplorationType,
  isModelExploration,
} from '@/core/exploration';

import { Icon } from '../../components/icon';
import { Loader } from '../../components/loader';
import { Exploration, Metric, Model } from '../types';
import { ExplorationList } from './exploration-list';
import { convertExplorationsArrayTypes } from '../input';
import { isModel } from './utils';
import {
  buildExplorationHashUrl,
  buildExplorationUrl,
  ensureValidExploration,
  getCellBaseModel,
  getExplorationVariables,
  getUnparameterizedExplorations,
} from '../utils';
import { extractCellFromExploration } from '../exploration/utils';
import { flattenLists } from '.';

import styles from './exploration-search.module.scss';

interface SearchResultProps {
  explorations: Exploration[];
  models: Model[];
  metrics: Metric[];
  searchTerm: string;
  /**
   * When set to true, the search results will allow selecting individual cells, full exploration otherwise.
   */
  selectIndividualCells: boolean;
  onClickExploration?: (exploration: Exploration) => void;
  onClickSearchResultRef?: RefObject<(() => void) | null>;
  activeIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
}

export const SearchResults = (props: SearchResultProps) => {
  const {
    explorations,
    models,
    metrics,
    searchTerm,
    onClickExploration,
    selectIndividualCells,
    activeIndex,
    getItemProps,
  } = props;

  const navigate = useNavigate();
  const trackEvent = useTrackEvent();
  const [isScrolled, scrollContainerRef] = useIsScrolled<HTMLDivElement>();
  const [collapsedItems, setCollapsedItems] = useState<Set<string>>(new Set());
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();

  const toggleItemCollapse = (id: string) => {
    setCollapsedItems((collapsedItems) => {
      const set = new Set(collapsedItems);
      set.has(id) ? set.delete(id) : set.add(id);
      return set;
    });
  };

  // Avoid fetching on first letter by using an empty initial value
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const {
    data,
    loading: queryLoading,
    error,
  } = useExplorationsSearchQuery({
    variables: { accountId: account.accountId, search: debouncedSearchTerm },
    skip: debouncedSearchTerm.length === 0,
  });

  const loading = queryLoading || debouncedSearchTerm !== searchTerm;

  const instantResults = useMemo(() => {
    const filteredExplorations = explorations
      .filter((exploration) =>
        exploration.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      )
      .sort((a, b) => (isModelExploration(b) ? 1 : 0) - (isModelExploration(a) ? 1 : 0));

    return filteredExplorations;
  }, [explorations, searchTerm]);

  const semanticResults = useMemo(
    () =>
      getUnparameterizedExplorations(
        convertExplorationsArrayTypes(data?.account?.explorations ?? []),
      )
        .map((exploration) => {
          const variables = getExplorationVariables(exploration);
          return ensureValidExploration(exploration, models, metrics, variables);
        })
        .filter(
          (e) =>
            !instantResults.some(
              (e2) =>
                e.explorationId === e2.explorationId ||
                (getExplorationType(e) === 'model' &&
                  getExplorationType(e2) === 'model' &&
                  getCellBaseModel(first(e.view.cells), e) ===
                    getCellBaseModel(first(e2.view.cells), e2)),
            ),
        ),
    [data, instantResults, metrics, models],
  );

  const buildItem = useCallback(
    (exploration: Exploration) => {
      const url = buildAccountUrl(buildExplorationUrl(exploration));
      return {
        exploration,
        url,
        onClick: (event?: MouseEvent) => {
          event?.preventDefault();
          if (selectIndividualCells && !isModelExploration(exploration)) {
            toggleItemCollapse(exploration.explorationId);
            return;
          }
          if (onClickExploration) {
            onClickExploration(exploration);
          } else {
            navigate(url);
          }
        },
        children: exploration.view.cells
          .filter(() => !isModelExploration(exploration))
          .filter(() => selectIndividualCells)
          .filter(isReplicableCell)
          .map((cell) => {
            const { cell: extractedCell, dependencies } = extractCellFromExploration(
              cell.id,
              exploration,
            );
            const outputExploration = buildExplorationFromCells([extractedCell, ...dependencies]);
            const url = buildAccountUrl(buildExplorationHashUrl(outputExploration));
            return {
              cell,
              url,
              onClick: (event?: MouseEvent) => {
                event?.preventDefault();
                if (onClickExploration) {
                  onClickExploration(outputExploration);
                } else {
                  navigate(url);
                }
              },
            };
          }),
      };
    },
    [buildAccountUrl, navigate, onClickExploration, selectIndividualCells],
  );

  const instantResultsList = useMemo(
    () => instantResults.map(buildItem),
    [buildItem, instantResults],
  );
  const semanticResultsList = useMemo(
    () => semanticResults.map(buildItem),
    [buildItem, semanticResults],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      const modelCount = instantResults.filter((e) => isModel(e.labels)).length;
      trackEvent('Exploration Search', {
        searchTerm: searchTerm,
        instantCount: instantResults.length,
        modelCount,
        explorationCount: instantResults.length - modelCount,
      });
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (loading || data === undefined) {
      return;
    }
    const modelCount = semanticResults.filter((e) => isModel(e.labels)).length;
    trackEvent('Exploration Search Results', {
      searchTerm: debouncedSearchTerm,
      instantCount: instantResults.length,
      resultCount: semanticResults.length,
      modelCount,
      explorationCount: semanticResults.length - modelCount,
    });
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.account?.explorations]);

  const handleItemClick = useCallback(() => {
    const flattenedItems = flattenLists([
      { children: instantResultsList },
      { children: semanticResultsList },
    ]);
    const item = flattenedItems.at(activeIndex !== null && activeIndex !== -1 ? activeIndex : 0);
    if (item !== undefined) {
      item.onClick();
    }
  }, [activeIndex, instantResultsList, semanticResultsList]);

  useEffect(() => {
    if (props.onClickSearchResultRef) {
      props.onClickSearchResultRef.current = handleItemClick;
    }
  }, [handleItemClick, props.onClickSearchResultRef]);

  return (
    <>
      <div
        className={classNames(styles.searchResults, { [styles.scrolled]: isScrolled })}
        ref={scrollContainerRef}>
        {instantResultsList.length > 0 && (
          <ExplorationList
            items={instantResultsList}
            activeIndex={activeIndex}
            getItemProps={getItemProps}
            collapsedItems={collapsedItems}
          />
        )}
        {loading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        {!loading && semanticResultsList.length > 0 && (
          <ExplorationList
            items={semanticResultsList}
            activeIndex={activeIndex}
            getItemProps={getItemProps}
            collapsedItems={collapsedItems}
          />
        )}
        {!loading && error && (
          <div className={styles.error}>
            <Icon name="CloudDrizzle" size={16} />
            Semantic search failed. Only exact matches are displayed.
          </div>
        )}
      </div>
    </>
  );
};
