import { entries, groupBy, pick, uniq, uniqBy } from 'lodash';

import {
  GroupedChartData,
  isPrimaryGroupSeriesMetaData,
  isSecondaryGroupSeriesMetaData,
} from '../grouped-chart/types';
import { LegendData } from './types';
import { getSeries } from '../grouped-chart/utils';

export const getLegendData = (chartData: GroupedChartData): LegendData => {
  const series = getSeries(chartData);

  if (series.every(isSecondaryGroupSeriesMetaData)) {
    const groups = groupBy(
      uniqBy(
        series.map((s) => pick(s, ['primaryGroupLabel', 'secondaryGroupLabel'])),
        (g) => `${g.primaryGroupLabel}_${g.secondaryGroupLabel}`,
      ),
      (g) => g.primaryGroupLabel,
    );

    return {
      type: 'grouped' as const,
      groups: entries(groups).map(([label, items]) => ({
        label,
        groups: items.map(({ primaryGroupLabel, secondaryGroupLabel }) => ({
          label: secondaryGroupLabel,
          items: getSeries(chartData, { primaryGroupLabel, secondaryGroupLabel }).map(
            ({ key, compactLabel, chartType, color }) => ({
              key,
              label: compactLabel,
              color,
              chartType,
            }),
          ),
        })),
      })),
    };
  }

  if (series.every(isPrimaryGroupSeriesMetaData)) {
    const groups = uniq(series.map((d) => d.primaryGroupLabel));

    return {
      type: 'grouped' as const,
      groups: groups.map((label) => ({
        label,
        items: uniqBy(
          getSeries(chartData, { primaryGroupLabel: label }).map(
            ({ key, compactLabel, chartType, color }) => ({
              key,
              label: compactLabel,
              color,
              chartType,
            }),
          ),
          ({ label }) => label,
        ),
      })),
    };
  }

  const legend = {
    type: 'basic' as const,
    items: series.map(({ key, compactLabel, chartType, color }) => ({
      key,
      label: compactLabel,
      color,
      chartType,
    })),
  };

  return legend;
};
