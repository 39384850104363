import {
  endOfYear,
  format,
  lastDayOfMonth,
  lastDayOfWeek,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

import { DateFormat } from '@/lib/date';

export const getRange = (range: 'week' | 'month' | 'year' | number) => {
  let toDate = new Date();
  let fromDate: Date;

  switch (range) {
    case 'week':
      toDate = startOfWeek(toDate, { weekStartsOn: 1 });
      fromDate = subDays(toDate, 7);
      toDate = lastDayOfWeek(fromDate, { weekStartsOn: 1 });
      break;
    case 'month':
      toDate = startOfMonth(toDate);
      fromDate = subMonths(toDate, 1);
      toDate = lastDayOfMonth(fromDate);
      break;
    case 'year':
      toDate = endOfYear(subYears(toDate, 1));
      fromDate = startOfYear(toDate);
      break;
    default:
      fromDate = subDays(toDate, range);
      toDate = subDays(toDate, 1);
      break;
  }

  return {
    from: format(fromDate.toISOString(), DateFormat),
    to: format(toDate.toISOString(), DateFormat),
  };
};
