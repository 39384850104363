import { FormEvent, useRef, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { sanitizeEmail } from '@/lib/utils/email';
import { Card } from '@/components/gradient-layout/cards';
import { Input } from '@/components/form/input';
import { Button } from '@/components/button';
import { Checkbox } from '@/components/form/checkbox';
import { useToastContext } from '@/components/toast';

import commonStyles from '../public.module.scss';

export const PasswordLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const auth = getAuth();
  const addToast = useToastContext();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch {
      setIsSubmitting(false);
      addToast({
        title: 'Bad login',
        content: () => 'Username or Password is incorrect',
        kind: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <Card>
        <div className={commonStyles.title}>Log in with email</div>

        <div className={commonStyles.fields}>
          <Input
            type="text"
            size="large"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(sanitizeEmail(event.target.value))}
          />

          <Input
            type={isShowingPassword ? 'text' : 'password'}
            size="large"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <label className={commonStyles.showPassword}>
            <Checkbox
              checked={isShowingPassword}
              onChange={() => setIsShowingPassword(() => !isShowingPassword)}
            />{' '}
            Show password
          </label>
        </div>

        <Button type="submit" className={commonStyles.button} size="large" loading={isSubmitting}>
          Log in
        </Button>
      </Card>
    </form>
  );
};
