import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { debounce, isEqual, throttle } from 'lodash';

import { countRecords, sliceNestedList } from '@/explore/grouping';

import { DataTableRow } from '../datatable';
import { PageIndexes } from './types';

const MaxItems = 500;

export type SetFinalIndexesFn = Dispatch<SetStateAction<PageIndexes>>;

/**
 * Get the initial internal indexes for generating slices.
 * Use the previously detected pageSize as an optimization for determining the new correct page size (which is likely the same)
 */
const getInitialIndexes = (
  indexes: PageIndexes,
  records: DataTableRow[],
  pageSize = 0,
): {
  startIndex: number;
  endIndex: number;
} => {
  if (indexes.endIndex === null) {
    return {
      startIndex: indexes.startIndex,
      endIndex: indexes.startIndex + Math.max(1, pageSize),
    };
  } else if (indexes.startIndex === null) {
    // Ensure going to the 'previous' page will produce a full page even if the container has grown
    const adjustedEndIndex = Math.min(
      countRecords(records),
      Math.max(indexes.endIndex as number, pageSize),
    );
    return {
      startIndex: Math.max(0, adjustedEndIndex - Math.max(1, pageSize)),
      endIndex: adjustedEndIndex,
    };
  }
  return indexes;
};

const getChildrenHeight = (container: HTMLElement) => {
  return Array.from(container.children).reduce((acc, el) => acc + el.clientHeight, 0);
};

/**
 * A hook for paginating records to fit the container by generating slices and measuring the rendered output until a "maximum slice" is found.
 * If the container does not have a specified height we default to the old behaviour of slicing N items.
 *
 * The startIndex and endIndex parameters define the indexes for 'current page' of the records.
 * One of the indexes can be passed as null. This is the 'loose' index and is then determined by how many items fit into the container.
 * The other then serves as an 'anchor' index.
 */
export const useFitContainerSlice = (
  containerRef: RefObject<HTMLElement | null>,
  records: DataTableRow[],
  indexes: PageIndexes,
  hasFixedHeight: boolean,
  setFinalIndexes: SetFinalIndexesFn,
  defaultPageSize = 10,
  isLoading = false,
): DataTableRow[] => {
  const { startIndex, endIndex } = indexes;
  const isResolvingForwards = endIndex === null;
  const isResolvingBackwards = startIndex === null;

  if (isResolvingBackwards && isResolvingForwards) {
    throw new Error('Either startIndex or endIndex must be provided');
  }

  const [wasHeightFixed, setWasHeightFixed] = useState(hasFixedHeight);
  const hasFixedHeightRef = useRef(hasFixedHeight);
  // eslint-disable-next-line react-compiler/react-compiler
  hasFixedHeightRef.current = hasFixedHeight;
  const pageSizeRef = useRef<number>(0);

  // Internal non-null indexes for generating slices
  const [internalIndexes, setInternalIndexes] = useState(
    getInitialIndexes(indexes, records, hasFixedHeight ? 0 : defaultPageSize),
  );
  // Denotes that if the next slice does not exceed the container bounds, it is the final result
  const [hasExceededContainerBounds, setHasExceededContainerBounds] = useState(false);

  const hasAnchorIndexChanged =
    (isResolvingForwards && startIndex !== internalIndexes.startIndex) ||
    (isResolvingBackwards && endIndex !== internalIndexes.endIndex);

  if (hasAnchorIndexChanged) {
    // Reset internal state for a new run
    // eslint-disable-next-line react-compiler/react-compiler
    const initialIndexes = getInitialIndexes(indexes, records, pageSizeRef.current);
    setInternalIndexes(initialIndexes);
    // sync the anchor in 'input' indexes in case the anchor has changed
    setFinalIndexes(
      startIndex === null
        ? {
            startIndex: null,
            endIndex: initialIndexes.endIndex,
          }
        : {
            startIndex: initialIndexes.startIndex,
            endIndex: null,
          },
    );
  }

  // For natural height, we need to start expanding from a small page size to allow the container to shrink.
  // URL-based state causes the cell height to become 'natural' for a brief moment
  // after dragging causing the render to flicker, so we wait and check if the height really is
  // natural before resetting the indexes
  // eslint-disable-next-line react-compiler/react-compiler
  const resetIndexesForNaturalExpansion = debounce(() => {
    if (hasFixedHeightRef.current) {
      return;
    }
    const initialIndexes = getInitialIndexes(
      { startIndex: indexes.startIndex ?? 0, endIndex: null },
      records,
      defaultPageSize,
    );
    setInternalIndexes(initialIndexes);
    setFinalIndexes({
      startIndex: initialIndexes.startIndex ?? 0,
      endIndex: null,
    });
  }, 60);

  const heightBecameNatural = wasHeightFixed && !hasFixedHeight;
  if (heightBecameNatural) {
    resetIndexesForNaturalExpansion();
  }

  useEffect(() => {
    setWasHeightFixed(hasFixedHeight);
  }, [hasFixedHeight]);

  // Find the maximum slice that fits the container
  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container === null || isLoading) {
      return;
    }
    if (
      (!isResolvingForwards && !isResolvingBackwards) ||
      hasAnchorIndexChanged ||
      heightBecameNatural
    ) {
      // Indexes already found or have just been re-set
      return;
    }

    const recordsCount = countRecords(records);
    const itemCount = internalIndexes.endIndex - internalIndexes.startIndex;
    const isExceedingContainerBounds = container.scrollHeight > container.clientHeight;
    const minItems = hasFixedHeight ? 1 : defaultPageSize;
    const minItemsReached = itemCount >= minItems || itemCount === recordsCount;

    const canExpandIndexes =
      (!isExceedingContainerBounds &&
        !hasExceededContainerBounds &&
        (!isResolvingBackwards || internalIndexes.startIndex > 0) &&
        (!isResolvingForwards || internalIndexes.endIndex < recordsCount) &&
        itemCount < MaxItems) ||
      !minItemsReached;

    if (canExpandIndexes && !hasFixedHeight) {
      // For natural height, we temporarily fix the height and detect overflow the same way
      containerRef.current?.style.setProperty('height', `${containerRef.current?.clientHeight}px`);
    }

    // Expand or contract the loose index until we find the maximum slice that fits the container
    if (canExpandIndexes) {
      // Expand indexes and rerender
      // As an optimization we estimate the correct page size based on the average item height
      const avgItemHeight = getChildrenHeight(container) / itemCount;
      if (avgItemHeight === 0) {
        return;
      }
      const maxItemsEstimate = Math.ceil(container.clientHeight / avgItemHeight);
      const expandIndexesBy = Math.max(1, maxItemsEstimate - itemCount);

      setInternalIndexes({
        startIndex: isResolvingForwards
          ? internalIndexes.startIndex
          : internalIndexes.startIndex - expandIndexesBy,
        endIndex: isResolvingBackwards
          ? internalIndexes.endIndex
          : internalIndexes.endIndex + expandIndexesBy,
      });
    } else if (isExceedingContainerBounds && itemCount > 0 && itemCount > minItems) {
      // Disable expansion and start contracting the loose index until we no longer exceed the bounds
      setHasExceededContainerBounds(true);
      setInternalIndexes({
        startIndex: isResolvingForwards
          ? internalIndexes.startIndex
          : internalIndexes.startIndex + 1,
        endIndex: isResolvingBackwards ? internalIndexes.endIndex : internalIndexes.endIndex - 1,
      });
    } else {
      // We have found the maximum slice that fits the container
      pageSizeRef.current = itemCount;
      setHasExceededContainerBounds(false);
      setFinalIndexes(internalIndexes);
      containerRef.current?.style.removeProperty('height');
    }
  }, [
    containerRef,
    defaultPageSize,
    hasAnchorIndexChanged,
    hasExceededContainerBounds,
    hasFixedHeight,
    heightBecameNatural,
    internalIndexes,
    isLoading,
    isResolvingBackwards,
    isResolvingForwards,
    records,
    setFinalIndexes,
  ]);

  // Respond to external changes to container height
  const heightRef = useRef<number | null>(null);
  useEffect(() => {
    const container = containerRef.current;
    if (container === null || isLoading || heightBecameNatural) {
      return;
    }
    if (heightRef.current === null) {
      heightRef.current = container.clientHeight;
    }

    const observer = new ResizeObserver(
      throttle(() => {
        if (
          heightRef.current !== container.clientHeight &&
          container.clientHeight > 0 &&
          !isResolvingBackwards &&
          !isResolvingForwards
        ) {
          // Height has changed, so let's find the new page size by finding a new endIndex
          // Using the retained page size helps minimize the number of re-renders
          setFinalIndexes((prevState) => {
            if (!isEqual(prevState, indexes)) {
              return prevState; // Avoid overwriting other state changes
            }
            return {
              startIndex: internalIndexes.startIndex,
              endIndex: null,
            };
          });
          setHasExceededContainerBounds(false);
        }
        heightRef.current = container.clientHeight;
      }, 100),
    );

    observer.observe(container);
    return () => observer.disconnect();
  }, [
    containerRef,
    heightBecameNatural,
    indexes,
    internalIndexes.startIndex,
    isLoading,
    isResolvingBackwards,
    isResolvingForwards,
    setFinalIndexes,
  ]);

  if (isResolvingBackwards || isResolvingForwards) {
    return sliceNestedList(records, internalIndexes.startIndex, internalIndexes.endIndex);
  }

  return sliceNestedList(records, startIndex, endIndex);
};
