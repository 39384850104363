import { useLayoutContext } from './layout-context';

import styles from './hide-in-embedded.module.scss';

interface HideInEmbeddedProps {
  children: React.ReactNode;
  renderHidden?: boolean;
}

export const HideInEmbedded = (props: HideInEmbeddedProps) => {
  const { children, renderHidden = false } = props;
  const { isEmbeddedMode } = useLayoutContext();
  return isEmbeddedMode ? (
    renderHidden ? (
      <div className={styles.hidden}>{children}</div>
    ) : null
  ) : (
    children
  );
};
