type SeriesValueDotProps = {
  cx: number;
  cy: number;
  color: string;
};

export const SeriesValueDot = (props: SeriesValueDotProps) => (
  <circle
    cx={props.cx}
    cy={props.cy}
    r={3}
    fill={props.color}
    stroke="white"
    strokeOpacity={0.2}
    strokeWidth={1}
    pointerEvents="none"
  />
);
