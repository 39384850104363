import { useState } from 'react';
import { first } from 'lodash';

import { Cell, Model } from '@/explore/types';
import { SearchInput } from '@/components/form/search-input';
import { fieldToOption } from '@/explore/edit-pipeline/utils';
import { PipelineModel } from '@/explore/components/pipeline/pipeline-model';
import { getPipelineColorByCellId } from '@/explore/pipeline/format';

import { isRecordsLikeCell } from '@/core/cell';

import { buildExplorationFromCells, buildModelExploration } from '@/core/exploration';

import { PipelineSearchModal } from '../../exploration-search/pipeline-search';
import { getFieldsByCellId } from '../utils';
import { getUnparameterizedExplorations, isMasterCell } from '../../utils';
import { useMetadataContext } from '../../metadata-context';
import { useExplorationContext } from '../exploration-context';
import { useExplorationCellContext } from '../exploration-cell-context';
import { CombineItemState } from './types';
import { getDefaultJoinKey } from './utils';

import form from '@/components/form/form.module.scss';
import style from './combine.module.scss';

interface CombineItemEditorProps {
  item?: CombineItemState;
  setItem: (item: CombineItemState | undefined) => void;
}

export const CombineItemEditor = (props: CombineItemEditorProps) => {
  const [showList, setShowList] = useState(false);
  const { exploration } = useExplorationContext();
  const { models, metrics, explorations } = useMetadataContext();

  const handleSelectModel = (model: Model) => {
    const fields = model.properties.map(({ key, name, type }) => ({ key, name, type }));
    props.setItem({
      kind: 'model',
      modelId: model.modelId,
      title: model.name,
      fields,
      joinKey: getDefaultJoinKey(fields),
      allFields: fields,
    });
    setShowList(false);
  };

  const handleSelectCell = (cell: Cell, dependencies: Cell[]) => {
    if (!isRecordsLikeCell(cell)) {
      return;
    }

    const explorationWithCells = buildExplorationFromCells([...dependencies, cell]);
    const fields = getFieldsByCellId(cell.id, explorationWithCells, models, [], metrics);

    props.setItem({
      kind: 'cell',
      cellId: cell.id,
      cells: [...dependencies, cell],
      title: cell.title ?? '',
      fields,
      joinKey: getDefaultJoinKey(fields),
      allFields: fields,
    });
    setShowList(false);
  };

  return (
    <>
      {showList && (
        <PipelineSearchModal
          explorations={getUnparameterizedExplorations(explorations)}
          exploration={exploration}
          onSelectModel={handleSelectModel}
          onSelectCell={handleSelectCell}
          onClose={() => setShowList(false)}
        />
      )}

      {props.item === undefined ? (
        <div className={style.pipeline}>
          <button className={form.placeholderButton} onClick={() => setShowList(true)}>
            Select data…
          </button>
        </div>
      ) : (
        <CombineItemEditorInner item={props.item} setItem={props.setItem} />
      )}
    </>
  );
};

const CombineItemEditorInner = (props: Required<CombineItemEditorProps>) => {
  const { item } = props;
  const { exploration, selectCell, scrollToCell } = useExplorationContext();
  const { getModel } = useMetadataContext();
  const { cell: parentCell } = useExplorationCellContext();

  const parentPipelineColor =
    item?.kind === 'cell' && isMasterCell(item.cellId, exploration)
      ? getPipelineColorByCellId(exploration, item.cellId)
      : undefined;

  const handleDelete = () => props.setItem(undefined);

  const handleImport = () => {
    if (item === undefined || item.kind !== 'model') {
      throw new Error('Trying to import non-existent or invalid combine item');
    }

    const modelExploration = buildModelExploration(getModel(item.modelId));
    const cell = first(modelExploration.view.cells);
    if (cell === undefined || !('pipeline' in cell) || cell.pipeline.pipelineId === undefined) {
      throw new Error('Model exploration generation produced invalid cell');
    }

    props.setItem({ ...item, kind: 'cell', cellId: cell.id, cells: [cell] });

    selectCell(parentCell.id);
    scrollToCell(parentCell.id);
  };

  return (
    <div className={style.pipeline}>
      <PipelineModel
        title="From"
        color={parentPipelineColor}
        accessories={[
          ...(item.kind === 'model'
            ? [{ icon: 'Instance' as const, title: 'Edit Pipeline', onClick: handleImport }]
            : []),
          { icon: 'Trash2', title: 'Clear selection', onClick: handleDelete },
        ]}>
        {item.title}
      </PipelineModel>

      {item.fields.length === 0 ? (
        <div className={form.placeholderButton}>
          No matching fields found. Please select another field from the opposite side.
        </div>
      ) : (
        <SearchInput
          options={item.fields.map(fieldToOption)}
          value={item.joinKey}
          onChange={(value) => props.setItem({ ...item, joinKey: value })}
        />
      )}
    </div>
  );
};
