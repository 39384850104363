import { z } from 'zod';
import { pick } from 'lodash';

import { isPipelineWithParent } from '@/core/pipeline';
import {
  Exploration,
  Metric,
  Model,
  Pipeline,
  PipelineStateRelation,
  RecordsLikeCell,
  Relation,
} from '@/explore/types';
import { getExplorationVariables, restoreInvalidOperation } from '@/explore/utils';
import { dereferencePipeline, formatRelationLabel } from '@/explore/pipeline/utils';
import { getFinalStateOrThrow, findModelPropertyField } from '@/explore/pipeline/state';
import { getCellByPipelineId } from '@/explore/exploration/utils';
import { getJoinKeys, getModelOrThrow } from '@/explore/model/utils';
import { Option } from '@/components/form/types';
import { isRecordsLikeCell } from '@/core/cell';

import { isRecursiveOperation } from '.';

const relationOptionValueSchema = z.union([
  // On This Page relation to block via model relation
  z.object({
    baseModelId: z.string(),
    key: z.string(),
    pipelineId: z.string(),
  }),
  //  Normal relation to model
  z.object({
    baseModelId: z.string(),
    key: z.string(),
  }),
  // Custom join to any block
  z.object({
    pipelineId: z.string(),
  }),
]);

export const constructRelationOptionValue = (
  reference: z.infer<typeof relationOptionValueSchema>,
) => JSON.stringify(pick(reference, 'baseModelId', 'key', 'pipelineId'));

export const deconstructRelationOptionValue = (value: string) => {
  return relationOptionValueSchema.parse(JSON.parse(value));
};

export const parseRelationOptionValue = (relations: PipelineStateRelation[], value: string) => {
  const reference = deconstructRelationOptionValue(value);
  if ('key' in reference) {
    const relation = relations.find(
      ({ baseModelId, key }) => key === reference.key && baseModelId === reference.baseModelId,
    );
    if (relation === undefined) {
      throw new Error(`Unable to find relation with key ${reference.key}`);
    }
    return {
      relation,
      ...('pipelineId' in reference ? { pipelineId: reference.pipelineId } : {}),
    };
  }
  return { pipelineId: reference.pipelineId, relation: undefined };
};

export const getRelation = (
  relations: PipelineStateRelation[],
  key: string,
  baseModelId?: string,
) => {
  return relations.find(
    (relation) =>
      relation.key === key && (baseModelId === undefined || relation.baseModelId === baseModelId),
  );
};

export const getValidRelationTypesForOperation = (
  operation: 'relationAggregate' | 'addRelatedColumn' | undefined,
) => {
  switch (operation) {
    case 'relationAggregate':
      return ['hasMany', 'manyToMany'];
    case 'addRelatedColumn':
      return ['hasOne', 'hasOneThrough'];
    default:
      return ['hasMany', 'manyToMany', 'hasOne', 'hasOneThrough'];
  }
};

const getCellFinalState = (
  cell: RecordsLikeCell,
  exploration: Exploration,
  models: Model[],
  metrics: Metric[],
) => {
  const fullPipeline = dereferencePipeline(cell.pipeline, exploration);
  return getFinalStateOrThrow(fullPipeline.baseModelId, fullPipeline.operations, {
    models,
    variables: getExplorationVariables(exploration),
    metrics,
  });
};

const pipelineContainsReference = (
  pipelineId: string,
  pipeline: Pipeline,
  exploration: Exploration,
): boolean => {
  return (
    (isPipelineWithParent(pipeline) && pipeline.parentId === pipelineId) ||
    pipeline.operations.some((item) => {
      const operation = restoreInvalidOperation(item);
      if (!isRecursiveOperation(operation) || operation.parameters.pipelineId === undefined) {
        return false;
      }

      if (operation.parameters.pipelineId === pipelineId) {
        return true;
      }

      const cell = getCellByPipelineId(operation.parameters.pipelineId, exploration);

      return (
        cell !== undefined && pipelineContainsReference(pipelineId, cell.pipeline, exploration)
      );
    })
  );
};

/**
 * For a given cell get the matching relations for the current model.
 */
const findRelationsForCell = (
  relations: PipelineStateRelation[],
  models: Model[],
  metrics: Metric[],
  cell: RecordsLikeCell,
  exploration: Exploration,
) => {
  const finalState = getCellFinalState(cell, exploration, models, metrics);
  return relations.filter((relation) => {
    const joinKeys = getJoinKeys(relation, relations, models);
    return (
      findModelPropertyField(finalState.fields, relation.modelId, joinKeys.joinKeyOnRelated) !==
      undefined
    );
  });
};

/**
 * Create select field option for a normal relation.
 */
const createRelationOption = (
  relation: PipelineStateRelation[][number],
  models: Model[],
): Option => {
  const baseModel = getModelOrThrow(models, relation.baseModelId);
  return {
    value: constructRelationOptionValue({
      key: relation.key,
      baseModelId: baseModel.modelId,
    }),
    label: formatRelationLabel({
      relationName: relation.name,
      relatedModelName: getModelOrThrow(models, relation.modelId).name,
      baseModelName: baseModel.name,
    }),
  };
};

/**
 * Create select field options for normal relations.
 */
export const createRelationOptions = (
  relations: PipelineStateRelation[],
  models: Model[],
): Option[] => relations.map((relation) => createRelationOption(relation, models));

/**
 * Create select field option for a cell.
 */
const createOnThisPageRelationOption = (
  relation: PipelineStateRelation,
  relations: Relation[],
  cell: RecordsLikeCell,
  currentCell: RecordsLikeCell,
  exploration: Exploration,
) => {
  const isCircularReference = pipelineContainsReference(
    currentCell.pipeline.pipelineId,
    cell.pipeline,
    exploration,
  );

  return {
    value: constructRelationOptionValue({
      key: relation.key,
      baseModelId: relation.baseModelId,
      pipelineId: cell.pipeline.pipelineId,
    }),
    label:
      relations.length === 1
        ? (cell.title ?? '(Untitled)')
        : `${cell.title ?? '(Untitled)'} as ${relation.name}`,
    disabled: isCircularReference,
    title: isCircularReference
      ? 'Adding data from this table would create a circular reference'
      : '',
  };
};

const createCustomRelationOption = (
  cell: RecordsLikeCell,
  currentCell: RecordsLikeCell,
  exploration: Exploration,
) => {
  const isCircularReference = pipelineContainsReference(
    currentCell.pipeline.pipelineId,
    cell.pipeline,
    exploration,
  );

  return {
    value: constructRelationOptionValue({ pipelineId: cell.pipeline.pipelineId }),
    label: cell.title ?? '(Untitled)',
    disabled: isCircularReference,
    title: isCircularReference
      ? 'Adding data from this table would create a circular reference'
      : '',
  };
};

export const getOnThisPageRelationOptions = (
  relations: PipelineStateRelation[],
  models: Model[],
  metrics: Metric[],
  exploration: Exploration,
  currentCell: RecordsLikeCell,
) =>
  exploration.view.cells
    .filter(({ id }) => id !== currentCell.id)
    .filter(isRecordsLikeCell)
    .reduce(
      (acc, cell) => {
        const availableRelations = findRelationsForCell(
          relations,
          models,
          metrics,
          cell,
          exploration,
        );

        return acc.concat(
          availableRelations.map((relation) =>
            createOnThisPageRelationOption(
              relation,
              availableRelations,
              cell,
              currentCell,
              exploration,
            ),
          ),
        );
      },
      [] as { value: string; label: string }[],
    );

export const getCustomRelationOptions = (exploration: Exploration, currentCell: RecordsLikeCell) =>
  exploration.view.cells.reduce<{ value: string; label: string }[]>((acc, cell) => {
    if (isRecordsLikeCell(cell) && cell.id !== currentCell.id) {
      acc.push(createCustomRelationOption(cell, currentCell, exploration));
      return acc;
    }
    return acc;
  }, []);
