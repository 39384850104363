export const LinearIcon = ({ size = 12 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
      fill="#5E6AD2"
    />
    <path
      d="M4.19607 13.8436C4.16047 13.6919 4.34126 13.5963 4.45149 13.7065L10.2935 19.5485C10.4037 19.6587 10.3081 19.8395 10.1564 19.8039C7.20824 19.1123 4.88765 16.7918 4.19607 13.8436ZM4.0003 11.5023C3.99748 11.5476 4.01452 11.5918 4.04663 11.6239L12.376 19.9534C12.4082 19.9855 12.4524 20.0026 12.4977 19.9997C12.8768 19.9761 13.2488 19.9261 13.6117 19.8515C13.734 19.8264 13.7765 19.6761 13.6882 19.5879L4.41215 10.3118C4.32385 10.2235 4.17356 10.2659 4.14844 10.3883C4.0739 10.7512 4.02391 11.1232 4.0003 11.5023ZM4.67375 8.75286C4.64711 8.81267 4.66068 8.88256 4.70697 8.92886L15.0711 19.293C15.1174 19.3393 15.1873 19.3529 15.2471 19.3263C15.5329 19.199 15.8099 19.0554 16.0768 18.8968C16.1652 18.8443 16.1788 18.7229 16.1061 18.6503L5.34971 7.89387C5.27705 7.82121 5.15567 7.83485 5.10319 7.92318C4.94458 8.19013 4.80104 8.46709 4.67375 8.75286ZM6.02539 6.89184C5.96618 6.83262 5.96251 6.73765 6.0183 6.67518C7.48472 5.03349 9.61782 4 11.9923 4C16.4148 4 20 7.58517 20 12.0077C20 14.3822 18.9665 16.5153 17.3248 17.9817C17.2624 18.0375 17.1674 18.0338 17.1081 17.9746L6.02539 6.89184Z"
      fill="#F0F0F2"
    />
  </svg>
);
