import { useRef, useState, useEffect, useMemo } from 'react';
import { DayPicker, DateRange as PickerDateRange } from 'react-day-picker';
import { format, parseISO } from 'date-fns';
import { useOnClickOutside } from 'usehooks-ts';
import { isEmpty } from 'lodash';

import {
  includesTime,
  combineDateTime,
  DateFormat,
  DateTimeFormat,
  DateRange,
  DateFriendlyFormat,
  getHumanReadableRange,
  DateFriendlyTimeFormat,
} from '@/lib/date';
import { useKeyPress } from '@/lib/hooks';
import { notNil } from '@/lib/utils';

import { Icon } from '../../icon';
import { InlineButton } from '../../button';
import { TimeInput } from '../time-input';
import { Input } from '../input';
import { FormInputSize } from '../types';
import { CheckboxWithLabel } from '../checkbox';
import { getRange } from './utils';

import 'react-day-picker/style.css';

import styles from './datetime-input.module.scss';

import './day-selector.scss';

interface SharedProps {
  value: Partial<DateRange>;
  isRequired?: boolean;
  mode?: 'single' | 'range';
  onChange: (value: Partial<DateRange>) => void;
}

interface SharedPickerProps {
  month?: string;
  setMonth: (month: string) => void;
}

interface PickerProps extends SharedProps, SharedPickerProps {
  isPresetChange: boolean;
  onChange: (value: Partial<DateRange>) => void;
}

const DatePicker = (props: PickerProps) => {
  const {
    value: initialValue,
    isRequired,
    isPresetChange,
    mode = 'single',
    month,
    setMonth,
    onChange,
  } = props;
  const [value, setValue] = useState<PickerDateRange>({
    from: initialValue.from !== undefined ? parseISO(initialValue.from) : new Date(),
    to: initialValue.to !== undefined ? parseISO(initialValue.to) : new Date(),
  });
  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 50;

  const currentMonth = useMemo(() => (month !== undefined ? parseISO(month) : undefined), [month]);

  useEffect(() => {
    if (isPresetChange) {
      setValue({
        from: initialValue.from !== undefined ? parseISO(initialValue.from) : new Date(),
        to: initialValue.to !== undefined ? parseISO(initialValue.to) : new Date(),
      });
      initialValue.to !== undefined && setMonth(initialValue.to);
    }
  }, [initialValue, isPresetChange, setMonth]);

  const handleMonthChange = (month: Date) => {
    setMonth(format(month, DateFormat));
  };

  const handleSelect = (newValue?: Date | PickerDateRange, selectedDay?: Date) => {
    if (isRequired === true && newValue === undefined) {
      return;
    }

    if (newValue === undefined || selectedDay === undefined) {
      onChange({ from: undefined, to: undefined });
      return setValue({ from: undefined, to: undefined });
    }

    const selected = format(selectedDay, DateFormat);

    if (newValue instanceof Date || (value.from && value.to && value.from !== value.to)) {
      // Reset the range selection if user already had a range established
      onChange({ from: selected, to: selected });
      return setValue({ from: selectedDay, to: selectedDay });
    }

    const defaultFrom = isRequired === true ? format(new Date(), DateFormat) : undefined;
    const from = newValue?.from !== undefined ? format(newValue.from, DateFormat) : defaultFrom;
    const to = newValue?.to !== undefined ? format(newValue.to, DateFormat) : undefined;
    onChange({ from, to });
    setValue(newValue);
    selectedDay !== undefined && setMonth(format(selectedDay, DateFormat));
  };

  // DayPicker has strict types, see https://github.com/gpbl/react-day-picker/discussions/1570#discussioncomment-12407495
  if (mode === 'range') {
    return isRequired === true ? (
      <DayPicker
        mode="range"
        onSelect={handleSelect}
        selected={value}
        month={currentMonth}
        onMonthChange={handleMonthChange}
        weekStartsOn={1}
        required={isRequired}
        captionLayout="dropdown"
        fromYear={fromYear}
        toYear={currentYear}
      />
    ) : (
      <DayPicker
        mode="range"
        onSelect={handleSelect}
        selected={value}
        month={currentMonth}
        onMonthChange={handleMonthChange}
        weekStartsOn={1}
        required={false}
        captionLayout="dropdown"
        fromYear={fromYear}
        toYear={currentYear}
      />
    );
  }

  return (
    <DayPicker
      mode={mode}
      onSelect={handleSelect}
      selected={value.from}
      month={currentMonth}
      onMonthChange={handleMonthChange}
      weekStartsOn={1}
      required={isRequired}
      captionLayout="dropdown"
      fromYear={fromYear}
      toYear={currentYear}
    />
  );
};

type TimePickerProps = SharedProps & SharedPickerProps;

const DateTimePicker = (props: TimePickerProps) => {
  const { value, onChange, isRequired, mode, month, setMonth } = props;
  const [isPresetChange, setIsPresetChange] = useState(false);
  const [fromDatePart, fromTimePart] = (value.from ?? '').split('T');
  const fromIncludesTime = fromTimePart !== undefined;
  const [toDatePart, toTimePart] = (value.to ?? '').split('T');
  const toIncludesTime = toTimePart !== undefined;

  const handleTimeToggle = () => {
    const newFrom = fromIncludesTime ? fromDatePart : `${fromDatePart}T00:00`;
    const newTo =
      toDatePart !== undefined ? (toIncludesTime ? toDatePart : `${toDatePart}T00:00`) : undefined;

    onChange({
      from: newFrom,
      to: newTo,
    });
  };

  const handleTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    keyToChange: 'from' | 'to',
  ) => {
    const target = event.currentTarget as HTMLInputElement;
    const timeValue = target.value;

    if (keyToChange === 'from') {
      return onChange({
        from: fromDatePart !== undefined ? combineDateTime(fromDatePart, timeValue) : undefined,
        to: value.to,
      });
    }

    onChange({
      from: value.from,
      to: toDatePart !== undefined ? combineDateTime(toDatePart, timeValue) : undefined,
    });
  };

  const handleDateChange = (newValue: Partial<DateRange>) => {
    setIsPresetChange(false);
    onChange({
      from: newValue.from !== undefined ? combineDateTime(newValue.from, fromTimePart) : '',
      to: newValue.to !== undefined ? combineDateTime(newValue.to, toTimePart) : '',
    });
  };

  const setRange = (preset: 'week' | 'month' | 'year' | number) => {
    setIsPresetChange(true);
    const { from: fromDate, to: toDate } = getRange(preset);
    const newFrom = fromIncludesTime
      ? format(parseISO(combineDateTime(fromDate, fromTimePart)), DateTimeFormat)
      : format(parseISO(fromDate), DateFormat);
    const newTo = toIncludesTime
      ? format(parseISO(combineDateTime(toDate, toTimePart)), DateTimeFormat)
      : format(parseISO(toDate), DateFormat);

    onChange({
      from: newFrom,
      to: newTo,
    });
  };

  return (
    <div>
      <DatePicker
        value={value}
        mode={mode}
        month={month}
        isRequired={isRequired}
        isPresetChange={isPresetChange}
        setMonth={setMonth}
        onChange={handleDateChange}
      />
      <div className={styles.timeRow}>
        <CheckboxWithLabel checked={fromIncludesTime} onChange={handleTimeToggle}>
          Time
        </CheckboxWithLabel>
        {fromIncludesTime && (
          <TimeInput
            value={fromTimePart}
            onChange={(event) => handleTimeChange(event, 'from')}
            required={isRequired}
          />
        )}
        {mode === 'range' && toIncludesTime && (
          <>
            to
            <TimeInput
              value={toTimePart}
              onChange={(event) => handleTimeChange(event, 'to')}
              required={isRequired}
            />
          </>
        )}
      </div>

      {mode === 'range' && (
        <div className={styles.presetRow}>
          <InlineButton onClick={() => setRange('week')}>Last week</InlineButton>
          <InlineButton onClick={() => setRange('month')}>Last month</InlineButton>
          <InlineButton onClick={() => setRange('year')}>Last year</InlineButton>
          <InlineButton onClick={() => setRange(7)}>Last 7 days</InlineButton>
          <InlineButton onClick={() => setRange(30)}>Last 30 days</InlineButton>
        </div>
      )}
    </div>
  );
};

interface DateInputProps extends SharedProps {
  size?: FormInputSize;
}

export const DateTimeInput = (props: DateInputProps) => {
  const { value, isRequired, mode = 'single', onChange, size } = props;
  const [isOpen, setIsOpen] = useState(false);
  const formattedValue = useMemo(
    () => ({
      from: value.from !== undefined ? value.from : '',
      to: value.to !== undefined ? value.to : '',
    }),
    [value],
  );
  const [pickerValue, setPickerValue] = useState<Partial<DateRange>>(formattedValue);
  const [pickerMonth, setPickerMonth] = useState(value.from);
  const overlayRef = useRef<HTMLDivElement>(null!);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMouseEvent = useRef(false);

  const fieldLabel = useMemo(() => {
    if (mode === 'single' || value.to === undefined) {
      const formatType = includesTime(value.from ?? '')
        ? DateFriendlyTimeFormat
        : DateFriendlyFormat;
      return format(value.from ?? '', formatType);
    }

    return getHumanReadableRange(formattedValue);
  }, [value, mode, formattedValue]);

  const handlePickerCancel = (event: MouseEvent | TouchEvent | FocusEvent) => {
    if (event.target === inputRef.current) {
      return;
    }

    handleChange(formattedValue);
    setIsOpen(false);
  };

  const handleChange = (value: Partial<DateRange>) => {
    if (notNil(value.from) && !isEmpty(value.from)) {
      // const fromIncludesTime = value.from?.includes('T');
      // const formatTemplate = fromIncludesTime ? DateTimeFormat : DateFormat;
      onChange({
        from: value.from,
        to: value.to !== '' ? value.to : undefined,
      });
      setPickerMonth(value.from);
    }
    setPickerValue(value);
  };

  // For some reason KeyboardEvent conflicts between useKeyPress and onKeyDown handlers.
  const handleEscape = (event: { stopPropagation: () => void }) => {
    if (isOpen) {
      event.stopPropagation();
      setIsOpen(false);
    }
  };

  useKeyPress('Escape', handleEscape, { capture: true, includeInputs: true });
  useOnClickOutside(overlayRef, handlePickerCancel);

  useEffect(() => {
    if (value !== pickerValue) {
      setPickerValue(value);
    }
  }, [value, pickerValue]);

  const handleInputFocus = () => {
    !isMouseEvent.current && setIsOpen(true); // Only open if there is no active mouse event
  };

  const handleMouseDown = () => {
    isMouseEvent.current = true;
  };

  const handleMouseUp = () => {
    isMouseEvent.current = false;
    setIsOpen(!isOpen);
  };

  const handleKeypress = (event: React.KeyboardEvent) => {
    if (event.code === 'Escape') {
      handleEscape(event);
    }

    // Prevent Firefox's native calendar icon from toggling the native picker
    if (event.code === 'Space') {
      event.preventDefault();
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <div className={styles.dateTimeInput}>
        <Input
          ref={inputRef}
          required={isRequired}
          readOnly
          type="text"
          value={fieldLabel}
          size={size}
          data-testid="date-input"
          className={styles.input}
          onFocus={handleInputFocus}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onKeyDown={handleKeypress}
        />
        <Icon className={styles.icon} name={isOpen ? 'X' : 'Calendar'} size={16} />
      </div>
      {isOpen && (
        <div className={styles.overlay} ref={overlayRef}>
          <DateTimePicker
            mode={mode}
            value={pickerValue}
            month={pickerMonth}
            setMonth={setPickerMonth}
            onChange={handleChange}
            isRequired={isRequired}
            data-testid="date-picker"
          />
        </div>
      )}
    </>
  );
};
