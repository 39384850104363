import React, { ComponentProps, useRef, useState } from 'react';
import { isArray, last, omit, uniq } from 'lodash';
import { ParentSize } from '@visx/responsive';
import { AxisBottom } from '@visx/axis';
import { localPoint } from '@visx/event';
import classNames from 'classnames';
import { toZonedTime } from 'date-fns-tz';

import { scaleTime } from '@visx/scale';

import { findClosestDate } from '@/lib/date';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Grouping, TimeAggregationPeriod, ValueClickHandler } from '@/explore/types';

import { NoMatchBanner } from '@/components/banner';

import { DropdownMenuItem } from '@/components/dropdown';

import {
  alignScale,
  getTickFormatFn,
  getTickValues,
  getValueScale,
  GroupAxisHeight,
} from './utils';
import {
  flattenGroupedChartData,
  getChartCount,
  getSeries,
  getValueKeys,
} from '../grouped-chart/utils';
import {
  GroupedChartData,
  GroupedTimeSeriesData,
  isGroupedTimeSeriesData,
  TimeSeriesData,
} from '../grouped-chart/types';
import { GroupedChart } from '../grouped-chart';
import { NumYTicks, TimeSeriesChart } from './timeseries-chart';
import { StackedTimeSeriesChart } from './stacked-timeseries-chart';

import { getLegendData } from '../legend/utils';

import { Legend } from '../legend';

import { HighlightedData } from '../common';

import commonStyles, { multiChartChartHeight, minChartHeight } from '../charts.module.scss';

const MinChartHeight = parseInt(minChartHeight);
const MinChartWithSubAxisHeight = 400;
const WidthPerGroup = parseInt(commonStyles.widthPerGroup);
const YValuePaddingCoef = 0.1;
const HorizontalPadding = 20;
const VerticalPadding = 10;

export interface ChartColor {
  fill: string;
  highlight: string;
}

const getDates = (data: GroupedChartData<TimeSeriesData | GroupedTimeSeriesData>): Date[] =>
  isArray(data)
    ? data.flatMap((item) => getDates(item.items))
    : data.chartData.items.map((item) => item.dateValue);

const DefaultScalePadding = 0;
const MinBarChartScalePadding = 30;

interface GroupedTimeSeriesChartProps {
  data: GroupedChartData<TimeSeriesData | GroupedTimeSeriesData>;
  grouping: Grouping[];
  aggPeriod: TimeAggregationPeriod | null;
  stacked?: boolean;
  setStacked?: (stacked: boolean) => void;
  onValueClick?: ValueClickHandler;
  hideGrid?: boolean;
  timezone: string;
  isResized?: boolean;
  isTableVisible?: boolean;
  buildSeriesMenu?: (seriesKey: string) => DropdownMenuItem[];
}

export const GroupedTimeSeriesChart = (props: GroupedTimeSeriesChartProps) => {
  const {
    data,
    grouping,
    aggPeriod,
    stacked = false,
    setStacked,
    onValueClick,
    hideGrid = false,
    timezone,
    isResized = false,
    isTableVisible = true,
    buildSeriesMenu,
  } = props;

  const [highlightedData, setHighlightedData] = useState<HighlightedData | null>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const series = getSeries(data);
  const dates = uniq(getDates(data));
  if (dates.length === 0) {
    return <NoMatchBanner />;
  }

  const isStackable =
    getValueKeys(data, 'left').length > 1 || getValueKeys(data, 'right').length > 1;

  const groupKey = last(grouping)?.key;
  const containsBarChartSeries = series.some(({ chartType }) => chartType === 'bar');
  const customHeightEnabled = !isTableVisible && isResized;
  const chartCount = getChartCount(data);

  const isGroupedTimeSeries = flattenGroupedChartData(data).some((chartData) =>
    isGroupedTimeSeriesData(chartData),
  );
  const seriesDepth = isGroupedTimeSeries ? 3 : 2;
  const chartHeight = isGroupedTimeSeries
    ? MinChartWithSubAxisHeight
    : parseInt(multiChartChartHeight);
  const numChartGroups = Math.max(0, grouping.length - seriesDepth);
  const isMultiChart = numChartGroups > 0;

  return (
    <ParentSize
      debounceTime={0}
      className={classNames(commonStyles.chartContainer, {
        [commonStyles.customHeight]: customHeightEnabled,
      })}
      style={{
        minHeight:
          isMultiChart && !customHeightEnabled ? `${chartHeight * chartCount}px` : undefined,
      }}>
      {(parent) => {
        const scalePadding = containsBarChartSeries
          ? Math.min(
              parent.width / 4,
              Math.max(hideGrid ? 0 : MinBarChartScalePadding, parent.width / dates.length / 2),
            )
          : DefaultScalePadding;

        const dateScale = scaleTime({
          domain: [dates[0], dates[dates.length - 1]],
          range: [scalePadding, parent.width - WidthPerGroup * numChartGroups - scalePadding],
        });

        const zonedDateScale = dateScale
          .copy()
          .domain(dateScale.domain().map((date) => toZonedTime(date, timezone)));

        const footerHeight =
          (footerRef?.current?.clientHeight ?? 55) +
          (hideGrid ? 10 : 0) +
          (isGroupedTimeSeries ? GroupAxisHeight : 0);

        const chartHeight = Math.max(
          MinChartHeight,
          Math.floor(
            (parent.height -
              (footerHeight + (isMultiChart && isGroupedTimeSeries ? GroupAxisHeight : 0))) /
              chartCount,
          ),
        );

        const leftValueScale = getValueScale(data, stacked, 'left', YValuePaddingCoef, [
          chartHeight,
          0,
        ]);
        const rightValueScale = alignScale(
          getValueScale(data, stacked, 'right', YValuePaddingCoef, [chartHeight, 0]),
          leftValueScale,
          NumYTicks,
        );
        const valueScales = {
          left: leftValueScale,
          right: rightValueScale,
        };

        const xTickSize = parent.width / dates.length;

        const handleMouseMove = (
          event: React.TouchEvent<Element> | React.MouseEvent<Element> | MouseEvent,
          group?: string,
          axis?: 'left' | 'right',
        ) => {
          const point = localPoint(event);

          if (!point) {
            return;
          }

          const hoveredDate = new Date(dateScale.invert(point.x - xTickSize / 2));
          const closestDate = findClosestDate(dates, hoveredDate) ?? last(dates);

          setHighlightedData({
            date: new Date(closestDate),
            group,
            axis,
            point: point.value(),
          });
        };

        return (
          <div>
            <GroupedChart
              width={parent.width}
              widthPerGroup={WidthPerGroup}
              data={data}
              renderChart={(chartData, chartWidth) => {
                if (stacked) {
                  const chartProps: ComponentProps<typeof StackedTimeSeriesChart> = {
                    data: chartData,
                    dateScale,
                    valueScales,
                    aggPeriod,
                    highlightedData,
                    width: chartWidth,
                    height: chartHeight,
                    hideGrid,
                    groupKey,
                    onMouseMove: (event, group, axis) => handleMouseMove(event, group, axis),
                    onTouchMove: (event) => handleMouseMove(event),
                    onMouseLeave: () => setHighlightedData(null),
                    onValueClick,
                    timezone,
                  };
                  return <StackedTimeSeriesChart {...chartProps} />;
                }

                // Rendering group and not stacked time series chart is not supported
                if (isGroupedTimeSeriesData(chartData)) {
                  throw new Error('Grouped time series chart is not supported');
                }

                const chartProps: ComponentProps<typeof TimeSeriesChart> = {
                  data: chartData,
                  dateScale,
                  zonedDateScale,
                  valueScales,
                  aggPeriod,
                  highlightedData,
                  width: chartWidth,
                  height: chartHeight,
                  hideGrid,
                  groupKey,
                  onMouseMove: (event) => handleMouseMove(event),
                  onMouseLeave: () => setHighlightedData(null),
                  onValueClick,
                  timezone,
                };
                return (
                  <TimeSeriesChart
                    {...omit(chartProps, 'highlightedGroup')}
                    data={chartProps.data}
                  />
                );
              }}
            />
            <div ref={footerRef}>
              {!hideGrid && (
                <svg width={parent.width} height={25}>
                  <AxisBottom
                    left={Math.max(0, WidthPerGroup * numChartGroups)}
                    top={0}
                    scale={zonedDateScale}
                    hideZero
                    hideAxisLine
                    tickClassName={commonStyles.tickLabel}
                    tickLabelProps={() => ({
                      textAnchor: scalePadding > 0 ? 'middle' : 'start',
                      dy: -4,
                    })}
                    tickValues={getTickValues(aggPeriod ?? 'month', dateScale)}
                    tickFormat={getTickFormatFn(aggPeriod ?? 'month')}
                    rangePadding={300}
                  />
                </svg>
              )}

              <div
                style={{
                  marginLeft: Math.max(HorizontalPadding, WidthPerGroup * numChartGroups),
                  marginRight: HorizontalPadding,
                  marginTop: hideGrid ? VerticalPadding : 0,
                }}>
                <div className={commonStyles.chartFooter}>
                  <Legend data={getLegendData(data)} buildSeriesMenu={buildSeriesMenu} />
                  {isStackable ? (
                    <CheckboxWithLabel
                      className={[commonStyles.stackingCheckbox]}
                      checked={stacked}
                      onChange={() => setStacked?.(!stacked)}>
                      Stacked
                    </CheckboxWithLabel>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ParentSize>
  );
};
