import { Outlet } from 'react-router-dom';

import { ErrorBoundary, GenericFallback } from '@/lib/error';

import { ContextMenuContextProvider } from '../context-menu/context-menu-context';
import { OverlayContextProvider } from '../overlay/context';
import { Sidebar } from './sidebar';

import { HideInEmbedded } from './hide-in-embedded';

import { useLayoutContext } from './layout-context';

import styles from './sidebar-layout.module.scss';

export const SidebarLayout = () => {
  const { scrollAreaRef } = useLayoutContext();
  return (
    <ContextMenuContextProvider>
      <div className={styles.layout}>
        <OverlayContextProvider>
          <HideInEmbedded>
            <Sidebar />
          </HideInEmbedded>
          <div className={styles.main} ref={scrollAreaRef}>
            <div className={styles.content}>
              <ErrorBoundary
                fallback={({ resetError }) => (
                  <GenericFallback fullscreen resetOnNavigate resetError={resetError} />
                )}>
                <Outlet />
              </ErrorBoundary>
            </div>
          </div>
        </OverlayContextProvider>
      </div>
    </ContextMenuContextProvider>
  );
};
