import classnames from 'classnames';

import { FormInputSize, Option, SelectOptions, isOptionGroup } from '../types';

import styles from './select.module.scss';

export type { SelectOption, SelectOptions, Option, OptionGroup } from '../types';

const renderOption = (option: Option, i: number) => (
  <option key={i} value={option.value} disabled={option.disabled === true} title={option.title}>
    {option.label}
  </option>
);

export const withEmptyOptionForMissingValue = (options: Option[], value: string) =>
  options.some((option) => option.value === value)
    ? options
    : [{ label: '', value: '' }, ...options];

export interface SelectProps {
  options: SelectOptions | Readonly<SelectOptions>;
  value?: string;
  onChange(value: string): void;
  autoFocus?: boolean;
  className?: classnames.Argument;
  size?: FormInputSize;
  fullWidth?: boolean;
}

export const Select = ({ size = 'small', fullWidth = true, ...props }: SelectProps) => (
  <div
    className={classnames(
      styles.select,
      {
        [styles.sizeSmall]: size === 'small',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
        [styles.fullWidth]: fullWidth,
      },
      props.className,
    )}>
    <select
      autoFocus={props.autoFocus}
      value={props.value}
      onChange={(event) => props.onChange(event.target.value)}>
      {props.options.map((option, i) =>
        isOptionGroup(option) ? (
          <optgroup label={option.label} key={i}>
            {option.options.map((option, i) => renderOption(option, i))}
          </optgroup>
        ) : (
          renderOption(option, i)
        ),
      )}
    </select>
    <div className={styles.chevron}>
      <svg
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        aria-hidden="true">
        <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z" />
      </svg>
    </div>
  </div>
);
