import classNames from 'classnames';
import { compact, first, omit } from 'lodash';
import { useState } from 'react';

import { AnalyticsContextProvider, useTrackEvent } from '@/lib/analytics';

import { insertCellAtIdx } from '@/core/cell';
import { addCells, buildModelExploration, replaceCells } from '@/core/exploration';

import { createBasePipeline, createPipelineWithParent } from '@/core/pipeline';

import { Button } from '../../components/button';
import { Icon } from '../../components/icon';
import { useExplorationContext } from '../exploration/exploration-context';
import { useExplorationCellContext } from '../exploration/exploration-cell-context';
import type { Cell, Exploration, FunnelStep, Grouping, Model, Pipeline } from '../types';
import { updateOperation } from '../edit-pipeline/utils';
import { getFinalStateOrThrow } from '../pipeline/state';
import { FunnelSteps } from './funnel-steps';
import {
  updateGroupings,
  getDefaultCountingFieldKey,
  getGroupableFields,
  getDefaultSortFieldKey,
  getGroupKeyValue,
} from './utils';
import { GroupingsEditor } from '../edit-pipeline/groupings-editor';
import { PipelineSearchModal } from '../exploration-search/pipeline-search';
import { SidebarSection } from '../exploration/sidebar-section';
import { getCell, getCellIndex, prepareExplorationCellsForImport } from '../exploration/utils';
import { dereferencePipeline } from '../pipeline/utils';
import { getModelOrThrow } from '../model/utils';
import { useEnsureFieldsExistGrouped } from '../edit-pipeline/hooks/use-ensure-fields-exist';
import { useMetadataContext } from '../metadata-context';
import { useEnsurePipelinesExist } from '../edit-pipeline/hooks/use-ensure-pipelines-exist';

import formStyles from '../../components/form/form.module.scss';
import styles from './funnel.module.scss';

interface EditFunnelProps {
  title: string;
  pipeline: Pipeline;
  exploration: Exploration;
  explorations: Exploration[];
  setExploration: (exploration: Exploration) => void;
}

export const EditFunnel = (props: EditFunnelProps) => {
  const { pipeline, exploration } = props;

  const { models, metrics, getModel } = useMetadataContext();
  const { selectCell, scrollToCell, getVariables } = useExplorationContext();
  const { cellIndex, cell } = useExplorationCellContext();
  const trackEvent = useTrackEvent();

  const [eventSelectionStepIndex, setEventSelectionStepIndex] = useState<number | null>(null);
  const showExplorationList = eventSelectionStepIndex !== null;
  const variables = getVariables();
  const stateContext = { models, variables, metrics };

  const operation = first(pipeline.operations);

  if (operation === undefined || operation.operation !== 'funnel') {
    throw new Error('EditFunnel requires a funnel operation');
  }

  const { parameters } = operation;
  const paddedExploration = useEnsurePipelinesExist(
    exploration,
    compact(
      parameters.steps.map((step) =>
        'parentId' in step.pipeline ? step.pipeline.parentId : undefined,
      ),
    ),
  );

  const groups =
    parameters.groups?.map((group) => ({
      ...group,
      key: getGroupKeyValue(group.key, operation, exploration, stateContext),
    })) ?? [];

  const groupableFields = useEnsureFieldsExistGrouped(
    getGroupableFields(parameters.steps, paddedExploration, stateContext),
    groups.map(({ key }) => key),
  );

  const handleClickEvent = (stepIndex: number) => {
    setEventSelectionStepIndex(stepIndex);
  };

  const handleImportEvent = (stepIndex: number) => {
    const step = parameters.steps.at(stepIndex);
    if (step === undefined || !('baseModelId' in step.pipeline)) {
      throw new Error('Trying to import non-existent or invalid funnel step');
    }
    const modelExploration = buildModelExploration(getModel(step.pipeline.baseModelId));
    const cellToImport = first(modelExploration.view.cells);
    if (
      cellToImport === undefined ||
      !('pipeline' in cellToImport) ||
      cellToImport.pipeline.pipelineId === undefined
    ) {
      throw new Error('Model exploration generation produced invalid cell');
    }

    const pipelineId = cellToImport.pipeline.pipelineId;
    const updatedStep = {
      ...step,
      pipeline: {
        ...omit(step.pipeline, 'baseModelId'),
        parentId: pipelineId,
      },
    };

    const withUpdatedOperation = updateOperation(
      exploration,
      cell.id,
      0,
      {
        ...operation,
        parameters: {
          ...parameters,
          steps: [
            ...parameters.steps.slice(0, stepIndex),
            updatedStep,
            ...parameters.steps.slice(stepIndex + 1),
          ],
        },
      },
      stateContext,
    );

    const updatedExploration = replaceCells(
      withUpdatedOperation,
      insertCellAtIdx(withUpdatedOperation.view.cells, cellToImport, cellIndex + 1),
    );
    props.setExploration(updatedExploration);

    const updatedCell = updatedExploration.view.cells.at(cellIndex + 1);

    if (updatedCell !== undefined) {
      selectCell(updatedCell.id);
      scrollToCell(updatedCell.id);
    }
  };

  const handleSetSteps = (steps: FunnelStep[], exploration: Exploration) => {
    trackEvent('Funnel Steps Updated', {
      funnel: operation,
      steps,
    });
    const updatedSelectedCellIndex = getCellIndex(cell.id, exploration);
    const updatedExploration = updateOperation(
      exploration,
      cell.id,
      0,
      {
        ...operation,
        parameters: {
          ...parameters,
          steps,
        },
      },
      stateContext,
    );

    props.setExploration(updatedExploration);

    const updatedCell = updatedExploration.view.cells.at(updatedSelectedCellIndex);
    if (updatedCell !== undefined) {
      selectCell(updatedCell.id);
      scrollToCell(updatedCell.id);
    }
  };

  const handleGroupsChange = (groups: Grouping[]) => {
    trackEvent('Funnel Grouping Updated', {
      funnel: operation,
      groups,
    });
    props.setExploration(
      updateOperation(
        exploration,
        cell.id,
        0,
        updateGroupings(operation, groups, exploration, stateContext),
        stateContext,
      ),
    );
  };

  const handleAddStep = (pipeline: Pipeline, exploration: Exploration) => {
    if (eventSelectionStepIndex === null) {
      throw new Error('Event selection for unknown step');
    }

    const dereferencedPipeline = dereferencePipeline(pipeline, exploration);

    const model = getModelOrThrow(models, dereferencedPipeline.baseModelId);
    const fields = getFinalStateOrThrow(
      dereferencedPipeline.baseModelId,
      dereferencedPipeline.operations,
      stateContext,
    ).fields;

    const countingField = getDefaultCountingFieldKey(fields, model, models);
    const sortField = getDefaultSortFieldKey(fields, model);

    const step = {
      fields: [countingField],
      sortKey: sortField,
      pipeline,
    };

    handleSetSteps(
      [
        ...parameters.steps.slice(0, eventSelectionStepIndex),
        step,
        ...parameters.steps.slice(eventSelectionStepIndex + 1),
      ],
      exploration,
    );

    setEventSelectionStepIndex(null);
  };

  const handleSelectModel = (model: Model) => {
    handleAddStep(
      createBasePipeline({
        baseModelId: model.modelId,
        operations: [],
      }),
      exploration,
    );
  };

  const handleSelectCell = (selectedCell: Cell, dependencies: Cell[]) => {
    if (!('pipeline' in selectedCell) || selectedCell.pipeline.pipelineId === undefined) {
      throw new Error('Invalid selection for funnel step');
    }

    const isFromCurrentExploration = getCell(selectedCell.id, exploration) !== undefined;

    if (isFromCurrentExploration) {
      return handleAddStep(
        createPipelineWithParent({
          parentId: selectedCell.pipeline.pipelineId,
          operations: [],
        }),
        exploration,
      );
    }

    [selectedCell, ...dependencies] = prepareExplorationCellsForImport([
      selectedCell,
      ...dependencies,
    ]);
    if (!('pipeline' in selectedCell) || selectedCell.pipeline.pipelineId === undefined) {
      throw new Error('Cell has no pipelineId after preparation for import.');
    }

    const updatedExploration: Exploration = addCells(
      exploration,
      [...dependencies, selectedCell],
      cell.id,
    );
    handleAddStep(
      createPipelineWithParent({
        parentId: selectedCell.pipeline.pipelineId,
        operations: [],
      }),
      updatedExploration,
    );
  };

  return (
    <>
      <SidebarSection title="Funnel events">
        <div className={classNames(styles.funnel)}>
          <FunnelSteps
            models={models}
            metrics={metrics}
            steps={parameters.steps}
            setSteps={(steps) => handleSetSteps(steps, exploration)}
            onClickEvent={handleClickEvent}
            onImportEvent={handleImportEvent}
            exploration={exploration}
          />
          {parameters.steps.length > 0 && (
            <Icon name={'DownArrow'} size={14} className={styles.downArrow} />
          )}
          <Button
            variant={'outlined'}
            icon={<Icon name="PlusCircle" size={16} />}
            onClick={() => handleClickEvent(parameters.steps.length)}>
            Add Event
          </Button>
        </div>
      </SidebarSection>
      <SidebarSection title="Grouping">
        {parameters.steps.length > 0 &&
          (groupableFields.length > 0 ? (
            <GroupingsEditor
              fields={groupableFields}
              groups={groups}
              setGroups={handleGroupsChange}
              autoFocus
            />
          ) : (
            <p className={formStyles.helpText}>No additional fields to group by.</p>
          ))}
      </SidebarSection>

      {showExplorationList && (
        <AnalyticsContextProvider
          properties={{
            intent: 'funnel event selection',
            exploration: props.exploration,
            operation,
            atIndex: eventSelectionStepIndex,
          }}>
          <PipelineSearchModal
            explorations={props.explorations}
            exploration={props.exploration}
            onSelectModel={handleSelectModel}
            onSelectCell={handleSelectCell}
            onClose={() => setEventSelectionStepIndex(null)}
            kinds={['Event']}
            title="Select Event"
          />
        </AnalyticsContextProvider>
      )}
    </>
  );
};
