import { Image } from '@tiptap/extension-image';

import { generateUUID } from '@/lib/utils';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    uploadingImage: {
      uploadFile: (atPos: number, file: File) => ReturnType;
      insertUploadingImageAtPos: (
        pos: number,
        fileId: string,
        src: string | ArrayBuffer | null,
      ) => ReturnType;
      completeImageUpload: (fileId: string, url: string) => ReturnType;
      updateImageUploadedUrl: (fileId: string, url: string) => ReturnType;
      setImageUploadProgress: (fileId: string, progress: number) => ReturnType;
      setImageUploadFailure: (fileId: string) => ReturnType;
    };
  }
}

interface UploadingImageOptions {
  accountId: string | null;
  getToken: () => Promise<string | null>;
  onUploadSuccess: (fileId: string, url: string) => void;
  onUploadError: (fileId: string) => void;
}

const UploadingImage = Image.extend<UploadingImageOptions>({
  name: 'uploadingImage',

  addOptions() {
    return {
      ...this.parent?.(),
      selectable: true,
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),

      uploadFile:
        (atPos, file) =>
        ({ editor }) => {
          (async () => {
            const accountId = this.options.accountId;
            const authToken = await this.options.getToken();

            if (accountId === null || authToken === null) {
              return false;
            }

            const fileId = generateUUID();
            const formData = new FormData();

            formData.append('file', file);

            const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';

            const xhr = new XMLHttpRequest();
            xhr.open('PUT', `/user-attachments/assets/${accountId}/${fileId}.${fileExtension}`);
            xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);

            xhr.upload.onprogress = (event) => {
              if (event.lengthComputable) {
                editor.commands.setImageUploadProgress(fileId, (event.loaded / event.total) * 100);
              }
            };

            xhr.onerror = () => {
              editor.commands.setImageUploadFailure(fileId);

              this.options.onUploadError(fileId);
            };

            xhr.onload = () => {
              if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                editor.commands.completeImageUpload(fileId, response.url);

                this.options.onUploadSuccess(fileId, response.url);
              } else {
                editor.commands.setImageUploadFailure(fileId);

                this.options.onUploadError(fileId);
              }
            };

            xhr.send(formData);

            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              editor
                .chain()
                .insertUploadingImageAtPos(atPos, fileId, fileReader.result)
                .focus()
                .run();
            };
          })();

          return true;
        },

      insertUploadingImageAtPos:
        (pos, fileId, src) =>
        ({ commands }) =>
          commands.insertContentAt(pos, {
            type: 'uploadingImage',
            attrs: { src, fileId, progress: 0 },
          }),

      completeImageUpload:
        (fileId, url) =>
        ({ editor }) => {
          const image = new window.Image();
          image.onload = () => editor.commands.updateImageUploadedUrl(fileId, url);
          image.src = url;

          return true;
        },

      updateImageUploadedUrl:
        (fileId, url) =>
        ({ tr, editor }) => {
          tr.doc.content.descendants((node, pos) => {
            if (node.type.name === 'uploadingImage' && node.attrs.fileId === fileId) {
              tr.replaceWith(pos, pos + 1, editor.schema.nodes.image.create({ src: url }));
              return true;
            }
          });

          return true;
        },

      setImageUploadProgress:
        (fileId, progress) =>
        ({ tr }) => {
          tr.doc.content.descendants((node, pos) => {
            if (node.type.name === 'uploadingImage' && node.attrs.fileId === fileId) {
              tr.setNodeAttribute(pos, 'progress', progress);
              return true;
            }
          });

          return true;
        },

      setImageUploadFailure:
        (fileId) =>
        ({ tr }) => {
          tr.doc.content.descendants((node, pos) => {
            if (node.type.name === 'uploadingImage' && node.attrs.fileId === fileId) {
              tr.setNodeAttribute(pos, 'error', 'Upload failed for this image');
              return true;
            }
          });

          return true;
        },
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),

      progress: {
        default: null,
        parseHTML: (element) => {
          const value = element.getAttribute('data-progress');
          if (value === null) {
            return null;
          }
          return parseInt(value, 10);
        },
        renderHTML: (attributes) => {
          if (attributes.progress === null || attributes.progress >= 100) {
            return {};
          }
          return { 'data-progress': attributes.progress };
        },
      },

      error: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-error'),
      },

      fileId: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-fileid'),
        renderHTML: (attributes) => ({ 'data-fileid': attributes.fileId }),
      },
    };
  },

  addNodeView() {
    return ({ node, HTMLAttributes }) => {
      const container = document.createElement('figure');
      container.setAttribute('data-fileid', node.attrs.fileId);
      container.setAttribute('data-progress', node.attrs.progress);
      if (node.attrs.error !== null) {
        container.setAttribute('data-error', node.attrs.error);
      }

      const progress = document.createElement('progress');
      progress.setAttribute('value', node.attrs.progress);
      progress.setAttribute('max', '100');

      const img = document.createElement('img');

      Object.entries(HTMLAttributes)
        .filter(([key]) => !key.startsWith('data-'))
        .filter(([value]) => value !== null)
        .forEach(([key, value]) => img.setAttribute(key, value));

      container.appendChild(progress);
      container.appendChild(img);

      return {
        dom: container,
        contentDOM: img,
        update: (node) => {
          if (node.attrs.progress !== null) {
            progress.value = node.attrs.progress;
          }
          if (node.attrs.error !== null) {
            container.setAttribute('data-error', node.attrs.error);
          } else {
            container.removeAttribute('data-error');
          }

          return true;
        },
      };
    };
  },
});

export default UploadingImage;
