import { useState, useRef, useEffect } from 'react';

import { useLayoutContext } from '../layout/layout-context';
import { InlineButton } from '../button';

import styles from './update-notification.module.scss';

export function UpdateNotification() {
  const [showPrompt, setShowPrompt] = useState(false);
  const { isEmbeddedMode } = useLayoutContext();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (isEmbeddedMode) {
      return;
    }

    const fetchVersion = async () => {
      try {
        const version = await (await fetch('/version.json')).json();
        return 'commitRef' in version ? version.commitRef : null;
      } catch {
        return null;
      }
    };

    fetchVersion().then((initialVersion) => {
      intervalRef.current = setInterval(async () => {
        const version = await fetchVersion();

        if (version !== initialVersion) {
          setShowPrompt(true);
          intervalRef.current !== null && clearInterval(intervalRef.current);
        }
      }, 60 * 1000);
    });

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showPrompt) {
    return null;
  }

  const reload = () => window.location.reload();

  return (
    <div className={styles.wrapper}>
      <div className={styles.notification}>
        A new version of Supersimple is available.
        <InlineButton onClick={reload}>Reload to update</InlineButton>
      </div>
    </div>
  );
}
