import { createContext, useState, useCallback, useContext, useReducer } from 'react';
import { get, omit } from 'lodash';

import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';
import { useLayoutContext } from '@/components/layout/layout-context';
import { deleteExplorationCell, insertConversationStart, replaceCells } from '@/core/exploration';

import {
  Cell,
  InvalidCell,
  DeriveFieldOperation,
  Exploration,
  ExplorationParameter,
  ExplorationParameters,
  Field,
  Fields,
  FilterOperation,
  GroupAggregateOperation,
  Metric,
  Model,
  QueryVariables,
  RelationAggregateOperation,
  CanvasNode,
  CanvasEdge,
} from '../types';
import { getVariableDefinitions, getQueryVariablesFromParameters } from '../utils';
import {
  importExplorationCellsToExploration,
  createExplorationCellInstance,
  duplicateExplorationCell,
  getFieldsByCellId,
} from './utils';
import {
  ensureValidLayout,
  getRowEndIndex,
  getRowStartIndex,
  isCellFirstInRow,
  isCellInMultiRow,
  isCellLastInRow,
  getCellRow,
  getCellRows,
  moveCell,
  swapCells,
} from './exploration-layout/utils';
import { addDrillDownCellToExploration } from '../utils/drilldown';
import { useDirtyContext } from '../dirty-context';
import { usePersistCell, CopiedCell } from '../utils/use-persist-cell';

export type FormId =
  | 'addFilter'
  | 'addGroupAggregate'
  | 'addSwitchToRelation'
  | 'addRelationAggregate'
  | 'addDeriveField';

export interface QueryMeta {
  recordType?: Fields;
  grouping?: { key: string }[];
}

type ExplorationState = {
  selectedCellId: string | null;
  scrollToId: string | null;
  addFormData:
    | {
        index: number;
        formId: 'addFilter';
        parameters?: FilterOperation['parameters'];
      }
    | {
        index: number;
        formId: 'addGroupAggregate';
        parameters?: GroupAggregateOperation['parameters'];
      }
    | {
        index: number;
        formId: 'addRelationAggregate';
        parameters?: RelationAggregateOperation['parameters'];
      }
    | {
        index: number;
        formId: 'addDeriveField';
        parameters?: DeriveFieldOperation['parameters'];
      }
    | {
        index: number;
        formId?: 'addSwitchToRelation';
      }
    | null;
  editFormIndex: number | null;
  newCellIndex: number | null;
  queryMeta: { [cellId: string]: QueryMeta | null };
  conversationDrafts: Record<string, string | null>;
};

type OpenEditorAction = {
  type: 'OPEN_EDITOR';
  payload: { cellId: string };
};

type CloseEditorAction = {
  type: 'CLOSE_EDITOR';
};

type SelectCellAction = {
  type: 'SELECT_CELL';
  payload: { cellId: string | null };
};

type DeselectCellAction = {
  type: 'DESELECT_CELL';
};

type ScrollToCellAction = {
  type: 'SCROLL_TO_CELL';
  payload: { cellId: string | null };
};

type OpenAddMenuAction = {
  type: 'OPEN_ADD_MENU';
  payload: { index: number };
};

type OpenAddFormAction = {
  type: 'OPEN_ADD_FORM';
  payload:
    | { index: number; formId: 'addFilter'; parameters: FilterOperation['parameters'] }
    | {
        index: number;
        formId: 'addGroupAggregate';
        parameters: GroupAggregateOperation['parameters'];
      }
    | {
        index: number;
        formId: 'addRelationAggregate';
        parameters: RelationAggregateOperation['parameters'];
      }
    | { index: number; formId: 'addDeriveField'; parameters: DeriveFieldOperation['parameters'] }
    | { index: number; formId: FormId };
};

type CloseAddFormAction = {
  type: 'CLOSE_ADD_FORM';
};

type OpenEditFormAction = {
  type: 'OPEN_EDIT_FORM';
  payload: { index: number };
};

type CloseEditFormAction = {
  type: 'CLOSE_EDIT_FORM';
};

type SetQueryMetaAction = {
  type: 'SET_QUERY_META';
  payload: { cellId: string; meta: QueryMeta | null };
};

type SetNewCellIndex = {
  type: 'SET_NEW_CELL_INDEX';
  payload: { index: number | null };
};

type SetConversationDraft = {
  type: 'SET_CONVERSATION_DRAFT';
  payload: { conversationId: string; draft: string | null };
};

type Action =
  | OpenEditorAction
  | CloseEditorAction
  | SelectCellAction
  | DeselectCellAction
  | ScrollToCellAction
  | OpenAddMenuAction
  | OpenAddFormAction
  | CloseAddFormAction
  | OpenEditFormAction
  | CloseEditFormAction
  | SetQueryMetaAction
  | SetNewCellIndex
  | SetConversationDraft;

const getInitialState = (exploration: Exploration, screenSize: Breakpoint): ExplorationState => ({
  selectedCellId:
    exploration.view.cells.length === 1 &&
    screenSize > Breakpoint.md &&
    exploration.view.cells.at(0)?.kind !== 'chat'
      ? (exploration.view.cells.at(0)?.id ?? null)
      : null,
  scrollToId: null,
  addFormData: null,
  editFormIndex: null,
  queryMeta: {},
  newCellIndex: null,
  conversationDrafts: {},
});

const reducer = (state: ExplorationState, action: Action): ExplorationState => {
  switch (action.type) {
    case 'OPEN_EDITOR':
      return {
        ...state,
        selectedCellId: action.payload?.cellId ?? state.selectedCellId,
        scrollToId: action.payload?.cellId === state.scrollToId ? state.scrollToId : null,
        addFormData: action.payload?.cellId !== state.selectedCellId ? null : state.addFormData,
        editFormIndex: action.payload?.cellId !== state.selectedCellId ? null : state.editFormIndex,
      };
    case 'CLOSE_EDITOR':
      return {
        ...state,
        scrollToId: null,
        selectedCellId: null,
        addFormData: null,
      };
    case 'SELECT_CELL':
      return {
        ...state,
        selectedCellId: action.payload.cellId,
        addFormData: null,
        editFormIndex: null,
      };
    case 'DESELECT_CELL':
      return {
        ...state,
        selectedCellId: null,
      };
    case 'OPEN_ADD_MENU':
    case 'OPEN_ADD_FORM':
      return {
        ...state,
        addFormData: action.payload,
        editFormIndex: null,
      };
    case 'CLOSE_ADD_FORM':
      return {
        ...state,
        addFormData: null,
      };
    case 'OPEN_EDIT_FORM':
      return {
        ...state,
        editFormIndex: action.payload.index,
        addFormData: null,
      };
    case 'CLOSE_EDIT_FORM':
      return {
        ...state,
        editFormIndex: null,
      };
    case 'SCROLL_TO_CELL':
      return {
        ...state,
        scrollToId: action.payload.cellId,
      };
    case 'SET_QUERY_META':
      return {
        ...state,
        queryMeta: {
          ...state.queryMeta,
          [action.payload.cellId]: action.payload.meta,
        },
      };
    case 'SET_NEW_CELL_INDEX':
      return {
        ...state,
        newCellIndex: action.payload.index,
      };
    case 'SET_CONVERSATION_DRAFT':
      return {
        ...state,
        conversationDrafts: {
          ...state.conversationDrafts,
          [action.payload.conversationId]: action.payload.draft,
        },
      };
    default:
      return state;
  }
};

export interface ExplorationContextValue {
  exploration: Exploration;
  cellCount: number;
  selectedCell: Cell | null;
  isEditorOpen: boolean;
  addFormData: ExplorationState['addFormData'];
  editFormIndex: number | null;
  scrollToId: string | null;
  openEditor: (cellId: string) => void;
  closeEditor: () => void;
  selectCell: (cellId: string) => void;
  deselectCell: () => void;
  copiedCell?: CopiedCell;
  persistCopiedCell: (cell: { cell: Cell; dependencies: Cell[] }) => void;
  removeCopiedCell: () => void;
  openAddMenu: (payload: OpenAddMenuAction['payload']) => void;
  openAddForm: (payload: OpenAddFormAction['payload']) => void;
  closeAddForm: () => void;
  openEditForm: (payload: OpenEditFormAction['payload']) => void;
  closeEditForm: () => void;
  scrollToCell: (cellId: string | null) => void;
  setExploration: (exploration: Exploration, parameters?: ExplorationParameters) => void;
  upsertExploration: (
    exploration: Exploration,
    parameters: ExplorationParameters | null,
  ) => Promise<{ explorationId: string }>;
  deleteExploration: (explorationId: string) => Promise<void>;
  resetExploration: (parameters: ExplorationParameters | null) => void;
  setCellById: (cell: Cell, cellId: string) => void;
  getCellById: (cellId: string) => Cell | undefined;
  duplicateCell: (cellId: string) => { exploration: Exploration; cell?: Cell };
  addCells: (
    cell: Cell,
    dependencies: Cell[],
    index: number,
  ) => { exploration: Exploration; cell: Cell; dependencies: Cell[] };
  createCellInstance: (cellId: string) => { exploration: Exploration; cell?: Cell };
  deleteCell: (cellId: string) => void;
  moveCell: (
    fromIndex: number,
    toIndex: number,
    after?: boolean,
    mergeIntoRow?: boolean,
    height?: number,
  ) => void;
  moveCellUp: (index: number) => void;
  moveCellDown: (index: number) => void;
  setQueryMeta: (payload: SetQueryMetaAction['payload']) => void;
  getQueryMeta: (cellId: string) => QueryMeta | null;
  getFieldsByCellId: (cellId: string) => Fields;
  parameters: ExplorationParameters;
  setParameter: (key: keyof ExplorationParameters, value: ExplorationParameter) => void;
  getParameter: (key: keyof ExplorationParameters) => ExplorationParameter | undefined;
  getParameters: () => ExplorationParameters;
  getVariables: () => QueryVariables;
  clearParameter: (key: keyof ExplorationParameters) => void;
  isDirty: boolean;
  drillDownByProperty: (
    record: Record<string, unknown>,
    field: Field,
    cellId: string,
    timezone: string,
  ) => void;
  canvasState: { nodes?: CanvasNode[] };
  setCanvasState: (state: { nodes?: CanvasNode[]; edges?: CanvasEdge[] }) => void;
  newCellIndex: number | null;
  setNewCellIndex: (state: number | null) => void;
  startConversation: (cell: Exclude<Cell, InvalidCell>, cellIndex: number) => void;
  conversationDrafts: Record<string, string | null>;
  setConversationDraft: (conversationId: string, draft: string | null) => void;
}

const defaultContextValue = Symbol();

const ExplorationContext = createContext<ExplorationContextValue | typeof defaultContextValue>(
  defaultContextValue,
);

// Taken from https://github.com/DefinitelyTyped/DefinitelyTyped/pull/24509#issuecomment-1545820830
export const useExplorationContext = () => {
  const context = useContext(ExplorationContext);
  if (context === defaultContextValue) {
    throw new Error('useExplorationContext must be used within a ExplorationContextProvider');
  }
  return context;
};

export const ExplorationContextProvider = ({
  exploration,
  models,
  metrics,
  parameters,
  setExploration,
  setParameters,
  upsertExploration,
  deleteExploration,
  resetExploration,
  children,
}: {
  exploration: Exploration;
  models: Model[];
  metrics: Metric[];
  parameters: ExplorationParameters;
  setExploration: (exploration: Exploration, parameters: ExplorationParameters | null) => void;
  setParameters: (parameters: ExplorationParameters) => void;
  upsertExploration: (
    exploration: Exploration,
    parameters: ExplorationParameters | null,
  ) => Promise<{ explorationId: string }>;
  deleteExploration: (explorationId: string) => Promise<void>;
  resetExploration: (parameters: ExplorationParameters | null) => void;
  children: React.ReactNode;
}) => {
  const screenSize = useScreenSize();
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(exploration, screenSize.breakpoint),
  );
  const { confirmUnsavedChangesIfNeeded } = useDirtyContext();
  const [canvasState, setCanvasState] = useState<{
    nodes?: CanvasNode[];
    edges?: CanvasEdge[];
  }>({});

  const { copiedCell, persistCopiedCell, removeCopiedCell } = usePersistCell();

  const isDirty =
    exploration.options?.explorationSourceId !== undefined &&
    exploration.options?.explorationSourceId !== exploration.explorationId;

  const cells = exploration.view.cells;

  const getCellById = (cellId: string) => cells.find((cell) => cell.id === cellId);
  const { toggleRightSidebar } = useLayoutContext();

  const openEditor = (cellId: string) => {
    confirmUnsavedChangesIfNeeded({
      onConfirm: () => {
        toggleRightSidebar(true);
        dispatch({ type: 'OPEN_EDITOR', payload: { cellId } });
      },
    });
  };

  const closeEditor = () => {
    confirmUnsavedChangesIfNeeded({
      onConfirm: () => {
        dispatch({ type: 'CLOSE_EDITOR' });
      },
    });
  };

  const selectCell = (cellId: string) => {
    dispatch({ type: 'SELECT_CELL', payload: { cellId } });
  };

  const deselectCell = () => {
    dispatch({ type: 'DESELECT_CELL' });
  };

  const openAddMenu = (payload: OpenAddMenuAction['payload']) => {
    confirmUnsavedChangesIfNeeded({
      onConfirm: () => {
        dispatch({ type: 'OPEN_ADD_MENU', payload });
      },
    });
  };

  const openAddForm = (payload: OpenAddFormAction['payload']) => {
    dispatch({ type: 'OPEN_ADD_FORM', payload });
  };

  const closeAddForm = () => {
    dispatch({ type: 'CLOSE_ADD_FORM' });
  };

  const openEditForm = (payload: OpenEditFormAction['payload']) => {
    confirmUnsavedChangesIfNeeded({
      onConfirm: () => {
        dispatch({ type: 'OPEN_EDIT_FORM', payload });
      },
    });
  };

  const closeEditForm = () => {
    dispatch({ type: 'CLOSE_EDIT_FORM' });
  };

  const scrollToCell = (cellId: string | null) => {
    dispatch({ type: 'SCROLL_TO_CELL', payload: { cellId } });
  };

  const setNewCellIndex = (index: number | null) => {
    dispatch({ type: 'SET_NEW_CELL_INDEX', payload: { index } });
  };

  const setConversationDraft = (conversationId: string, draft: string | null) => {
    dispatch({ type: 'SET_CONVERSATION_DRAFT', payload: { conversationId, draft } });
  };

  const setCellById = (updatedCell: Cell, cellId: string) => {
    const updatedExploration = {
      ...exploration,
      view: {
        ...exploration.view,
        cells: cells.map((cell) => (cell.id === cellId ? updatedCell : cell)),
      },
    };

    setExploration(updatedExploration, parameters);
  };

  const duplicateCell = (cellId: string) => {
    const result = duplicateExplorationCell(exploration, cellId);

    setExploration(result.exploration, parameters);

    return result;
  };

  const addCells = (cell: Cell, dependencies: Cell[], index: number) => {
    const result = importExplorationCellsToExploration(exploration, cell, dependencies, index);

    setExploration(result.exploration, parameters);

    return result;
  };

  const createCellInstance = (cellId: string) => {
    const result = createExplorationCellInstance(exploration, cellId);

    setExploration(result.exploration, parameters);

    return result;
  };

  const deleteCell = (cellId: string) => {
    setExploration(deleteExplorationCell(exploration, cellId), parameters);
    if (selectedCell?.id === cellId) {
      deselectCell();
    }
  };

  const moveCellUp = (index: number) => {
    let newCells = cells;

    if (!isCellInMultiRow(cells, index)) {
      // Jump before first cell in previous row
      newCells = moveCell(cells, index, getRowStartIndex(cells, index - 1), false, false);
    } else if (isCellFirstInRow(cells, index)) {
      // Detach from row = jump before itself (with mergeIntoRow = false)
      newCells = moveCell(cells, index, index, false, false);
    } else {
      // Cell is non-first in multi-row: move within row
      newCells = swapCells(cells, index, index - 1);
    }
    setExploration(
      {
        ...exploration,
        view: {
          ...exploration.view,
          cells: ensureValidLayout(newCells),
        },
      },
      parameters,
    );

    if (selectedCell !== null) {
      selectCell(selectedCell.id);
    }
  };

  const moveCellDown = (index: number) => {
    let newCells = cells;

    if (!isCellInMultiRow(cells, index)) {
      // Jump after last cell in next row
      newCells = moveCell(cells, index, getRowEndIndex(cells, index + 1), true, false);
    } else if (isCellLastInRow(cells, index)) {
      // Detach from row = jump after itself (with mergeIntoRow = false)
      newCells = moveCell(cells, index, index, true, false);
    } else {
      // Cell is non-last in multi-row: move within row
      newCells = swapCells(cells, index, index + 1);
    }
    setExploration(
      {
        ...exploration,
        view: {
          ...exploration.view,
          cells: ensureValidLayout(newCells),
        },
      },
      parameters,
    );

    if (selectedCell !== null) {
      selectCell(selectedCell.id);
    }
  };

  const wrappedMoveCell = (
    fromIndex: number,
    toIndex: number,
    after = false,
    mergeIntoRow = false,
    cellHeight?: number,
  ) => {
    const newCells = moveCell(cells, fromIndex, toIndex, after, mergeIntoRow, cellHeight);
    setExploration({ ...exploration, view: { ...exploration.view, cells: newCells } }, parameters);

    if (selectedCell !== null) {
      selectCell(selectedCell.id);
    }
  };

  const getQueryMeta = useCallback(
    (cellId: string) => get(state.queryMeta, cellId, null),
    [state.queryMeta],
  );

  const setQueryMeta = useCallback(
    (payload: SetQueryMetaAction['payload']) => dispatch({ type: 'SET_QUERY_META', payload }),
    [],
  );

  const setParameter = (key: keyof ExplorationParameters, value: ExplorationParameter) =>
    setParameters({ ...parameters, [key]: value });

  const getParameter = (key: keyof ExplorationParameters) => parameters[key];

  const clearParameter = (key: keyof ExplorationParameters) => setParameters(omit(parameters, key));

  // It is paradoxical but the variables are sorted here because their order is not important. It is done so because
  // reordering variables do not cause dependent queries to refetch.
  const getVariables = () =>
    getQueryVariablesFromParameters(getVariableDefinitions(exploration), parameters).sort((a, b) =>
      a.key.localeCompare(b.key),
    );

  const startConversation = (cell: Exclude<Cell, InvalidCell>, cellIndex: number) => {
    let cells = exploration.view.cells;
    const cellRow = getCellRow(getCellRows(exploration.view.cells), cellIndex);

    if (cellRow.length > 1) {
      const rowEnd = getRowEndIndex(cells, cellIndex);
      cells = moveCell(cells, cellIndex, rowEnd, true, false);
      cellIndex = cells.findIndex((c) => c.id === cell.id);
    }

    const cellWithoutRow = cells[cellIndex];
    const newExploration = replaceCells(exploration, cells);
    if (cellWithoutRow === undefined || cellWithoutRow.kind === 'invalid') {
      return;
    }

    setExploration(insertConversationStart(newExploration, cellWithoutRow, cellIndex), parameters);
  };

  const drillDownByProperty = (
    record: Record<string, unknown>,
    field: Field,
    cellId: string,
    timezone: string,
  ) => {
    const fields = getQueryMeta(cellId)?.recordType ?? [];

    const { exploration: updatedExploration, cell } = addDrillDownCellToExploration({
      record,
      properties: fields,
      property: field,
      exploration,
      cellId,
      timezone,
    });

    setExploration(updatedExploration, parameters);

    if (cell !== undefined) {
      scrollToCell(cell.id);
      selectCell(cell.id);
    }
  };

  const cellCount = cells.length;

  const selectedCell =
    state.selectedCellId !== null ? (getCellById(state.selectedCellId) ?? null) : null;

  return (
    <ExplorationContext.Provider
      value={{
        exploration,
        cellCount,
        selectedCell,
        isEditorOpen: selectedCell !== null,
        scrollToId: state.scrollToId,
        addFormData: state.addFormData,
        editFormIndex: state.editFormIndex,
        newCellIndex: state.newCellIndex,
        conversationDrafts: state.conversationDrafts,
        setNewCellIndex,
        setConversationDraft,
        openEditor,
        closeEditor,
        selectCell,
        deselectCell,
        copiedCell,
        persistCopiedCell,
        removeCopiedCell,
        openAddMenu,
        openAddForm,
        closeAddForm,
        openEditForm,
        closeEditForm,
        scrollToCell,
        setExploration: (exploration, newParameters) =>
          setExploration(exploration, newParameters === undefined ? parameters : newParameters),
        upsertExploration,
        deleteExploration,
        resetExploration,
        setCellById,
        getCellById,
        duplicateCell,
        addCells,
        createCellInstance,
        deleteCell,
        moveCell: wrappedMoveCell,
        moveCellUp,
        moveCellDown,
        setQueryMeta,
        getQueryMeta,
        getFieldsByCellId: (cellId: string) =>
          getFieldsByCellId(cellId, exploration, models, getVariables(), metrics),
        parameters,
        setParameter,
        getParameter,
        getParameters: () => parameters,
        getVariables,
        clearParameter,
        isDirty,
        drillDownByProperty,
        canvasState,
        setCanvasState,
        startConversation,
      }}>
      {children}
    </ExplorationContext.Provider>
  );
};
