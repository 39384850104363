import { Location } from 'react-router-dom';

const sanitizeHash = (hash: string) => hash.replace(/^#/, '');

export const addNextUrlParam = (url: string, location: Location) => {
  const next = `${location.pathname}${location.search}${location.hash}`;
  return next === '/' ? url : `${url}#next=${encodeURIComponent(next)}`;
};

export const retainNextUrlParam = (url: string, location: Location) => {
  const next = getNextUrl(location);
  return next === '/' ? url : `${url}#next=${encodeURIComponent(next)}`;
};

export const getNextUrl = (location: Location) => {
  const next = new URLSearchParams(sanitizeHash(location.hash)).get('next');
  return next ?? '/';
};

export const getDomain = (url: string) => {
  try {
    const u = new URL(url);
    return u.hostname;
  } catch {
    return null;
  }
};
