import { useMemo, useEffect, useState, MouseEvent, ReactEventHandler } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { isNil } from 'lodash';

import { Tooltip } from '@/components/tooltip';
import { Icon, IconName } from '@/components/icon';
import { getDomain } from '@/public/utils/url';

import styles from './footnote.module.scss';

interface Footnote {
  citeId: string;
  label: string;
  href: string;
  icon: IconName;
}

export const FootnoteList = ({ editor }: NodeViewProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [footnotes, setFootnotes] = useState<Footnote[]>([]);

  useEffect(() => {
    const generateFootnotes = () => {
      const footnoteNodes: Footnote[] = [];
      editor.state.doc.descendants((node) => {
        if (node.type.name === 'footnoteNode') {
          const href = node.attrs.href;
          const domain = isNil(href) || href.length === 0 ? null : getDomain(href);

          if (domain !== null) {
            footnoteNodes.push({
              href,
              citeId: node.attrs.citeId,
              label: node.attrs.label ?? `Link from ${domain}`,
              icon: getIcon(domain),
            });
          }
        }
      });
      setFootnotes(footnoteNodes);
    };

    generateFootnotes();

    editor.on('update', generateFootnotes);

    return () => {
      editor.off('update', generateFootnotes);
    };
  }, [editor]);

  const getIcon = (host: string | null) => {
    if (host === null) {
      return 'Globe';
    }
    if (host.endsWith('slack.com')) {
      return 'SlackIcon';
    }
    if (host.endsWith('linear.app')) {
      return 'LinearIcon';
    }

    return 'Globe';
  };

  const uniqueHosts = useMemo(() => {
    const domains: string[] = [];
    footnotes.map((note) => {
      const domain = getDomain(note?.href ?? '');
      if (domain !== null && !domains.includes(domain)) {
        domains.push(domain);
      }
    });
    return domains;
  }, [footnotes]);

  const handleToggle: ReactEventHandler<HTMLDetailsElement> = (event) => {
    setIsOpened((event.target as HTMLDetailsElement).open);
  };

  const handleClick = (event: MouseEvent) => {
    // Prevent selecting cell
    event.stopPropagation();
  };

  if (footnotes.length === 0) {
    return <></>;
  }

  return (
    <NodeViewWrapper className="footnote-list-node">
      <details className={styles.details} onToggle={handleToggle} onClick={handleClick}>
        {isOpened ? (
          <summary>
            Sources
            <Icon name="ChevronUp" size={16} />
          </summary>
        ) : (
          <summary>
            Sources ({footnotes.length})
            {uniqueHosts.map((host) => (
              <Icon key={host} name={getIcon(host)} size={16} />
            ))}
            <Icon name="ChevronDown" size={16} />
          </summary>
        )}

        <ol className={styles.footnotesList} data-custom-styles>
          {footnotes.map((footnote, index) => (
            <li key={index} id={`cite-${footnote.citeId}`} className="footnote-item">
              <a
                href={footnote.href}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className={styles.footnote}>
                <Icon name={footnote.icon} size={20} />
                {footnote.label}
              </a>{' '}
              <Tooltip content="Back to content">
                <a
                  href={`#cite-ref-${footnote.citeId}`}
                  className={styles.footnoteRef}
                  aria-label="Back to content">
                  ↩
                </a>
              </Tooltip>
            </li>
          ))}
        </ol>
      </details>
    </NodeViewWrapper>
  );
};
