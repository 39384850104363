import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';

import { useTrackEvent } from '@/lib/analytics';

import { SlackReference, SlackReferenceProps } from './slack-reference';
import { LinearReference, LinearReferenceProps } from './linear-reference';

import styles from './reference-block.module.scss';

type ReferenceBlockProps = LinearReferenceProps | SlackReferenceProps;

export const ReferenceBlock = ({ node }: NodeViewProps) => {
  const trackEvent = useTrackEvent();

  const props = node.attrs as ReferenceBlockProps;
  const { source, url } = props;

  const trackClick = () => {
    trackEvent('Reference clicked', { source, url });
    window.open(url, '_blank');
  };

  const renderReference = () => {
    if (props.source === 'slack') {
      return <SlackReference {...props} />;
    }
    if (props.source === 'linear') {
      return <LinearReference {...props} />;
    }
    return <></>;
  };

  return (
    <NodeViewWrapper className="reference-node">
      <a
        href={url}
        rel="noopener noreferrer nofollow"
        target="_blank"
        className={styles.wrapper}
        onClick={trackClick}>
        {renderReference()}
      </a>
    </NodeViewWrapper>
  );
};
