import React from 'react';
import { Link } from 'react-router-dom';
import { first } from 'lodash';

import { useBuildAccountUrl } from '@/lib/accounts/context';
import { exhaustiveCheck } from '@/lib/exhaustive';
import { Button } from '@/components/button';
import { FormInputSize } from '@/components/form/types';
import { Icon } from '@/components/icon';
import { useMetadataContext } from '@/explore/metadata-context';
import { Exploration } from '@/explore/types';
import { buildExplorationHashUrl } from '@/explore/utils';
import { buildExplorationByType, BuildType } from '@/core/exploration';

interface AddButtonProps {
  type: BuildType;
  size?: FormInputSize;
  onClick?: (exploration: Exploration) => void;
}

export const AddButton = ({ type, size = 'large', onClick }: AddButtonProps) => {
  const buildAccountUrl = useBuildAccountUrl();
  const { models } = useMetadataContext();
  const baseModelId = first(models)?.modelId ?? '';
  const iconSize = 16;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick === undefined) {
      return;
    }

    event.preventDefault();

    onClick(buildExplorationByType(type, baseModelId));
  };

  const button =
    type === 'funnel' ? (
      <Button icon={<Icon name="Funnel" size={iconSize} />} variant="secondary" size={size}>
        Funnel chart
      </Button>
    ) : type === 'cohort' ? (
      <Button icon={<Icon name="Cohort" size={iconSize} />} variant="secondary" size={size}>
        Retention chart
      </Button>
    ) : type === 'variable' ? (
      <Button icon={<Icon name="Variable" size={iconSize} />} variant="secondary" size={size}>
        Variable
      </Button>
    ) : type === 'text' ? (
      <Button icon={<Icon name="AlignLeft" size={iconSize} />} variant="secondary" size={size}>
        Text
      </Button>
    ) : type === 'sql' ? (
      <Button icon={<Icon name="Database" size={iconSize} />} variant="secondary" size={size}>
        SQL
      </Button>
    ) : type === 'python' ? (
      <Button icon={<Icon name="Python" size={iconSize} />} variant="secondary" size={size}>
        Python
      </Button>
    ) : (
      exhaustiveCheck(type)
    );

  return (
    <Link
      onClick={handleClick}
      to={buildAccountUrl(buildExplorationHashUrl(buildExplorationByType(type, baseModelId)))}
      tabIndex={-1}>
      {button}
    </Link>
  );
};
