import { useCallback, useMemo } from 'react';

import { Select, Option, withEmptyOptionForMissingValue } from '@/components/form/select';
import { FormField } from '@/components/form/form-field';
import { EnumVariableDefinition } from '@/explore/types';
import { useExplorationContext } from '@/explore/exploration/exploration-context';

import { useMetadataContext } from '../metadata-context';
import { getCellOptions, getFieldOptions } from './utils/enum-variable';

import formStyles from '../../components/form/form.module.scss';

interface EnumVariableDefinitionFormProps {
  definition: EnumVariableDefinition;
  onSubmit: (definition: EnumVariableDefinition) => void;
}

export const EnumVariableDefinitionForm = (props: EnumVariableDefinitionFormProps) => {
  const { definition } = props;
  const { exploration, getVariables } = useExplorationContext();
  const { models, metrics } = useMetadataContext();

  const cellOptions = getCellOptions(exploration);

  const getFieldsByPipelineId = useCallback(
    (pipelineId: string): Option[] =>
      getFieldOptions(pipelineId, exploration, {
        models,
        metrics,
        variables: getVariables(),
      }),
    [exploration, getVariables, models, metrics],
  );

  const options = useMemo(
    () => getFieldsByPipelineId(definition.queryOptions.pipelineId),
    [definition.queryOptions.pipelineId, getFieldsByPipelineId],
  );

  const handleChangeBlock = (pipelineId: string) =>
    props.onSubmit({
      ...definition,
      queryOptions: {
        pipelineId,
        field: getFieldsByPipelineId(pipelineId).at(0)?.value ?? '',
      },
    });

  const handleChangeField = (field: string) =>
    props.onSubmit({ ...definition, queryOptions: { ...definition.queryOptions, field } });

  return (
    <>
      <div className={formStyles.formRow}>
        <FormField label="Block">
          <Select
            options={withEmptyOptionForMissingValue(
              cellOptions,
              definition.queryOptions.pipelineId,
            )}
            value={definition.queryOptions.pipelineId}
            onChange={handleChangeBlock}
          />
        </FormField>
      </div>
      <div className={formStyles.formRow}>
        <FormField label="Field">
          <Select
            options={withEmptyOptionForMissingValue(options, definition.queryOptions.field)}
            value={definition.queryOptions.field}
            onChange={handleChangeField}
          />
        </FormField>
      </div>
    </>
  );
};
