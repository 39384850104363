import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ReferenceBlock } from '../../components/reference-block';

export const ReferenceNode = Node.create({
  name: 'referenceNode',
  type: 'referenceNode',
  group: 'block',
  atom: true,
  inline: false,
  selectable: true,
  draggable: true,

  parseHTML() {
    return [{ tag: 'reference-node' }];
  },
  renderText({ node }) {
    return `<reference-node source="${node.attrs.source}" url="${node.attrs.url}" avatar="${node.attrs.avatar}" username="${node.attrs.username}" content="${node.attrs.content}" date="${node.attrs.date}"></reference-node>`;
  },
  renderHTML({ HTMLAttributes }) {
    return ['reference-node', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(ReferenceBlock);
  },
  addAttributes() {
    return {
      // TODO: Do we have to define all attrs of all source types?
      source: {
        default: undefined,
      },
      url: {
        default: undefined,
      },
      avatar: {
        default: undefined,
      },
      username: {
        default: undefined,
      },
      content: {
        default: undefined,
      },
      date: {
        default: undefined,
      },
    };
  },
});
