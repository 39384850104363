import { createContext, useContext, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate, useLocation, useParams, Navigate, Link } from 'react-router-dom';
import { pickBy } from 'lodash';

import { Account, useWorkspaceContext } from '@/workspace/context';

import { accountOnboardingBasePath, shouldRedirectToOnboarding } from '../../onboarding/utils';
import { hasPermission } from './utils';
import { ErrorBanner } from '../../components/banner';

export type { Account } from '@/workspace/context';

const LS_SELECTED_ACCOUNT_KEY = 'selectedAccount';

interface AccountContext {
  account: Account;
  accounts: Account[];
  switchAccount(accountId: string): void;
  hasPermission(permission: string): boolean;
  enabledFeatures: string[];
  isFeatureEnabled(feature: string): boolean;
}

const defaultContextValue = Symbol();

const AccountContext = createContext<AccountContext | typeof defaultContextValue>(
  defaultContextValue,
);

export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (context === defaultContextValue) {
    throw new Error('useAccountContext must be used within a AccountContextProvider');
  }
  return context;
};

const findAccountBySlug = (slug: string, accounts: Account[]) =>
  accounts.find((account) => account.slug === slug);

const findAccountById = (accountId: string, accounts: Account[]) =>
  accounts.find((account) => account.accountId === accountId);

const getLocalStorageAccountId = () => window.localStorage.getItem(LS_SELECTED_ACCOUNT_KEY);

const setLocalStorageAccountId = (accountId: string) =>
  window.localStorage.setItem(LS_SELECTED_ACCOUNT_KEY, accountId);

const prependSlug = (slug: string, path: string) => `/${slug}/${trimPath(path)}`;

const replaceSlug = (slug: string) => `/${slug}`;

const trimPath = (path: string) => (path[0] === '/' ? path.slice(1) : path);

interface AccountContextProviderProps {
  children: React.ReactNode;
}

export const AccountContextProvider = ({ children }: AccountContextProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { account: selectedAccountSlug } = useParams();

  const { accounts } = useWorkspaceContext();

  useEffect(() => {
    if (accounts === null || accounts.length === 0) {
      return;
    }

    const account =
      findAccountBySlug(selectedAccountSlug ?? '', accounts) ??
      findAccountById(getLocalStorageAccountId() ?? '', accounts) ??
      accounts[0];

    if (account.slug !== selectedAccountSlug) {
      navigate(replaceSlug(account.slug));
    }
  }, [selectedAccountSlug, accounts, navigate, location.pathname]);

  const account = findAccountBySlug(selectedAccountSlug ?? '', accounts) ?? null;

  // Account selection side effects
  useEffect(() => {
    if (account === null) {
      return;
    }
    const accountId = account.accountId;
    setLocalStorageAccountId(accountId);

    Sentry.setTags({
      account_id: accountId,
      account_name: account.name,
      account_slug: account.slug,
    });
  }, [account]);

  const switchAccount = (accountId: string) => {
    const account = findAccountById(accountId, accounts);
    if (account === undefined) {
      throw new Error(`Cannot switch account to ${accountId}. Account not found.`);
    }
    navigate(replaceSlug(account.slug));
  };

  if (account === null) {
    return null;
  }

  if (shouldRedirectToOnboarding(account, location.pathname)) {
    return <Navigate to={accountOnboardingBasePath(account)} />;
  }

  const enabledFeatures = Object.keys(pickBy(account.features, Boolean) ?? {}) ?? [];

  const accountData = {
    account,
    accounts,
    switchAccount,
    hasPermission: (permission: string) => hasPermission(account.me.role, permission),
    enabledFeatures,
    isFeatureEnabled: (feature: string) => enabledFeatures.includes(feature),
  };

  return <AccountContext.Provider value={accountData}>{children}</AccountContext.Provider>;
};

export const RedirectToAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { accounts } = useWorkspaceContext();

  useEffect(() => {
    if (accounts === null || accounts.length === 0) {
      return;
    }

    const account = findAccountById(getLocalStorageAccountId() ?? '', accounts) ?? accounts[0];
    navigate(prependSlug(account.slug, location.pathname), { replace: true });
  }, [accounts, navigate, location.pathname]);

  if (accounts === null || accounts.length === 0) {
    return (
      <ErrorBanner
        title="No accounts found"
        description={
          <>
            <p>You do not have access to any accounts. Please contact your administrator.</p>
            <p>
              <Link to="/logout">Log out</Link>
            </p>
          </>
        }
      />
    );
  }

  return null;
};

export function useSelectedAccount() {
  const accountData = useAccountContext();
  return accountData.account;
}

export function useAccountTimezone() {
  const { account } = useAccountContext();
  return account.timezone ?? 'UTC';
}

export function useBuildAccountUrl() {
  const accountData = useAccountContext();
  return (path: string) => prependSlug(accountData.account.slug, path);
}
