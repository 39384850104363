import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { useInteractions, useListItem } from '@floating-ui/react';

import { Exploration } from '../types';
import { Icon } from '../../components/icon';
import { PipelinePreview } from '../exploration/pipeline-preview';
import { CellNode } from '.';
import { useMetadataContext } from '../metadata-context';

import * as styles from './exploration-search.module.scss';

interface CellListProps {
  exploration: Exploration;
  activeIndex: number | null;
  items: CellNode[];
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  disabled?: boolean;
}

export const CellList = (props: CellListProps) => {
  return (
    <ol>
      {props.items.map((item, i) => {
        return (
          <CellItem
            key={i}
            item={item}
            activeIndex={props.activeIndex}
            exploration={props.exploration}
            disabled={props.disabled}
            getItemProps={props.getItemProps}
          />
        );
      })}
    </ol>
  );
};

interface CellItemProps {
  exploration: Exploration;
  activeIndex: number | null;
  item: CellNode;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  disabled?: boolean;
}

const CellItem = (props: CellItemProps) => {
  const { item, activeIndex, getItemProps, disabled = false } = props;
  const { cell, url } = item;

  const { models } = useMetadataContext();
  const { ref, index } = useListItem();

  return (
    <Link
      to={url}
      className={classNames(styles.listItem, { [styles.active]: activeIndex === index })}
      ref={ref}
      aria-disabled={disabled}
      {...getItemProps({
        onClick: item.onClick,
      })}>
      <Icon name="Block" size={32} className={styles.icon} />
      <div className={styles.name}>{cell.title}</div>
      <div className={styles.pipeline}>
        <PipelinePreview
          models={models}
          title={cell.title ?? 'Untitled'}
          exploration={props.exploration}
          pipeline={cell.pipeline}
          interactive={false}
        />
      </div>
    </Link>
  );
};
