import { model } from '@gosupersimple/types';

// in python pandas everything is an object by default
export const defaultPythonType = 'object' as const;
export const tsToPytonTypeMap: { [key in model.PropertyType]: string } = {
  Integer: 'Int64',
  Float: 'Float64',
  Number: 'Float64',
  String: 'object',
  Enum: 'object',
  Array: 'object',
  Object: 'object',
  Boolean: 'bool',
  Date: 'datetime64[ns]',
  Interval: 'timedelta64[ns]',
};

// TODO: investigate how jupyter and jupyter-lite does this
export const pythonTypeConverter = `
from js import Object
from pyodide.ffi import to_js

def classname(obj):
    cls = type(obj)
    module = cls.__module__
    name = cls.__qualname__
    if module is not None and module != "__builtin__":
        name = module + "." + name
    return name

def pandas_converter(result):
    type_map = {
        "Int64": "Integer",
        "Float64": "Float",
        "object": "String",
        "bool": "Boolean",
        "datetime64[ns]": "Date",
        "timedelta64[ns]": "Interval",
    }
    sup_types = set(type_map.values())

    dtypes_dict = (
        result.dtypes.reset_index()
        .rename(columns={0: "dtype", "index": "key"})
        .assign(
            **{
                "name": lambda df: df["key"],
                "type": lambda df: df["dtype"]
                .apply(lambda x: str(x))
                .map(type_map)
                .apply(lambda x: x if x in sup_types else "String"),
            }
            )
            .drop(columns=["dtype"])
            .to_dict(orient="records")
    )

    return dict(
        type="table",
        raw_py_class=classname(result),
        fields=dtypes_dict,
        rows=result.to_json(orient="records", date_format="iso"),
    )

def html_converter(result):
    return dict(
        type='html',
        raw_py_class=classname(result),
        htmlString=result._repr_html_(),
    )

def plotly_html_converter(result):
    return dict(
        type='html',
        raw_py_class=classname(result),
        htmlString=result.to_html(include_plotlyjs=True, full_html=False),
    )

def plot_converter(result):
    import io, base64

    buf = io.BytesIO()
    plt.savefig(buf, format='png', bbox_inches='tight')
    buf.seek(0)
    plt_base64_string = 'data:image/png;base64,' + base64.b64encode(buf.read()).decode('UTF-8')

    return dict(
        type='image',
        raw_py_class=classname(result),
        base64ImageString=plt_base64_string,
    )

def iterable_converter(result):
    return dict(
        type='primitive',
        raw_py_class=classname(result),
        value=str(result),
    )

def primitive_converter(result):
    return dict(
        type='primitive',
        raw_py_class=classname(result),
        value=str(result),
    )

def convert(obj):
    if obj.__class__.__name__ == 'DataFrame':
        return to_js(pandas_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, 'to_html') and 'plotly.' in obj.__class__.__module__:
        return to_js(plotly_html_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, '_repr_html_'):
        return to_js(html_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, "savefig") or "matplotlib." in str(obj.__class__) or (hasattr(obj, "__name__") and "matplotlib." in obj.__name__) or (isinstance(obj, list) and len(obj) > 0 and "matplotlib." in str(type(obj[0]))):
        return to_js(plot_converter(obj), dict_converter=Object.fromEntries)
    elif hasattr(obj, "__len__"):
        return to_js(iterable_converter(obj), dict_converter=Object.fromEntries)
    else:
        return to_js(primitive_converter(obj), dict_converter=Object.fromEntries)

convert
`;
