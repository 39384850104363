import classNames from 'classnames';
import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  type JSX,
  Fragment,
} from 'react';
import { first } from 'lodash';
import { useFuzzySearchList } from '@nozbe/microfuzz/react';

import {
  FloatingList,
  useFloating,
  useInteractions,
  useListItem,
  useListNavigation,
} from '@floating-ui/react';

import { useClientRect } from '@/lib/hooks/use-client-rect';

import { FormInputSize, SelectOptions, isOptionGroup, SelectOption, Option } from '../types';
import { Input } from '../input';
import { Icon } from '../../icon';
import { Overlay } from '../../overlay';

import styles from './search-input.module.scss';

interface BaseSearchInputProps {
  options: SelectOptions;
  autoFocus?: boolean;
  className?: classNames.Argument;
  size?: FormInputSize;
  disabled?: boolean;
  placeholder?: string;
}

export interface SearchInputProps<T extends string | string[]> extends BaseSearchInputProps {
  value?: T;
  onChange: (value: T) => void;
}

const getSelectedOptionLabel = ({
  value,
  placeholder,
  selectedOptionParent,
  options,
}: {
  value?: string | string[];
  placeholder: string;
  selectedOptionParent?: SelectOption;
  options: SelectOptions;
}) => {
  const flatOptions = options.flatMap((option) =>
    isOptionGroup(option) ? option.options : option,
  );
  const selectedOption = Array.isArray(value)
    ? flatOptions.find((option) => value.includes(option.value))
    : flatOptions.find((option) => option.value === value);

  if (Array.isArray(value)) {
    if (value.length > 1) {
      return `${value.length} selected`;
    }
    if (value.length === 1) {
      return value[0];
    }
    return placeholder;
  }

  return selectedOptionParent !== undefined
    ? `${selectedOption?.label} (${selectedOptionParent.label})`
    : // TODO: Fall back to placeholder if no selected option
      (selectedOption?.label ?? first(flatOptions)?.label ?? '');
};

function SearchInput(props: SearchInputProps<string>): JSX.Element;
function SearchInput(props: SearchInputProps<string[]>): JSX.Element;
function SearchInput<T extends string | string[]>(props: SearchInputProps<T>) {
  const {
    options,
    value,
    autoFocus,
    placeholder = '',
    onChange,
    size = 'small',
    disabled = false,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const triggerRef = useRef<HTMLButtonElement>(null);
  const triggerRect = useClientRect(triggerRef, [isOpen]);

  const selectedOptionParent = options.find(
    (option) => isOptionGroup(option) && option.options.some((o) => o.value === value),
  );
  const selectedOptionLabel = useMemo(
    () => getSelectedOptionLabel({ value, options, selectedOptionParent, placeholder }),
    [value, options, selectedOptionParent, placeholder],
  );

  const closePanel = useCallback(() => {
    setIsOpen(false);
    triggerRef.current?.focus();
  }, [triggerRef]);

  useEffect(() => {
    if (autoFocus === true) {
      triggerRef.current?.focus();
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(styles.searchInput, props.className, {
        [styles.sizeSmall]: size === 'small',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
      })}>
      <SearchInputTrigger
        label={selectedOptionLabel}
        onOpenPanel={(searchTerm) => {
          setSearchTerm(searchTerm ?? '');
          setIsOpen(!isOpen);
        }}
        ref={triggerRef}
        disabled={disabled}
      />
      {isOpen && (
        <Overlay>
          <>
            <div className={styles.backdrop} onClick={() => setIsOpen(false)} />
            <SearchInputPanel<T>
              searchTerm={searchTerm}
              options={options}
              value={value}
              position={{
                top: triggerRect?.top ?? 0,
                left: triggerRect?.left ?? 0,
              }}
              placeholder={placeholder}
              width={triggerRect?.width ?? 0}
              onChangeSearchTerm={setSearchTerm}
              onChange={(value) => {
                onChange(value);
                closePanel();
              }}
              onClose={closePanel}
              size={size}
            />
          </>
        </Overlay>
      )}
    </div>
  );
}

type SearchInputTriggerProps = {
  label: string;
  disabled?: boolean;
  onOpenPanel(searchTerm?: string): void;
};

const SearchInputTrigger = forwardRef(function SearchInputTrigger(
  props: SearchInputTriggerProps,
  ref?: React.ForwardedRef<HTMLButtonElement>,
) {
  const { label, disabled = false, onOpenPanel } = props;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        onOpenPanel();
      }
      if (e.key.length === 1) {
        e.preventDefault();
        onOpenPanel(e.key);
      }
    },
    [onOpenPanel],
  );

  return (
    <button
      className={styles.trigger}
      type="button"
      onClick={() => onOpenPanel()}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      ref={ref}>
      <span className={styles.label}>{label}</span>
      <div className={styles.chevron}>
        <svg
          focusable="false"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          aria-hidden="true">
          <path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z" />
        </svg>
      </div>
    </button>
  );
});

interface SearchInputPanelProps<T extends string | string[]> {
  options: SelectOptions | Readonly<SelectOptions>;
  searchTerm: string;
  value?: T;
  position: { top: number; left: number };
  width: number;
  placeholder?: string;
  onChangeSearchTerm: (value: string) => void;
  onChange: (value: T) => void;
  onClose?: () => void;
  size?: FormInputSize;
}

const flattenOptions = (options: SelectOptions | Readonly<SelectOptions>) =>
  options.flatMap((option) => (isOptionGroup(option) ? option.options : option));

const SearchInputPanel = <T extends string | string[]>(props: SearchInputPanelProps<T>) => {
  const {
    options,
    value,
    position,
    width,
    placeholder,
    searchTerm,
    onChangeSearchTerm,
    onChange,
    onClose,
    size,
  } = props;

  const filteredValues = useFuzzySearchList<Option, string>({
    strategy: 'smart',
    list: flattenOptions(options),
    queryText: searchTerm.trim(),
    getText: (option) => [option.label, option.value],
    mapResultItem: ({ item }) => item.value,
  });

  const matchCount = filteredValues.length;

  const isMatch = (option: SelectOption) =>
    isOptionGroup(option) || filteredValues.includes(option.value);

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [direction, setDirection] = useState<'up' | 'down'>('down');

  const firstSelectedValueIndex = flattenOptions(options)
    .filter(isMatch)
    .findIndex((option) => option.value === value);

  const { refs, context } = useFloating({
    open: true,
  });
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    virtual: true,
    loop: true,
    selectedIndex: firstSelectedValueIndex,
    onNavigate: (index) => setActiveIndex(index),
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([listNavigation]);

  const listRect = useClientRect(refs.floating, [searchTerm]);

  const filteredOptions = options.filter(isMatch).map((option) => {
    if (isOptionGroup(option)) {
      return {
        ...option,
        options: option.options.filter(isMatch),
      };
    }
    return option;
  });

  useEffect(() => {
    direction === 'down' &&
      listRect !== null &&
      listRect.top + listRect.height > window.innerHeight &&
      setDirection('up');
  }, [direction, listRect]);

  const handleSelectOption = useCallback(
    (option: Option) => {
      if (Array.isArray(value)) {
        const newValue = value.includes(option.value)
          ? value.filter((v) => v !== option.value)
          : [...value, option.value];
        onChange(newValue as T);
      } else {
        onChange(option.value as T);
      }
    },
    [value, onChange],
  );

  return (
    <FloatingList elementsRef={listRef}>
      <div
        className={classNames(styles.panel, {
          [styles.sizeSmall]: size === 'small',
          [styles.sizeMedium]: size === 'regular',
          [styles.sizeLarge]: size === 'large',
          [styles.openAbove]: direction === 'up',
        })}
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
          width: `${width}px`,
        }}>
        <Input
          size={size}
          value={searchTerm}
          placeholder={placeholder ?? 'Search...'}
          onChange={(e) => onChangeSearchTerm(e.target.value)}
          ref={refs.setReference}
          autoFocus
          {...getReferenceProps({
            onKeyDown: (e) => {
              if (e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                onClose?.();
                return;
              }
              const activeOption = flattenOptions(filteredOptions).at(
                activeIndex !== null && activeIndex !== -1 ? activeIndex : 0,
              );
              if ((e.key === 'Enter' || e.key === 'Tab') && activeOption !== undefined) {
                e.preventDefault();
                handleSelectOption(activeOption);
              }
            },
          })}
        />
        <div ref={refs.setFloating} className={styles.items} role="listbox" {...getFloatingProps()}>
          {matchCount === 0 ? (
            <div className={styles.empty} role="listbox">
              No matches found
            </div>
          ) : (
            <ItemList
              options={filteredOptions}
              onClick={(option) => handleSelectOption(option)}
              getItemProps={getItemProps}
              activeIndex={activeIndex}
              value={value}
            />
          )}
        </div>
      </div>
    </FloatingList>
  );
};

type ItemListProps = {
  options: SelectOption[];
  value?: string | string[];
  activeIndex: number | null;
  onClick: (item: Option) => void;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
};

const ItemList = (props: ItemListProps) => {
  const { options, value } = props;

  return (
    <>
      {options.map((option, i) => {
        if ('options' in option) {
          if (option.options.length === 0) {
            return null;
          }
          return (
            <Fragment key={i}>
              <div className={styles.title}>{option.label}</div>
              <ItemList
                options={option.options}
                onClick={props.onClick}
                activeIndex={props.activeIndex}
                getItemProps={props.getItemProps}
                value={props.value}
              />
            </Fragment>
          );
        }
        const isSelected = Array.isArray(value)
          ? value.includes(option.value)
          : option.value === value;
        return (
          <Item
            key={i}
            option={option}
            onClick={props.onClick}
            selected={isSelected}
            activeIndex={props.activeIndex}
            getItemProps={props.getItemProps}
          />
        );
      })}
    </>
  );
};

type ItemProps = {
  option: Option;
  selected?: boolean;
  activeIndex: number | null;
  onClick: (option: Option) => void;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
};

const Item = (props: ItemProps) => {
  const { option, selected = false } = props;
  const { ref, index } = useListItem();

  return (
    <button
      type="button"
      role="option"
      aria-selected={selected}
      title={'title' in option ? option.title : option.label}
      className={classNames(styles.item, {
        [styles.selected]: selected,
        [styles.active]: index === props.activeIndex,
      })}
      disabled={option.disabled}
      ref={ref}
      {...props.getItemProps({
        onClick: () => props.onClick(option),
      })}>
      {option.icon !== undefined && <Icon size={16} name={option.icon} />}
      <span className={styles.label}>{option.label}</span>
      {selected && <Icon size={16} name="Check" className={styles.check} />}
    </button>
  );
};

export { SearchInput };
