import { useMemo, useState } from 'react';
import { first } from 'lodash';

import { common } from '@gosupersimple/types';

import { useAccountContext, useAccountTimezone } from '@/lib/accounts/context';
import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { getNodes, useExplorationDataQuery } from '@/graphql';
import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';
import { useIsInView, useQueryLoadCondition } from '@/lib/hooks';
import { isTimeoutError } from '@/lib/error/utils/timeout';
import { useMetadataContext } from '@/explore/metadata-context';
import { precisionToTimeInterval } from '@/explore/pipeline/state';
import { setCellTitle } from '@/core/cell';
import { createDereferencedPipeline } from '@/core/pipeline';
import { HideInEmbedded } from '@/components/layout/hide-in-embedded';
import { DataWarning } from '@/explore/components/visualisation/data-warning';

import { Exploration, FunnelCell, Grouping, Metric, Model } from '../../types';
import { DataTableProperty } from '../../components/datatable/types';
import { NestedList, NestedListItem } from '../../grouping';
import { FunnelChart } from '../../components/charts/funnel-chart';
import { useExplorationCellContext } from '../exploration-cell-context';
import { useExplorationContext } from '../exploration-context';
import { convertRecordTypeTypes } from '../../input';
import { CellTitle } from '../cell-title';
import { CollapsibleContainer, CollapseButton, CollapsibleContent } from '../collapsible-cell';
import { formatPropertyValue } from '../../utils/format';
import { getStepName, getFunnelOperation } from '../../edit-funnel/utils';
import { CellControls } from '../cell-controls';
import { ErrorBanner, IconBanner } from '../../../components/banner';
import { dereferenceFunnelOperation } from '../../pipeline/utils';
import { filterVariablesForPipeline } from '../../utils';
import { TableSkeleton } from '../../../components/skeleton-loader';
import { Icon } from '../../../components/icon';
import { SqlPreview } from '../sql-preview';
import { validateFunnel } from './utils';

import { YamlPreview } from '../yaml-preview';

import styles from '../exploration.module.scss';

const MinHeight = 285;

type EmptyViewProps = {
  requiresAction: boolean;
};

const EmptyView = ({ requiresAction }: EmptyViewProps) => (
  <IconBanner
    requiresAction={requiresAction}
    iconName="Funnel"
    minHeight={MinHeight}
    title="Select at least 2 events"
    description="You can use sections created on this page as well as any predefined exploration"
  />
);

interface FunnelCellViewProps {
  cell: FunnelCell;
  exploration: Exploration;
  height?: number;
  onSelectCell?: () => void;
  onSetDraggable: (value: boolean) => void;
}

const InvalidView = (props: { message: string }) => (
  <IconBanner
    iconName="Funnel"
    minHeight={MinHeight}
    title="Invalid funnel"
    description={props.message}
  />
);

interface GroupedFunnelValue {
  label: string[]; // each element is a group label
  value: number | null;
}

const recurseGrouping = (
  record: NestedListItem,
  groups: Grouping[],
  key: string,
  stepIndex: number,
  fields: DataTableProperty[],
  timezone: string,
  variables: common.QueryVariables,
  groupIndex = 0,
): GroupedFunnelValue[] => {
  const field = fields.find((field) => field.key === groups[groupIndex].key);
  if (groupIndex === groups.length - 1) {
    const precision = groups[groupIndex].precision;
    return [
      {
        label: [
          formatPropertyValue(record[groups[groupIndex].key], {
            field,
            precision: precisionToTimeInterval(precision, variables),
            timezone: timezone,
          }),
        ],
        value:
          record.$children === undefined || !(stepIndex in record.$children)
            ? null
            : Number(record.$children[stepIndex][key]),
      },
    ];
  }
  return (
    record.$children?.reduce((acc: GroupedFunnelValue[], child: NestedListItem) => {
      const children = recurseGrouping(
        child,
        groups,
        key,
        stepIndex,
        fields,
        timezone,
        variables,
        groupIndex + 1,
      );
      return [
        ...acc,
        ...children.map(({ label, value }) => ({
          label: [formatPropertyValue(record[groups[groupIndex].key], { field })].concat(label),
          value,
        })),
      ];
    }, [] as GroupedFunnelValue[]) ?? []
  );
};

/**
 * Recursively traverse the API response and get all values for a given step.
 */
const getGroupedStepValues = (
  records: NestedList,
  stepIndex: number,
  groups: Grouping[] | undefined,
  propertyKey: string,
  fields: DataTableProperty[],
  timezone: string,
  variables: common.QueryVariables,
): { label: string; value: number | null }[] =>
  records
    .reduce(
      (acc, record) => [
        ...acc,
        ...recurseGrouping(
          record,
          groups ?? [],
          propertyKey,
          stepIndex,
          fields,
          timezone,
          variables,
        ),
      ],
      [] as GroupedFunnelValue[],
    )
    .map(({ label, value }: GroupedFunnelValue) => ({ label: label.join(', '), value }));

export const FunnelCellView = (props: FunnelCellViewProps) => {
  const { cell, exploration } = props;
  const { models, metrics: metrics } = useMetadataContext();
  const { getVariables } = useExplorationContext();
  const { setCell, isSelectedCell, isConversationCell, isCollapsible, getYaml, startConversation } =
    useExplorationCellContext();
  const screenSize = useScreenSize();
  const [isShowingSql, setIsShowingSql] = useState(false);
  const [isShowingYaml, setIsShowingYaml] = useState(false);
  const [isDragHovered, setIsDragHovered] = useState(false);
  const [containerRef, isInView] = useIsInView();

  const variables = getVariables();

  const handleSetIsDragHovered = (value: boolean) => {
    props.onSetDraggable(value);
    setIsDragHovered(value);
  };

  const editButtonVisible = screenSize.breakpoint <= Breakpoint.md;

  return (
    <CollapsibleContainer
      className={styles.funnelViewCell}
      onClick={props.onSelectCell}
      ref={containerRef}>
      <div className={styles.cellHeader}>
        <div className={styles.cellControlsContainer}>
          <HideInEmbedded>
            {!isConversationCell && (
              <Icon
                name="DragHandle"
                size={10}
                className={styles.dragHandle}
                onMouseOver={() => handleSetIsDragHovered(true)}
                onMouseOut={() => handleSetIsDragHovered(false)}
              />
            )}
          </HideInEmbedded>
          <CellTitle
            exploration={exploration}
            value={cell.title ?? '(Untitled)'}
            onChange={(value) => setCell(setCellTitle(cell, value))}
          />
          <CellControls
            exploration={exploration}
            editButtonVisible={editButtonVisible}
            options={(defaultOptions) => [
              ...defaultOptions,
              {
                label: 'Ask about this block',
                icon: <Icon name="Zap" size={16} />,
                onClick: startConversation,
                sort: 1,
                disabled: isConversationCell,
                tooltip: isConversationCell
                  ? 'This block is already part of a conversation'
                  : undefined,
              },
              {
                type: 'divider',
                sort: 1,
              },
              {
                type: 'divider',
                sort: 30,
              },
              {
                label: 'Show YAML',
                icon: <Icon name="Code" size={16} />,
                onClick: () => setIsShowingYaml(true),
                sort: 32,
                disabled: !validateFunnel(cell.pipeline, props.exploration, {
                  models,
                  variables,
                  metrics,
                }).isValid,
              },
              {
                label: 'Show SQL',
                icon: <Icon name="Database" size={16} />,
                onClick: () => setIsShowingSql(true),
                sort: 32,
                disabled: !validateFunnel(cell.pipeline, props.exploration, {
                  models,
                  variables,
                  metrics,
                }).isValid,
              },
            ]}
          />
          {isCollapsible && <CollapseButton />}
        </div>
      </div>
      <CollapsibleContent isDragHovered={isDragHovered}>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          {isShowingSql && (
            <SqlPreview
              pipeline={props.cell.pipeline}
              exploration={props.exploration}
              onClose={() => setIsShowingSql(false)}
            />
          )}
          {isShowingYaml && (
            <YamlPreview yaml={getYaml()} onClose={() => setIsShowingYaml(false)} />
          )}
          <FunnelCellViewInner
            selected={isSelectedCell}
            models={models}
            metrics={metrics}
            isInView={isInView}
            {...props}
          />
        </ErrorBoundary>
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};

type FunnelCellViewInnerProps = FunnelCellViewProps & {
  models: Model[];
  metrics: Metric[];
  selected: boolean;
  isInView: boolean;
  height?: number;
};

const FunnelCellViewInner = (props: FunnelCellViewInnerProps) => {
  const { cell, models, metrics, exploration, selected } = props;
  const { account, isFeatureEnabled } = useAccountContext();
  const { getVariables } = useExplorationContext();
  const { isCollapsed } = useExplorationCellContext();
  const timezone = useAccountTimezone();

  const baseModelId = first(models)?.modelId ?? '';
  const funnelOperation = getFunnelOperation(cell.pipeline);
  const operations = [dereferenceFunnelOperation(funnelOperation, exploration)];
  const variables = filterVariablesForPipeline(
    createDereferencedPipeline({ baseModelId, operations }),
    getVariables(),
  );

  const hasEnoughSteps = funnelOperation.parameters.steps.length > 1;
  const isResized = props.height !== undefined;
  const funnelValidation = validateFunnel(cell.pipeline, exploration, {
    models,
    variables,
    metrics,
  });

  const [onCompleted, onError, skip] = useQueryLoadCondition(
    props.isInView,
    !isCollapsed,
    hasEnoughSteps,
    funnelValidation.isValid,
  );

  const { data, loading, error, refetch } = useExplorationDataQuery({
    variables: {
      accountId: account.accountId,
      baseModelId,
      pipeline: operations,
      variables,
      options: {
        labels: { exploration: exploration.explorationId, block: cell.id },
        optimize: isFeatureEnabled('optimizeQuery'),
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    skip,
    onCompleted,
    onError,
  });

  const recordType = useMemo(
    () => convertRecordTypeTypes(data?.account?.query?.recordType),
    [data],
  );

  const records = getNodes(data?.account?.query);
  const isNotFullData = data?.account?.query?.pageInfo.hasNextPage ?? false;
  const isGrouped =
    funnelOperation.parameters.groups !== undefined && funnelOperation.parameters.groups.length > 0;

  const funnelData =
    records.length === 0
      ? []
      : funnelOperation.parameters.steps.map((step, i) => {
          const stepName = getStepName(step, models, exploration);
          const propertyKey = funnelOperation.parameters.property.key;

          if (isGrouped) {
            return {
              label: stepName,
              values: getGroupedStepValues(
                records,
                i,
                funnelOperation.parameters.groups,
                propertyKey,
                recordType ?? [],
                timezone,
                variables,
              ),
            };
          }

          if (i > records.length - 1) {
            throw new Error('Funnel returned fewer steps than was requested.');
          }
          if (!(propertyKey in records[i])) {
            throw new Error(`Funnel did not return a value with key '${propertyKey}'`);
          }

          return {
            label: stepName,
            values: [
              {
                label: 'Value',
                value: records[i][propertyKey],
              },
            ],
          };
        });

  return (
    <div className={styles.cellSection}>
      {!hasEnoughSteps ? (
        <EmptyView requiresAction={!selected} />
      ) : !funnelValidation.isValid ? (
        <InvalidView message={funnelValidation.error ?? ''} />
      ) : skip || (loading && data === undefined) ? (
        <TableSkeleton rows={6} cols={5} key="loading" />
      ) : error !== undefined && isTimeoutError(error) ? (
        <ErrorBanner
          title="Database didn't respond in time"
          actions={[{ label: 'Retry', onClick: () => refetch() }]}
        />
      ) : error !== undefined ? (
        <ErrorBanner
          details={error.message}
          actions={[{ label: 'Retry', onClick: () => refetch() }]}
        />
      ) : (
        <>
          {isNotFullData ? <DataWarning /> : null}
          <FunnelChart data={funnelData} height={MinHeight} isResized={isResized} />
        </>
      )}
    </div>
  );
};
