import { Icon } from '@/components/icon';

import { DropdownMenuItem } from '@/components/dropdown';

import { chartColors } from '../components/charts/utils';
import { ChartAxis, ChartType } from '../types';

type BuildSeriesMenuItemsProps = {
  color: string;
  chartTypes: ChartType[];
  axes: ChartAxis[];
  showValues: boolean[];
  isChartTypeDisabled: boolean;
  isSecondaryAxisDisabled: boolean;
  onChartTypeChange: (chartType: ChartType) => void;
  onColorChange?: (color: string | undefined) => void;
  onRemove?: () => void;
  onSecondaryAxisChange: (checked: boolean) => void;
  onShowValuesChange: (showValues: boolean) => void;
  onReset: () => void;
};

export const buildSeriesMenuItems = (props: BuildSeriesMenuItemsProps): DropdownMenuItem[] => {
  const {
    chartTypes,
    showValues,
    axes,
    color,
    isChartTypeDisabled,
    isSecondaryAxisDisabled,
    onChartTypeChange,
    onColorChange,
    onRemove,
    onSecondaryAxisChange,
    onShowValuesChange,
    onReset,
  } = props;

  return [
    {
      type: 'radio' as const,
      label: 'Area',
      checked: chartTypes.includes('area'),
      disabled: isChartTypeDisabled,
      onClick: () => onChartTypeChange('area'),
    },
    {
      type: 'radio' as const,
      label: 'Line',
      checked: chartTypes.includes('line'),
      disabled: isChartTypeDisabled,
      onClick: () => onChartTypeChange('line'),
    },
    {
      type: 'radio' as const,
      label: 'Bar',
      checked: chartTypes.includes('bar'),
      disabled: isChartTypeDisabled,
      onClick: () => onChartTypeChange('bar'),
    },
    {
      type: 'divider' as const,
    },
    axes.length <= 1
      ? {
          type: 'checkbox' as const,
          label: 'Use Secondary axis',
          checked: axes[0] === 'right',
          disabled: isSecondaryAxisDisabled,
          onChange: (isChecked: boolean) => onSecondaryAxisChange(isChecked),
        }
      : {
          type: 'mixed-checkbox' as const,
          label: 'Use Secondary axis',
          onClick: () => onSecondaryAxisChange(true),
        },
    showValues.length === 1
      ? {
          type: 'checkbox' as const,
          label: 'Show values',
          checked: showValues[0],
          onChange: (isChecked: boolean) => onShowValuesChange(isChecked),
        }
      : {
          type: 'mixed-checkbox' as const,
          label: 'Show values',
          onClick: () => onShowValuesChange(true),
        },
    ...(onColorChange !== undefined
      ? [
          {
            type: 'color' as const,
            label: 'Color',
            color,
            presetColors: chartColors,
            onChange: (color: string | undefined) => onColorChange(color),
          },
        ]
      : []),
    {
      type: 'divider' as const,
    },
    ...(onReset !== undefined
      ? [
          {
            label: 'Reset all options',
            onClick: () => onReset(),
            icon: <Icon name="RotateCcw" size={16} />,
          },
        ]
      : []),
    ...(onRemove !== undefined
      ? [
          {
            label: 'Delete',
            onClick: () => onRemove(),
            icon: <Icon name="Trash2" size={16} />,
          },
        ]
      : []),
  ];
};
