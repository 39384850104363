import { memo, useMemo, forwardRef, useState } from 'react';
import classNames from 'classnames';

import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { DisableInEmbedded } from '@/components/layout/disable-in-embedded';
import {
  isChatCell,
  isConversationCell,
  isConversationStart,
  isRecordsCell,
  isSqlCell,
} from '@/core/cell';

import { Cell, Exploration, ExplorationParameters, VariableDefinition } from '../types';
import { RecordsCellView } from './records-cell-view';
import { FunnelCellView } from './funnel-cell';
import { CohortCellView } from './cohort-cell';
import { VariableCellView } from './variable-cell';
import { SqlCellView } from './sql-cell';
import { PythonCellView } from './python-cell';
import { TextCellView } from './text-cell';
import { ChatCellView } from './chat-cell';
import { ExplorationCellContextProvider } from './exploration-cell-context';
import { InvalidCellView } from './invalid-cell';

import { DraggableCell } from './exploration-layout/draggable-cell';

import styles from './exploration.module.scss';

interface ExplorationCellProps {
  exploration: Exploration;
  parameters: ExplorationParameters;
  index: number;
  cell: Cell;
  selected?: boolean;
  variables: VariableDefinition[];
  isCollapsible: boolean;
  isResizable?: boolean;
  isLastConversationReply?: boolean;
  className?: string;
  style?: React.CSSProperties;
  canvasView?: boolean;
  height?: number;
  rowHeightOverride?: number | undefined;
  onMouseDown?: (e: React.MouseEvent) => void;
  onSelectCell?: () => void;
  setRowHeightOverride?: (height: number | undefined) => void;
}

export const ExplorationCell = memo(
  forwardRef(function ExplorationCell(
    props: ExplorationCellProps,
    ref?: React.ForwardedRef<HTMLDivElement | null>,
  ) {
    const {
      exploration,
      cell,
      index,
      parameters,
      selected = false,
      variables,
      rowHeightOverride,
      isCollapsible,
      isLastConversationReply = false,
      isResizable = true,
      canvasView = false,
      onSelectCell,
      onMouseDown,
      setRowHeightOverride,
    } = props;

    const [isDraggable, setIsDraggable] = useState(false);

    const classList = useMemo(
      () =>
        classNames([styles.cell, props.className], {
          [styles.selectedCell]: selected,
          [styles.selectedBorder]: selected && canvasView,
          [styles.transparent]: ['text', 'chat'].includes(cell.kind) && !canvasView,
          [styles.conversationChat]: ['chat'].includes(cell.kind),
          // TODO: Move this to ExplorationView, as it needs to wrap multiple rows sometimes (consecutive non-chat cells)
          [styles.conversation]:
            !canvasView &&
            isConversationCell(cell) &&
            !isChatCell(cell) &&
            !isConversationStart(cell, exploration.view.cells),
          [styles.conversationStart]:
            !canvasView &&
            isConversationCell(cell) &&
            !isChatCell(cell) &&
            isConversationStart(cell, exploration.view.cells),
          [styles.conversationActive]: isLastConversationReply,
        }),
      [
        canvasView,
        cell,
        exploration.view.cells,
        props.className,
        isLastConversationReply,
        selected,
      ],
    );

    return (
      <ErrorBoundary fallback={<GenericFallback />}>
        <ExplorationCellContextProvider cell={cell} isCollapsible={isCollapsible}>
          <DraggableCell
            index={index}
            isDraggable={!isConversationCell(cell) && isDraggable}
            selected={selected}
            height={props.height}
            isResizable={!isChatCell(cell) && cell.kind !== 'text' && isResizable}
            style={props.style}
            heightOverride={rowHeightOverride}
            setRowHeightOverride={setRowHeightOverride}>
            <div className={classList} onMouseDown={onMouseDown} ref={ref}>
              {isRecordsCell(cell) ? (
                <RecordsCellView
                  cell={cell}
                  exploration={exploration}
                  parameters={parameters}
                  onSelectCell={onSelectCell}
                  onSetDraggable={(value) => setIsDraggable(value)}
                  height={props.height ?? rowHeightOverride}
                />
              ) : cell.kind === 'funnel' ? (
                <FunnelCellView
                  cell={cell}
                  exploration={exploration}
                  onSelectCell={onSelectCell}
                  onSetDraggable={(value) => setIsDraggable(value)}
                  height={props.height}
                />
              ) : cell.kind === 'variable' ? (
                <DisableInEmbedded>
                  <VariableCellView
                    cell={cell}
                    variables={variables}
                    onSelectCell={onSelectCell}
                    onSetDraggable={(value) => setIsDraggable(value)}
                  />
                </DisableInEmbedded>
              ) : isSqlCell(cell) ? (
                <SqlCellView
                  cell={cell}
                  onSelectCell={onSelectCell}
                  onSetDraggable={(value) => setIsDraggable(value)}
                />
              ) : cell.kind === 'text' ? (
                <TextCellView
                  cell={cell}
                  selected={selected}
                  onSelectCell={onSelectCell}
                  onSetDraggable={(value) => setIsDraggable(value)}
                />
              ) : cell.kind === 'python' ? (
                <PythonCellView
                  cell={cell}
                  onSelectCell={onSelectCell}
                  onSetDraggable={(value) => setIsDraggable(value)}
                />
              ) : cell.kind === 'cohort' ? (
                <CohortCellView
                  cell={cell}
                  exploration={exploration}
                  onSelectCell={onSelectCell}
                  onSetDraggable={(value) => setIsDraggable(value)}
                />
              ) : isChatCell(cell) ? (
                <ChatCellView cell={cell} exploration={exploration} />
              ) : cell.kind === 'invalid' ? (
                <InvalidCellView
                  cell={cell}
                  exploration={exploration}
                  onSelectCell={onSelectCell}
                  onSetDraggable={(value) => setIsDraggable(value)}
                />
              ) : null}
            </div>
          </DraggableCell>
        </ExplorationCellContextProvider>
      </ErrorBoundary>
    );
  }),
);
