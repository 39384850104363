import { Fragment, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { first } from 'lodash';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';

import { useStickyElement } from '@/lib/hooks/use-sticky-element';
import { unlessNil } from '@/lib/utils';
import { NoMatchBanner } from '@/components/banner';
import { TableSkeleton } from '@/components/skeleton-loader';
import { Tooltip } from '@/components/tooltip';

import { DataTableRow, DataTableProperty } from './types';
import { CellValue, getCellTitle } from './cell';
import { isNumberType } from '../../utils';
import { SortButton, isSortEnabledForColumn } from './sort-button';
import { Sort, SortItem } from '../../types';
import { useCellContextMenu, useColumnContextMenu } from './context-menu';

export * from './types';

import tableStyle from '@/components/table/table.module.scss';

const MinDefaultColumnWidth = 100;
const MinColumnWidth = 50;
const MaxDefaultColumnWidth = 350;
const ColumnPadding = parseInt(tableStyle.columnHorizontalPadding, 10);

interface DataTableProps {
  records: DataTableRow[];
  properties: DataTableProperty[];
  sort: Sort;
  setSort: (sort: SortItem | null) => void;
  setRef: (ref: HTMLElement | null) => void;
  canEditPipeline?: boolean;
  loading?: boolean;
}

const columnHelper = createColumnHelper<DataTableRow>();

const getRecordFromRow = (row: Row<DataTableRow>): Record<string, unknown> => {
  const rows = [row, ...row.getParentRows()];
  return rows
    .flatMap((row) =>
      Object.keys(row.original)
        .filter((key) => !key.startsWith('$'))
        .map((key) => ({ [key]: row.original[key] })),
    )
    .reduce((acc, val) => ({ ...acc, ...val }), {});
};

function useColumnDefaultSize(): (property: DataTableProperty) => number {
  const textWidthCanvasContext = useMemo(() => {
    const canvas = document.createElement('canvas');
    const context: (CanvasRenderingContext2D & { letterSpacing?: string }) | null =
      canvas.getContext('2d');
    return context;
  }, []);

  const getTextWidth = useCallback(
    (text: string) => {
      if (textWidthCanvasContext === null) {
        return 0;
      }
      const {
        columnHeaderFontSize,
        columnHeaderFontWeight,
        columnHeaderLetterSpacing,
        fontFamily,
      } = tableStyle;
      textWidthCanvasContext.font = `${columnHeaderFontWeight} ${columnHeaderFontSize} ${fontFamily}`;
      textWidthCanvasContext.letterSpacing = columnHeaderLetterSpacing;

      return textWidthCanvasContext.measureText(text.toUpperCase()).width;
    },
    [textWidthCanvasContext],
  );

  return useCallback(
    (property: DataTableProperty) => {
      const computedTextWidth = getTextWidth(property.name);
      const desiredWidth = Math.ceil(computedTextWidth) + ColumnPadding * 2;
      const val = Math.min(Math.max(desiredWidth, MinDefaultColumnWidth), MaxDefaultColumnWidth);
      return val;
    },
    [getTextWidth],
  );
}

export const DataTable = (props: DataTableProps) => {
  'use no memo'; // React 19 breaks column resizing https://github.com/TanStack/table/issues/5567
  const {
    properties,
    records,
    sort,
    setSort,
    setRef,
    canEditPipeline = true,
    loading = false,
  } = props;

  const [tableElement, setTableElement] = useState<HTMLElement | null>(null);
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);

  const openCellContextMenu = useCellContextMenu();
  const openColumnContextMenu = useColumnContextMenu();

  const tableRef = useCallback(
    (ref: HTMLTableElement | null) => {
      setTableElement(ref);
      setRef(ref);
    },
    [setRef],
  );

  useStickyElement(headerElement, tableElement);

  const defaultNumberOfColumns = 7;

  const getColumnDefaultSize = useColumnDefaultSize();
  const columns = useMemo(
    () =>
      properties.map((property) =>
        columnHelper.accessor(property.key, {
          size: getColumnDefaultSize(property),
          minSize: MinColumnWidth,
          header: property.name,
          meta: { property },
          cell: (props) => (
            <CellValue
              value={props.row.original[property.key]}
              property={property}
              onClick={(event) => {
                openCellContextMenu({
                  position: {
                    x: event.clientX - 10,
                    y: event.clientY + 10,
                  },
                  row: getRecordFromRow(props.row),
                  property,
                  value: props.row.original[property.key],
                  canEditPipeline,
                });
              }}
            />
          ),
          enableResizing: true,
        }),
      ),
    [properties, canEditPipeline, openCellContextMenu, getColumnDefaultSize],
  );

  const table = useReactTable({
    columns,
    data: records,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    getSubRows: (row) => (row.$children as DataTableRow[]) ?? [],
    state: { sorting: sort.map(({ key, direction }) => ({ id: key, desc: direction === 'DESC' })) },
    sortDescFirst: true,
    onSortingChange: (sortUpdater) => {
      const oldSort = sort.map(({ key, direction }) => ({ id: key, desc: direction === 'DESC' }));
      const newSort = typeof sortUpdater === 'function' ? sortUpdater(oldSort) : sortUpdater;
      if (newSort.length === 0) {
        setSort(null);
      } else {
        setSort(
          newSort.map(({ id, desc }): Sort[0] => ({
            key: id,
            direction: desc ? 'DESC' : 'ASC',
          }))[0],
        );
      }
    },
  });

  const buildRowCells = (row: Row<DataTableRow>, sliceStart: number): React.ReactElement<any> => (
    <>
      {row
        .getVisibleCells()
        .slice(sliceStart, row.subRows.length > 0 ? sliceStart + 1 : undefined)
        .map((cell) => (
          <td
            key={cell.id}
            rowSpan={(row.original.$leaves as number) ?? 1}
            title={getCellTitle(cell)}
            className={classNames({
              [tableStyle.groupColumn]: row.original.$children !== undefined,
              [tableStyle.alignRight]: isNumberType(cell.column.columnDef.meta?.property.type),
            })}>
            <div className={tableStyle.content}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          </td>
        ))}
      {unlessNil(first(row.subRows), (subRow) => buildRowCells(subRow, sliceStart + 1))}
    </>
  );

  const buildRow = (row: Row<DataTableRow>, rowIdx = 0, depth = 0): React.ReactElement<any> => (
    <Fragment key={row.id}>
      {(depth === 0 || rowIdx > 0) && (
        <tr className={classNames({ [tableStyle.groupRow]: row.subRows.length > 0 })}>
          {buildRowCells(row, depth)}
        </tr>
      )}
      {row.subRows.map((subRow, idx) => buildRow(subRow, idx, depth + 1))}
    </Fragment>
  );

  const handleHeaderClick = (event: React.MouseEvent, property: DataTableProperty | undefined) => {
    if (property === undefined) {
      return;
    }

    openColumnContextMenu({
      position: { x: event.clientX - 10, y: event.clientY + 10 },
      property,
      canEditPipeline: canEditPipeline,
    });
  };

  if (!loading && records.length === 0) {
    return <NoMatchBanner />;
  }

  const headerGroups = table.getHeaderGroups();
  const headersLength = headerGroups.at(0)?.headers.length ?? 0;

  const rows = loading ? (
    <TableSkeleton
      rows={10}
      cols={headersLength > 0 ? headersLength : defaultNumberOfColumns}
      type={'table'}
    />
  ) : (
    <tbody>{table.getRowModel().rows.map((row) => buildRow(row))}</tbody>
  );

  const loadingClass = loading ? tableStyle.loading : '';

  return (
    <table
      className={classNames(tableStyle.table, tableStyle.dataTable, loadingClass)}
      ref={tableRef}>
      {rows}
      {/* thead after tbody fixes Safari z-index bug */}
      <thead
        ref={setHeaderElement}
        className={classNames({
          [tableStyle.resizing]: table.getState().columnSizingInfo.isResizingColumn !== false,
        })}>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                colSpan={header.colSpan}
                style={{ width: header.getSize() }}
                key={header.id}
                className={classNames({
                  [tableStyle.alignRight]: isNumberType(
                    header.column.columnDef.meta?.property.type,
                  ),
                  [tableStyle.resizing]: header.column.getIsResizing(),
                  [tableStyle.sorted]: header.column.getIsSorted() !== false,
                })}>
                <div className={tableStyle.content}>
                  <div className={tableStyle.defaultLabel} onClick={(e) => e.stopPropagation()}>
                    <Tooltip
                      delay={150}
                      content={
                        <dl>
                          <dt>{header.column.columnDef.meta?.property.name}</dt>
                          <dd>{header.column.columnDef.meta?.property.description}</dd>
                        </dl>
                      }>
                      <button
                        className={tableStyle.label}
                        disabled={!canEditPipeline}
                        onClick={(event) => {
                          handleHeaderClick(event, header.column.columnDef.meta?.property);
                        }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </button>
                    </Tooltip>
                    {header.column.getIsSorted() !== false && (
                      <SortButton
                        sortDirection={header.column.getIsSorted()}
                        onClick={header.column.getToggleSortingHandler()}
                      />
                    )}
                  </div>
                  <div
                    className={tableStyle.hoverLabel}
                    onClick={(e) => e.stopPropagation()}
                    aria-hidden>
                    <Tooltip
                      delay={150}
                      content={
                        <dl>
                          <dt>{header.column.columnDef.meta?.property.name}</dt>
                          <dd>{header.column.columnDef.meta?.property.description}</dd>
                        </dl>
                      }>
                      <button
                        className={tableStyle.label}
                        disabled={!canEditPipeline}
                        onClick={(event) => {
                          handleHeaderClick(event, header.column.columnDef.meta?.property);
                        }}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </button>
                    </Tooltip>
                    {isSortEnabledForColumn({
                      type: header.column.columnDef.meta?.property.type ?? '',
                    }) && (
                      <SortButton
                        sortDirection={header.column.getIsSorted()}
                        onClick={header.column.getToggleSortingHandler()}
                      />
                    )}
                  </div>
                </div>
                {header.column.getCanResize() && (
                  <div
                    className={tableStyle.resizer}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    onDoubleClick={() => header.column.resetSize()}
                  />
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
    </table>
  );
};
