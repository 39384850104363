import { findLastIndex } from 'lodash';

import { Cell, ChatCell, ChatMessage } from '@/explore/types';
import { generateUUID } from '@/lib/utils';

import { generateCellId, belongsToConversation } from '.';

export const isChatCell = (cell: Cell): cell is ChatCell => cell.kind === 'chat';

export const buildChatCell = (searchQuery: string): ChatCell => {
  return {
    id: generateCellId(),
    kind: 'chat',
    conversationId: generateUUID(),
    messages: [
      {
        id: generateUUID(),
        role: 'user',
        type: 'initial_user_prompt',
        message: searchQuery,
      },
    ],
  };
};

export const buildEmptyChatCell = (conversationId: string): ChatCell => {
  return {
    id: generateCellId(),
    kind: 'chat',
    conversationId,
    messages: [],
  };
};

export const updateChatCellMessage = (cell: ChatCell, updatedMessage: ChatMessage): ChatCell => {
  return {
    ...cell,
    messages: cell.messages.map((message) =>
      message.id === updatedMessage.id ? updatedMessage : message,
    ),
  };
};

export const getLastUserMessageIndex = (messages: ChatCell['messages']) =>
  findLastIndex(messages, (msg) => msg.role === 'user');

export const getLastInputCellIndex = (
  cells: Cell[],
  conversationId: string,
  cellsToIgnore: string[] = [],
) => {
  return findLastIndex(
    cells,
    (cell) =>
      !cellsToIgnore.includes(cell.id) &&
      belongsToConversation(cell, conversationId) &&
      isChatCell(cell) &&
      cell.messages.some((message) => message.role === 'user'),
  );
};

export const removeLastChatInput = (cells: Cell[], conversationId: string, cell: ChatCell) => {
  const lastInputCellIndex = getLastInputCellIndex(
    cells,
    conversationId,
    cell.messages.length < 2 ? [cell.id] : [],
  );

  return cells
    .map((c, index) => {
      if (index === lastInputCellIndex && isChatCell(c)) {
        const cellMessages = cell.id === c.id ? cell.messages : c.messages;
        const lastMessageIndex = getLastUserMessageIndex(cellMessages);
        return {
          ...c,
          messages: cellMessages.slice(0, lastMessageIndex === 0 ? 1 : lastMessageIndex),
        };
      }

      return c;
    })
    .filter((c, index) => {
      if (!belongsToConversation(c, cell.conversationId)) {
        return true;
      }

      return index <= lastInputCellIndex;
    });
};
