import { getExplorationType } from '@/core/exploration';

import { SidebarSection } from '../../sidebar-section';
import { ExplorationSettings } from '../../settings';
import { ExplorationOutline } from '../../exploration-outline';
import { useExplorationContext } from '../../exploration-context';

interface ExplorationSidebarProps {
  onAddSection: () => void;
}

export const ExplorationSidebar = (props: ExplorationSidebarProps) => {
  const { onAddSection } = props;

  const { exploration, openEditor, scrollToCell, moveCell } = useExplorationContext();

  return (
    <>
      <SidebarSection title="Settings">
        <ExplorationSettings
          exploration={exploration}
          updateDisabled={getExplorationType(exploration) !== 'exploration'}
        />
      </SidebarSection>
      <SidebarSection title="Table of Contents" scrollable>
        <ExplorationOutline
          exploration={exploration}
          onClickCell={(cellId) => {
            openEditor(cellId);
            scrollToCell(cellId);
          }}
          onClickAddSection={onAddSection}
          onReorder={moveCell}
        />
      </SidebarSection>
    </>
  );
};
