import { first, isBoolean, isNumber } from 'lodash';
import { common } from '@gosupersimple/types';
import { format } from 'date-fns';

import { pluralize } from '@/lib/utils/string';

import {
  DateFriendlyFormat,
  DateFriendlyTimeFormat,
  getHumanReadableRange,
  includesTime,
  isDateRange,
} from '@/lib/date';

import {
  AddRelatedColumnOperation,
  CompositeFilterCondition,
  DeriveFieldOperation,
  Fields,
  FilterCondition,
  FilterOperation,
  GroupAggregateOperation,
  Model,
  PipelineOperation,
  RelationAggregateOperation,
  SqlOperation,
  SwitchToRelationOperation,
} from '../types';
import { isSingleFilterCondition, isValueExpression } from '../pipeline/operation';
import { precisionToTimeInterval } from '../pipeline/state';
import { formatMetricLabel } from '../utils/metrics';
import { getModelRelation } from '../model/utils';
import { getTimeAggregationPeriodLabel } from './format';
import { humanizeExpression } from '../utils/penguino';
import { Token } from '../components/pipeline/operation-description';

export const findFieldName = (fields: Fields, key: string) =>
  fields.find((f) => f.key === key)?.name ?? key;

export const getOperationIcon = (operation: PipelineOperation) => {
  switch (operation.operation) {
    case 'invalid':
      return 'AlertTriangle';
    case 'filter':
      return 'Filter';
    case 'switchToRelation':
      return 'RelatedData';
    case 'relationAggregate':
    case 'addRelatedColumn':
    case 'deriveField':
      return 'AddColumn';
    case 'groupAggregate':
      return 'Summarise';
    case 'sql':
      return 'Database';
    case 'cohort':
      return 'Cohort';
    case 'funnel':
      return 'Funnel';
    default:
      return 'Filter';
  }
};

export const getOperationTitle = (
  operation: PipelineOperation,
  { relations }: { relations: { key: string; name: string }[] },
) => {
  switch (operation.operation) {
    case 'invalid':
      return 'Invalid operation';
    case 'filter':
      return 'Filter';
    case 'relationAggregate': {
      const relationKey =
        'relation' in operation.parameters ? operation.parameters.relation.key : '';

      const relatedModel = relations.find((r) => r.key === relationKey)?.name ?? relationKey;

      return `Summarize from ${relatedModel}`;
    }
    case 'deriveField':
      return 'Custom Formula';
    case 'addRelatedColumn': {
      const relationKey = operation.parameters.relation.key;

      const relatedModel = relations.find((r) => r.key === relationKey)?.name ?? relationKey;

      return `Column${operation.parameters.columns.length > 1 ? 's' : ''} from ${relatedModel}`;
    }
    case 'groupAggregate':
      return 'Summarize';
    case 'sql':
      return 'SQL Query';
    case 'switchToRelation':
      return 'Jump to related data';
    default:
      return '';
  }
};

export const getOperationOverview = (
  operation: PipelineOperation,
  { fields, model, variables }: { fields: Fields; model: Model; variables: common.QueryVariables },
): Token => {
  switch (operation.operation) {
    case 'invalid':
      return operation.parameters.message ?? 'Invalid operation';
    case 'filter':
      return getFilterOperationOverview(operation, fields);
    case 'deriveField':
      return getDeriveFieldOperationOverview(operation, fields);
    case 'groupAggregate':
      return getGroupAggregateOperationOverview(operation, fields, variables);
    case 'relationAggregate':
      return getRelationAggregateOperationOverview(operation, fields);
    case 'addRelatedColumn':
      return getAddRelatedColumnOperationOverview(operation);
    case 'sql':
      return getSqlOperationOverview(operation);
    case 'switchToRelation':
      return getSwitchToRelationOperationOverview(operation, model);
    case 'cohort':
      return 'Retention chart';
    case 'funnel':
      return 'Funnel';
    default:
      return '';
  }
};

const getFilterOperationOverview = (operation: FilterOperation, fields: Fields) =>
  getCompositeFilterConditionLabel(operation.parameters, fields);

const getCompositeFilterConditionLabel = (
  condition: CompositeFilterCondition,
  fields: Fields,
): Token => {
  if (isSingleFilterCondition(condition)) {
    return getFilterConditionLabel(condition, fields);
  }

  return condition.operands
    .map((operand) => getCompositeFilterConditionLabel(operand, fields))
    .flatMap((value, index, array) =>
      array.length - 1 !== index ? [value, condition.operator.toUpperCase()] : [value],
    );
};

const getFilterConditionLabel = (condition: FilterCondition, fields: Fields): Token => {
  const { key, operator } = condition;

  if (filterIsUnaryOperation(condition)) {
    return [findFieldName(fields, key), getOperatorLabel(operator)];
  }

  const fieldType = fields.find((f) => f.key === key)?.type;
  const fieldName = findFieldName(fields, key);
  const operatorLabel = getOperatorLabel(operator);

  if (isNumber(condition.value)) {
    return [fieldName, operatorLabel, { value: condition.value, kind: 'value' }];
  }
  if (isValueExpression(condition.value)) {
    return [
      fieldName,
      operatorLabel,
      { value: humanizeExpression(condition.value.expression, fields), kind: 'expression' },
    ];
  }
  if (Array.isArray(condition.value)) {
    return [fieldName, operatorLabel, { value: condition.value.join(', '), kind: 'value' }];
  }
  if (isDateRange(condition.value)) {
    return [
      fieldName,
      operatorLabel,
      { value: getHumanReadableRange(condition.value), kind: 'date' },
    ];
  }
  if (fieldType === 'Date' && condition.value !== undefined && !isBoolean(condition.value)) {
    return [
      fieldName,
      operatorLabel,
      {
        value: format(
          condition.value,
          includesTime(condition.value) ? DateFriendlyTimeFormat : DateFriendlyFormat,
        ),
        kind: 'date',
      },
    ];
  }

  return [
    fieldName,
    operatorLabel,
    { value: condition.value?.toLocaleString() ?? '', kind: 'value' },
  ];
};

const filterIsUnaryOperation = ({ operator }: FilterCondition) =>
  operator === 'isnull' || operator === 'isnotnull';

const OPERATOR_LABELS: { [key: string]: string } = {
  noticontains: 'does not contain text',
  icontains: 'contains text',
  arrcontains: 'array contains',
  isnull: 'has no value',
  isnotnull: 'has a value',
  in: 'is one of',
  notin: 'is not one of',
  '==': 'is',
  '!=': 'is not',
  '>': '>',
  '<': '<',
  '>=': '≥',
  '<=': '≤',
};

const getOperatorLabel = (operator: string) => OPERATOR_LABELS[operator] || operator;

const getSliceDescription = (slice: common.Slice, fields: Fields) => {
  const { limit = 0, offset = 0, sort = [] } = slice;
  const sortFieldName = findFieldName(fields, first(sort)?.key ?? '');
  const isAsc = first(sort)?.direction === 'ASC';
  return `first ${limit} ${pluralize(limit, 'row', 'rows')} ${offset > 0 ? `skipping ${offset}` : ''}${` sorted by ${sortFieldName} ${isAsc ? 'Asc' : 'Desc'}`}`;
};

const getDeriveFieldOperationOverview = (operation: DeriveFieldOperation, fields: Fields) => ({
  value: humanizeExpression(operation.parameters.value.expression, fields),
  kind: 'expression',
  label: operation.parameters.fieldName,
});

const getGroupAggregateOperationOverview = (
  operation: GroupAggregateOperation,
  fields: Fields,
  variables: common.QueryVariables,
) => {
  const parts = [];
  const slice = operation.parameters.slice;

  const hasAggregations = operation.parameters.aggregations.length > 0;
  const hasGrouping = operation.parameters.groups.length > 0;
  const hasSlicing = slice !== undefined;

  if (hasAggregations) {
    parts.push(
      operation.parameters.aggregations
        .map((aggregation) =>
          aggregation.type === 'metric'
            ? formatMetricLabel(aggregation.property.name)
            : aggregation.property.name,
        )
        .join(', '),
    );
  }

  if (hasGrouping) {
    parts.push(hasAggregations ? 'per' : 'Group by');
    parts.push(
      operation.parameters.groups
        .map(({ key, precision }) => {
          const interval = precisionToTimeInterval(precision, variables);
          return `${findFieldName(fields, key)}${
            interval !== undefined ? ` by ${getTimeAggregationPeriodLabel(interval)}` : ''
          }`;
        })
        .join(', '),
    );
  }

  if (hasSlicing) {
    const description = getSliceDescription(slice, fields);
    parts.push(hasAggregations || hasGrouping ? `(${description})` : `Take ${description}`);
  }

  return parts;
};

const getRelationAggregateOperationOverview = (
  operation: RelationAggregateOperation,
  fields: Fields,
) => {
  const { aggregations, slice } = operation.parameters;
  const hasSlicing = slice !== undefined;

  const aggregationsDescription = aggregations
    .map((aggregation) => aggregation.property.name)
    .join(', ');

  return hasSlicing
    ? `${aggregationsDescription} (${getSliceDescription(slice, fields)})`
    : aggregationsDescription;
};

const getAddRelatedColumnOperationOverview = (operation: AddRelatedColumnOperation) =>
  operation.parameters.columns.map((column) => column.property.name).join(', ');

const getSqlOperationOverview = (operation: SqlOperation) => ({
  kind: 'expression',
  value: operation.parameters.sql.replaceAll('\n', '').slice(0, 50),
});

const getSwitchToRelationOperationOverview = (operation: SwitchToRelationOperation, model: Model) =>
  formatShortRelationLabel({
    relationName:
      getModelRelation(operation.parameters.relation.key, model)?.name ??
      operation.parameters.relation.key,
    baseModelName: model.name,
  });

const formatShortRelationLabel = ({
  relationName,
  baseModelName,
}: {
  relationName: string;
  baseModelName: string;
}) => `${relationName} on ${baseModelName}`;
