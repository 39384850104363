import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { generateUUID } from '@/lib/utils';

import { Footnote } from '../../components/footnote';

export const FootnoteNode = Node.create({
  name: 'footnoteNode',
  type: 'footnoteNode',
  group: 'inline',
  inline: true,
  inclusive: false,
  atom: true,
  selectable: false,
  draggable: true,

  parseHTML() {
    return [{ tag: 'footnote-node' }];
  },
  renderText({ node }) {
    return `<footnote-node href="${node.attrs.href}" label="${node.attrs.label}"></footnote-node>`;
  },
  renderHTML({ HTMLAttributes }) {
    return ['footnote-node', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(Footnote);
  },
  HTMLAttributes: {
    target: 'null',
  },

  addAttributes() {
    return {
      href: {
        default: undefined,
      },
      label: {
        default: undefined,
      },
      citeId: {
        default: undefined,
        parseHTML: () => generateUUID(),
      },
    };
  },
});
