import { useEffect, useState } from 'react';

/**
 * A hook that detects hovering of an element using screen coordinates instead of events
 * so it works on "pass-through" elements that have pointer-events: none.
 */
export const useHover = (ref: React.RefObject<HTMLElement | null>) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const rect = ref.current?.getBoundingClientRect();
      if (rect === undefined || (rect.width === 0 && rect.height === 0)) {
        return;
      }
      setIsHovered(
        event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom,
      );
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [ref]);

  return isHovered;
};
