import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon, IconProps } from '@/components/icon';
import { pipelineColor } from '@/explore/pipeline/format';

import styles from './pipeline-preview.module.scss';

interface SavedPipelineHeaderProps {
  icon?: IconProps['name'];
  title: string;
  childCount?: number;
  color?: number;
  onClick?: MouseEventHandler;
}

const ParentPipelineColors = [
  styles.parentPipelineColor1,
  styles.parentPipelineColor2,
  styles.parentPipelineColor3,
  styles.parentPipelineColor4,
  styles.parentPipelineColor5,
  styles.parentPipelineColor6,
];

export const SavedPipelineHeader = ({
  icon = 'Model',
  title,
  childCount,
  color,
  onClick,
}: SavedPipelineHeaderProps) => (
  <div
    className={classNames(
      styles.savedPipelineHeader,
      { [styles.clickable]: onClick !== undefined },
      pipelineColor(color, ParentPipelineColors),
    )}
    onClick={onClick}>
    <div className={styles.operationIcon}>
      <Icon name={icon} size={16} />
    </div>
    <div className={styles.operationTitle} title={title}>
      &quot;{title}&quot;
    </div>
    {childCount !== undefined && childCount > 0 && (
      <div className={styles.childrenCount} title={`Prent pipeline used ${childCount} instances`}>
        ({childCount})
      </div>
    )}
  </div>
);
