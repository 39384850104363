import { useState } from 'react';

import { Field, Fields } from '@/explore/types';
import { FieldGroup, isFieldGroup } from '@/explore/pipeline/utils';

export const createMissingFields = (fields: Fields, keys: string[]) => {
  return keys
    .filter((key) => !fields.some((field) => field.key === key))
    .map((key) => {
      let name = key;
      try {
        // TODO: figure out how to avoid json-key hack for when more info than field key is needed
        const parsed = JSON.parse(key);
        name = `${parsed.fieldKey ?? key}${'modelId' in parsed ? ` on model ${parsed.modelId}${'relationKey' in parsed ? `on relation ${parsed.relationKey}` : ''}` : ''}`;
      } catch {
        // Ignore
      }
      return {
        key,
        name: `❗ Field not found: ${name}`,
        type: 'Number' as const,
      };
    });
};

const fieldExists = (key: string, fields: (Field | FieldGroup)[]): boolean =>
  fields.some(
    (field) => (isFieldGroup(field) && fieldExists(key, field.fields)) || field.key === key,
  );

/**
 * A hook to 'pad' the fields array with any missing fields and to explicitly
 * store them in react state so the missing fields stay present until the component
 * is destroyed to prevent fields disappearing immediately when they are made unnecessary.
 */
export const useEnsureFieldsExist = (fields: Fields, keys: string[]) => {
  const [missingFields] = useState(createMissingFields(fields, keys));
  return [...missingFields.filter(({ key }) => !fieldExists(key, fields)), ...fields];
};

export const useEnsureFieldsExistGrouped = (fields: (Field | FieldGroup)[], keys: string[]) => {
  const flattenedFields = fields.flatMap((field) => (isFieldGroup(field) ? field.fields : [field]));
  const [missingFields] = useState(createMissingFields(flattenedFields, keys));
  return [...missingFields.filter(({ key }) => !fieldExists(key, fields)), ...fields];
};
