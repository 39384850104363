import { useEffect } from 'react';
import classNames from 'classnames';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Heading from '@tiptap/extension-heading';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import HardBreak from '@tiptap/extension-hard-break';
import Horizontalrule from '@tiptap/extension-horizontal-rule';
import Blockquote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Typography from '@tiptap/extension-typography';
import OrderedList from '@tiptap/extension-ordered-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Image from '@tiptap/extension-image';
import FileHandler from '@tiptap-pro/extension-file-handler';
import Dropcursor from '@tiptap/extension-dropcursor';
import History from '@tiptap/extension-history';
import Placeholder from '@tiptap/extension-placeholder';
import Gapcursor from '@tiptap/extension-gapcursor';
import { useEditor, EditorContent } from '@tiptap/react';
import { marked } from 'marked';

import { useAuth } from '@/auth/auth-context';
import { TextCell } from '@/explore/types';
import { Icon } from '@/components/icon';
import { useLayoutContext } from '@/components/layout/layout-context';
import { useAccountContext } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';
import { notNil } from '@/lib/utils';

import { useExplorationCellContext } from '../exploration-cell-context';
import { useExplorationContext } from '../exploration-context';
import { CellControls } from '../cell-controls';
import UploadingImage from './uploading-image';
import { PopupMenu } from './popup-menu';
import { ReferenceNode } from './extensions/nodes/reference-node';
import { FootnoteListNode } from './extensions/nodes/footnote-list-node';
import { FootnoteNode } from './extensions/nodes/footnote-node';
import { Video } from './extensions/nodes/video-node';
import { CustomKeyboardShortcuts } from './extensions/shortcuts';
import { EnhancedLink } from './extensions/link';

import styles from './text-cell.module.scss';
import explorationStyles from '../exploration.module.scss';

interface TextCellViewProps {
  cell: TextCell;
  selected: boolean;
  onSelectCell?: () => void;
  onSetDraggable: (value: boolean) => void;
}

const extensions = [
  Document,
  Paragraph,
  Text,
  Image,
  Heading.configure({ levels: [1, 2, 3] }),
  Bold,
  Italic,
  Underline,
  HardBreak,
  EnhancedLink.configure({
    openOnClick: false,
    protocols: ['http', 'https'],
    defaultProtocol: 'https',
  }),
  Horizontalrule,
  Blockquote,
  BulletList,
  OrderedList,
  ListItem,
  Typography,
  Code,
  CodeBlock,
  Video,
  FileHandler.configure({
    allowedMimeTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/heic'],
    onDrop: (editor, files, pos) => files.forEach((file) => editor.commands.uploadFile(pos, file)),
    onPaste: (editor, files, htmlContent) => {
      files.forEach((file) => {
        if (htmlContent !== undefined && htmlContent !== null && htmlContent.length > 0) {
          // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
          // you could extract the pasted file from this url string and upload it to a server for example
          return false;
        }

        editor.commands.uploadFile(editor.state.selection.anchor, file);
      });
    },
  }),
  Dropcursor,
  Placeholder.configure({ placeholder: 'Text...' }),
  Gapcursor,
  History,
  FootnoteNode,
  FootnoteListNode,
  ReferenceNode,
  CustomKeyboardShortcuts,
];

const convertContent = (cell: TextCell) => {
  const title = cell.title ?? '';
  const content = cell.content ?? '';
  return marked.parseInline(
    `${(title.length ?? 0) > 0 ? '<h1>${cell.title}</h1>' : ''}${content}`,
    { breaks: true },
  );
};

export const TextCellView = (props: TextCellViewProps) => {
  const { cell } = props;

  const { getToken } = useAuth();
  const { account } = useAccountContext();
  const { exploration } = useExplorationContext();
  const { setCell, isSelectedCell, isConversationCell } = useExplorationCellContext();
  const { isEmbeddedMode } = useLayoutContext();
  const trackEvent = useTrackEvent();

  const editor = useEditor({
    extensions: extensions.concat(
      UploadingImage.configure({
        accountId: account.accountId,
        getToken,
        onUploadSuccess: (fileId) =>
          trackEvent('Image Uploaded', {
            fileId,
            explorationId: exploration.explorationId,
            cellId: cell.id,
          }),
        onUploadError: (fileId) =>
          trackEvent('Image Upload Failed', {
            fileId,
            explorationId: exploration.explorationId,
            cellId: cell.id,
          }),
      }),
    ),
    content: convertContent(cell),
    editable: !isEmbeddedMode,
  });

  useEffect(() => {
    editor?.commands.setTextSelection(0);
  }, [editor]);

  useEffect(() => {
    const editorContent = editor?.getHTML();

    if (editor !== null && cell.content !== editorContent) {
      editor.commands.setContent(convertContent(cell));
    }
  }, [cell, editor]);

  useEffect(() => {
    if (isSelectedCell && !(editor?.view.hasFocus() ?? false)) {
      editor?.view.focus();
    }
    if (!isSelectedCell && (editor?.view.hasFocus() ?? false)) {
      editor?.commands.blur();
    }
  }, [isSelectedCell, editor]);

  const handleEditorBlur = () => {
    if (editor === null) {
      return;
    }

    const content = editor.getHTML();

    if (cell.content !== content) {
      setCell({ ...cell, content, title: undefined });
    }
  };

  return (
    <div className={styles.textCell}>
      {!isConversationCell && (
        <Icon
          name="DragHandle"
          size={10}
          className={classNames(explorationStyles.dragHandle, styles.dragHandle)}
          onMouseOver={() => props.onSetDraggable(true)}
          onMouseOut={() => props.onSetDraggable(false)}
        />
      )}

      {/*
        div here is necessary for PopupMenu keyboard navigation support
        Read more: https://atomiks.github.io/tippyjs/v6/accessibility/#interactivity
      */}
      <div>
        <EditorContent
          editor={editor}
          onBlur={handleEditorBlur}
          onClick={(e) => {
            const linkElement = (e.target as HTMLElement).closest('a');

            if (!linkElement) {
              props.onSelectCell?.();
              return;
            }

            const href = linkElement?.getAttribute('href');

            if (!isSelectedCell && notNil(href)) {
              window.open(href, '_blank', 'noopener noreferrer');
              return;
            }

            if (!e.metaKey && !e.ctrlKey) {
              editor?.chain().extendMarkRange('link').run();
            }
          }}
        />
        <PopupMenu editor={editor} />
      </div>

      <div className={styles.controls}>
        <CellControls exploration={exploration} />
      </div>
    </div>
  );
};
