import { formatDistance, isValid, parseISO } from 'date-fns';

import { Icon } from '@/components/icon';

import styles from './reference-block.module.scss';

export interface LinearReferenceProps {
  source: 'linear';
  url: string;
  content: string;
  date: string;
}

export const LinearReference = ({ content, date }: LinearReferenceProps) => {
  const relativeTime = isValid(parseISO(date))
    ? formatDistance(new Date(date), new Date(), { addSuffix: true })
    : '';

  return (
    <div className={styles.layout}>
      <div className={styles.icon}>
        <Icon name="LinearIcon" size={32} />
      </div>
      <div className={styles.title}>{content}</div>
      <div className={styles.time}>{relativeTime}</div>
    </div>
  );
};
