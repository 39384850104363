import { isRecordsLikeCell } from '@/core/cell';
import { getCellByPipelineId } from '@/explore/exploration/utils';
import { getFinalState, PipelineStateContext } from '@/explore/pipeline/state';
import { dereferencePipeline } from '@/explore/pipeline/utils';
import { Exploration } from '@/explore/types';

export const getDynamicVariableDefinitionDefaults = (
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const pipelineId = getCellOptions(exploration).at(0)?.value ?? '';
  const field = getFieldOptions(pipelineId, exploration, ctx).at(0)?.value ?? '';
  return { pipelineId, field };
};

export const getCellOptions = (exploration: Exploration) =>
  exploration.view.cells.filter(isRecordsLikeCell).map((cell) => ({
    value: cell.pipeline.pipelineId,
    label: cell.title ?? '',
  }));

export const getFieldOptions = (
  pipelineId: string,
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const cell = getCellByPipelineId(pipelineId, exploration);
  if (cell === undefined) {
    return [];
  }

  const { baseModelId, operations } = dereferencePipeline(cell.pipeline, exploration);
  return getFinalState(baseModelId, operations, ctx)
    .fields.filter(({ type }) => type !== 'Boolean' && type !== 'Date')
    .map((field) => ({
      value: field.key,
      label: field.name,
    }));
};
