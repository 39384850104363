import classnames from 'classnames';
import { useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import { ButtonGroup, IconButton } from '@/components/button';
import { useLayoutContext } from '@/components/layout/layout-context';
import { Tooltip } from '@/components/tooltip';
import { buildExplorationUrl } from '@/explore/utils/url';
import { useBuildAccountUrl } from '@/lib/accounts/context';
import { useTrackEvent } from '@/lib/analytics';

import { getExplorationLayout, getExplorationType } from '@/core/exploration';

import { Icon } from '../../components/icon';
import { getExplorationIconName, isModelDetailExploration } from '../utils';
import { useExplorationContext } from './exploration-context';

import { ExplorationLayout } from '../types';

import styles from './exploration.module.scss';

interface ExplorationHeaderProps {
  handleLayoutChange: (layout: ExplorationLayout) => void;
}

export const ExplorationHeader = (props: ExplorationHeaderProps) => {
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const buildAccountUrl = useBuildAccountUrl();
  const [, copyToClipboard] = useCopyToClipboard();
  const trackEvent = useTrackEvent();

  const { isRightSidebarOpen, toggleRightSidebar } = useLayoutContext();

  const { exploration, parameters, resetExploration, isDirty } = useExplorationContext();

  const handleShare = async () => {
    const explorationUrl = buildAccountUrl(buildExplorationUrl(exploration, parameters));
    const url = new URL(explorationUrl, location.origin);
    await copyToClipboard(url.toString());

    setShareLinkCopied(true);
    trackEvent('Share Link Copied', { explorationId: exploration.explorationId });

    setTimeout(() => setShareLinkCopied(false), 3000);
  };

  const explorationType = getExplorationType(exploration);
  const explorationIconName = getExplorationIconName(exploration);
  const isDetailExploration = isModelDetailExploration(exploration);

  return (
    <header className={styles.header}>
      <div className={classnames(styles.explorationTitle, { [styles.dirtyTitle]: isDirty })}>
        {isDirty && <div className={styles.titleStatus}>Exploring from</div>}
        <h1>
          <Icon name={explorationIconName} size={24} />
          {explorationType === 'ai' ? (
            <span className="truncate">{exploration.name}</span>
          ) : (
            <button
              type="button"
              className={styles.resetButton}
              title="Reset exploration"
              onClick={() => resetExploration(parameters)}>
              {exploration.name}
            </button>
          )}
        </h1>
        {isDetailExploration && <div className={styles.templateTag}>Template</div>}
      </div>

      <div className={styles.headerTools}>
        <Tooltip content={shareLinkCopied ? 'Copied!' : 'Copy share link'}>
          <IconButton
            icon="Link"
            onClick={handleShare}
            onMouseLeave={() =>
              // Account for tooltip hide delay
              setTimeout(() => setShareLinkCopied(false), 500)
            }
          />
        </Tooltip>
        <Tooltip content="Toggle right sidebar">
          <IconButton
            icon="SidebarRight"
            onClick={(e) => {
              const newSidebarState = !isRightSidebarOpen;
              toggleRightSidebar(newSidebarState);
              trackEvent(newSidebarState ? 'Right Sidebar Opened' : 'Right Sidebar Closed');
              (e.currentTarget as HTMLButtonElement).blur();
            }}
          />
        </Tooltip>
        <hr className={styles.headerToolsSpacer} />
        <ButtonGroup
          value={getExplorationLayout(exploration)}
          size="small"
          options={[
            { value: 'notebook', label: 'Notebook' },
            { value: 'canvas', label: 'Canvas' },
          ]}
          onChange={(value) => props.handleLayoutChange(value === 'canvas' ? 'canvas' : 'notebook')}
        />
      </div>
    </header>
  );
};
