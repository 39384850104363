import { MouseEvent, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  FloatingFocusManager,
  FloatingList,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';

import { notNil } from '@/lib/utils';
import { useIsScrolled } from '@/lib/hooks/use-is-scrolled';
import {
  buildExplorationFromCells,
  hasExplorationSection,
  isModelExploration,
} from '@/core/exploration';
import { getModelSections } from '@/core/model';
import { isReplicableCell, ReplicableCell } from '@/core/cell';
import { useBuildAccountUrl } from '@/lib/accounts/context';

import { useMetadataContext } from '../metadata-context';
import { Icon } from '../../components/icon';
import { Modal } from '../../components/modal';
import { Panel } from '../../components/panel';
import { getUnparameterizedExplorations } from '../utils';
import { usePersistCell, CopiedCell } from '../utils/use-persist-cell';
import {
  buildExplorationHashUrl,
  buildExplorationUrl,
  getHashSearchTerm,
  setHashSearchTerm,
} from '../utils/url';
import { Exploration } from '../types';
import { PipelinePreview } from '../exploration/pipeline-preview';

import { explorationHasReplicableCells, extractCellFromExploration } from '../exploration/utils';
import { CollapsibleSection } from './collapsible-section';
import { CellList } from './cell-list';
import { ExplorationList } from './exploration-list';
import { SearchResults } from './search-results';

import styles from './exploration-search.module.scss';

export type ExplorationListNode = {
  title: string;
  children: ExplorationNode[];
};

export type CellListNode = {
  title: string;
  exploration: Exploration;
  children: CellNode[];
};

export type ExplorationNode = {
  exploration: Exploration;
  url: string;
  onClick: (event?: MouseEvent) => void;
  children?: CellNode[];
};

export type CellNode = {
  cell: ReplicableCell;
  url: string;
  onClick: (event?: MouseEvent) => void;
};

export type ListNode = ExplorationListNode | CellListNode;
export type ListItemNode = ExplorationNode | CellNode;

export const flattenLists = (lists: ({ children: ListItemNode[] } | null)[]): ListItemNode[] => {
  return lists
    .filter(notNil)
    .flatMap<ExplorationNode | CellNode>((list) => list.children)
    .flatMap((item) => ('exploration' in item ? [item, ...(item.children ?? [])] : item));
};

interface ExplorationSearchProps {
  exploration?: Exploration;
  copiedCell?: CopiedCell;
  selectIndividualCells?: boolean;
  className?: string;
  onClickExploration?: (exploration: Exploration) => void;
  onPasteExploration?: () => void;
}

export const ExplorationSearch = (props: ExplorationSearchProps) => {
  const { explorations, models, metrics } = useMetadataContext();
  const {
    exploration,
    className,
    selectIndividualCells = false,
    onPasteExploration,
    onClickExploration,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const buildAccountUrl = useBuildAccountUrl();

  const [searchTerm, setSearchTerm] = useState(getHashSearchTerm(window.location.hash) ?? '');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const onClickSearchResultRef = useRef<(() => void) | null>(null);
  const [isScrolled, scrollContainerRef] = useIsScrolled<HTMLDivElement>();
  const [collapsedLists, setCollapsedLists] = useState<Set<number>>(new Set());
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const toggleCollapsedList = (index: number) => {
    setCollapsedLists((collapsedLists) => {
      const set = new Set(collapsedLists);
      set.has(index) ? set.delete(index) : set.add(index);
      return set;
    });
  };

  const toggleItemExpand = (id: string) => {
    setExpandedItems((expandedItems) => {
      const set = new Set(expandedItems);
      set.has(id) ? set.delete(id) : set.add(id);
      return set;
    });
  };

  const {
    copiedCell,
    removeCopiedCell,
    copiedExploration,
    copiedExplorationPipeline,
    copiedExplorationTitle,
    copiedExplorationUrl,
  } = usePersistCell();

  const trimmedSearchTerm = searchTerm.trim();

  const savedExplorations = useMemo(
    () => explorations.filter((exploration) => !isModelExploration(exploration)),
    [explorations],
  );
  const modelExplorations = useMemo(() => explorations.filter(isModelExploration), [explorations]);
  const modelSections = useMemo(() => getModelSections(models), [models]);
  const modelsWithoutSections = useMemo(
    () => modelExplorations.filter((exploration) => !hasExplorationSection(exploration)),
    [modelExplorations],
  );
  const unparameterisedExplorations = useMemo(
    () => getUnparameterizedExplorations(savedExplorations),
    [savedExplorations],
  );

  const searchableExplorations = useMemo(
    () => unparameterisedExplorations.concat(modelExplorations),
    [modelExplorations, unparameterisedExplorations],
  );

  const handleExplorationClick = useMemo(
    () => (exploration: Exploration, url: string) => {
      if (onClickExploration) {
        onClickExploration(exploration);
      } else {
        navigate(url);
      }
    },
    [navigate, onClickExploration],
  );

  const lists: (ListNode | null)[] = useMemo(
    () => [
      exploration === undefined || !explorationHasReplicableCells(exploration)
        ? null
        : {
            title: 'On This Page',
            exploration: exploration,
            children: exploration?.view.cells.filter(isReplicableCell).map((cell) => {
              const { cell: extractedCell, dependencies } = extractCellFromExploration(
                cell.id,
                exploration,
              );
              const outputExploration = buildExplorationFromCells([extractedCell, ...dependencies]);
              const url = buildAccountUrl(buildExplorationHashUrl(outputExploration));
              return {
                cell,
                url,
                onClick: (event) => {
                  event?.preventDefault();
                  handleExplorationClick(outputExploration, url);
                },
              };
            }),
          },
      unparameterisedExplorations.length === 0
        ? null
        : {
            title: 'Saved Explorations',
            children: unparameterisedExplorations
              .filter(
                (exploration) =>
                  !selectIndividualCells ||
                  exploration.view.cells.filter(isReplicableCell).length > 0,
              )
              .map((exploration) => {
                const url = buildAccountUrl(buildExplorationUrl(exploration));
                return {
                  exploration,
                  url,
                  onClick: (event) => {
                    event?.preventDefault();
                    if (selectIndividualCells) {
                      toggleItemExpand(exploration.explorationId);
                      return;
                    }
                    if (onClickExploration) {
                      onClickExploration(exploration);
                    } else {
                      navigate(url);
                    }
                  },
                  children: selectIndividualCells
                    ? exploration.view.cells.filter(isReplicableCell).map((cell) => {
                        const { cell: extractedCell, dependencies } = extractCellFromExploration(
                          cell.id,
                          exploration,
                        );
                        const outputExploration = buildExplorationFromCells([
                          extractedCell,
                          ...dependencies,
                        ]);
                        const url = buildAccountUrl(buildExplorationHashUrl(outputExploration));
                        return {
                          cell,
                          url,
                          onClick: (event: MouseEvent) => {
                            event?.preventDefault();
                            handleExplorationClick(outputExploration, url);
                          },
                        };
                      })
                    : [],
                };
              }),
          },
      ...modelSections.map<ListNode>((section) => ({
        title: section,
        children: modelExplorations
          .filter(({ labels }) => labels.section === section)
          .map((exploration) => {
            const url = buildAccountUrl(buildExplorationUrl(exploration));
            return {
              exploration,
              url,
              onClick: (event) => {
                event?.preventDefault();
                handleExplorationClick(exploration, url);
              },
            };
          }),
      })),
      modelsWithoutSections.length === 0
        ? null
        : {
            title: modelSections.length > 0 ? 'Other Data Models' : 'Raw Data Models',
            children: modelsWithoutSections.map((exploration) => {
              const url = buildAccountUrl(buildExplorationUrl(exploration));
              return {
                exploration,
                url,
                onClick: (event) => {
                  event?.preventDefault();
                  handleExplorationClick(exploration, url);
                },
              };
            }),
          },
    ],
    [
      buildAccountUrl,
      exploration,
      handleExplorationClick,
      modelExplorations,
      modelSections,
      modelsWithoutSections,
      navigate,
      onClickExploration,
      selectIndividualCells,
      unparameterisedExplorations,
    ],
  );

  const flattenedItems = useMemo(() => flattenLists(lists), [lists]);
  const activeItem = activeIndex !== null ? (flattenedItems.at(activeIndex) ?? null) : null;

  const collapsedItems = useMemo(
    () =>
      new Set(
        flattenedItems
          .map((item) => ('exploration' in item ? item.exploration.explorationId : item.cell.id))
          .filter((id) => !expandedItems.has(id)),
      ),
    [expandedItems, flattenedItems],
  );

  const { refs, context } = useFloating({
    open: true,
  });
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    virtual: true,
    loop: true,
    onNavigate: (index) => setActiveIndex(index),
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([listNavigation]);

  const showResults = trimmedSearchTerm.length > 0;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); //  Prevent explicit newlines in textarea
      if (showResults && onClickSearchResultRef.current !== null) {
        onClickSearchResultRef.current();
      }
      if (!showResults && activeItem !== null) {
        activeItem.onClick();
      }
    }
  };

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    const hash = setHashSearchTerm(window.location.hash, value);
    setSearchTerm(value);
    window.history.replaceState(null, '', `${location.pathname}#${hash}`);
  };

  const renderList = (item: ListNode, disabled: boolean) => {
    return 'exploration' in item ? (
      <CellList
        exploration={item.exploration}
        activeIndex={activeIndex}
        items={item.children}
        getItemProps={getItemProps}
        disabled={disabled}
      />
    ) : (
      <ExplorationList
        items={item.children}
        activeIndex={activeIndex}
        hideSectionLabels
        getItemProps={getItemProps}
        disabled={disabled}
        collapsedItems={collapsedItems}
      />
    );
  };

  return (
    <Panel className={classNames(styles.panel, className)}>
      <section className={styles.explorationSearch} ref={refs.setFloating} {...getFloatingProps()}>
        <FloatingFocusManager context={context} initialFocus={-1}>
          <FloatingList elementsRef={listRef}>
            <div className={styles.searchHeader}>
              <h1>What would you like to find?</h1>
              <div className={styles.searchInput}>
                <Icon name="Search" size={24} className={styles.searchIcon} />
                <div className={styles.textareaWrapper} data-value={searchTerm}>
                  <textarea
                    ref={refs.setReference}
                    value={searchTerm}
                    placeholder="Find explorations and data models"
                    autoFocus
                    rows={1}
                    onFocus={(e) =>
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length,
                      )
                    }
                    // eslint-disable-next-line react-compiler/react-compiler
                    {...getReferenceProps({
                      onChange: handleSearchTermChange,
                      onKeyDown: handleKeyDown,
                    })}
                  />
                </div>
              </div>
            </div>
            {notNil(copiedCell) && copiedExploration && (
              <Link
                to={copiedExplorationUrl}
                className={classNames(styles.listItem, styles.copySection)}
                onClick={(event) => {
                  removeCopiedCell();

                  if (notNil(onPasteExploration)) {
                    event.preventDefault();
                    onPasteExploration();
                  }
                }}>
                <Icon name="Clipboard" size={32} className={styles.icon} />
                <div className={styles.name}>Paste copied block</div>
                {copiedExplorationPipeline !== null && (
                  <div className={styles.pipeline}>
                    <PipelinePreview
                      models={models}
                      title={copiedExplorationTitle}
                      exploration={copiedExploration}
                      pipeline={copiedExplorationPipeline}
                    />
                  </div>
                )}
              </Link>
            )}
            {showResults ? (
              <SearchResults
                explorations={searchableExplorations}
                models={models}
                metrics={metrics}
                searchTerm={trimmedSearchTerm}
                selectIndividualCells={selectIndividualCells}
                onClickExploration={onClickExploration}
                activeIndex={activeIndex}
                getItemProps={getItemProps}
                onClickSearchResultRef={onClickSearchResultRef}
              />
            ) : (
              <div
                className={classNames(styles.allExplorations, {
                  [styles.scrolled]: isScrolled,
                })}
                ref={scrollContainerRef}>
                {lists.map((list, i) => {
                  if (list === null) {
                    return;
                  }
                  return (
                    <CollapsibleSection
                      key={i}
                      title={list.title}
                      isCollapsed={collapsedLists.has(i)}
                      onToggleCollapsed={() => toggleCollapsedList(i)}>
                      {renderList(list, collapsedLists.has(i))}
                    </CollapsibleSection>
                  );
                })}
              </div>
            )}
          </FloatingList>
        </FloatingFocusManager>
      </section>
    </Panel>
  );
};

interface ExplorationSearchModalProps extends ExplorationSearchProps {
  onClose: () => void;
}

export const ExplorationSearchModal = ({ onClose, ...rest }: ExplorationSearchModalProps) => (
  <Modal onClose={onClose} closeOnClickAway closeOnEsc>
    <ExplorationSearch {...rest} />
  </Modal>
);
