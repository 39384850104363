import React from 'react';
import { first } from 'lodash';

import { useBuildAccountUrl } from '@/lib/accounts/context';
import { Tooltip } from '@/components/tooltip';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { useMetadataContext } from '@/explore/metadata-context';
import { Exploration } from '@/explore/types';
import { buildExplorationHashUrl } from '@/explore/utils';

import { buildExplorationByType, BuildType } from '@/core/exploration';

import styles from './add-button.module.scss';

interface AddDropdownProps {
  type: 'templates' | 'code';
  onClick: (exploration: Exploration) => void;
}

export const AddDropdown = ({ type, onClick }: AddDropdownProps) => {
  const buildAccountUrl = useBuildAccountUrl();
  const { models } = useMetadataContext();
  const baseModelId = first(models)?.modelId ?? '';
  const iconSize = 16;

  const handleClick = (event: React.MouseEvent, type: BuildType) => {
    event.preventDefault();
    onClick(buildExplorationByType(type, baseModelId));
  };

  const templateMenuItems = [
    {
      icon: <Icon name="Funnel" size={iconSize} />,
      label: 'Funnel chart',
      type: 'link',
      href: buildAccountUrl(buildExplorationHashUrl(buildExplorationByType('funnel', baseModelId))),
      onClick: (e: React.MouseEvent) => handleClick(e, 'funnel'),
    },
    {
      icon: <Icon name="Cohort" size={iconSize} />,
      label: 'Retention chart',
      type: 'link',
      href: buildAccountUrl(buildExplorationHashUrl(buildExplorationByType('cohort', baseModelId))),
      onClick: (e: React.MouseEvent) => handleClick(e, 'cohort'),
    },
  ];

  const codeMenuItems = [
    {
      icon: <Icon name="Database" size={iconSize} />,
      label: 'SQL',
      type: 'link',
      href: buildAccountUrl(buildExplorationHashUrl(buildExplorationByType('sql', baseModelId))),
      onClick: (e: React.MouseEvent) => handleClick(e, 'sql'),
    },
    {
      icon: <Icon name="Python" size={iconSize} />,
      label: 'Python',
      type: 'link',
      href: buildAccountUrl(buildExplorationHashUrl(buildExplorationByType('python', baseModelId))),
      onClick: (e: React.MouseEvent) => handleClick(e, 'python'),
    },
  ];

  const items = type === 'code' ? codeMenuItems : templateMenuItems;

  const Items = (
    <div className={styles.tooltip}>
      {items.map((item, idx) => (
        <Button
          key={idx}
          variant="secondary"
          className={styles.button}
          size="small"
          onClick={item.onClick}>
          {item.label}
        </Button>
      ))}
    </div>
  );

  return (
    <Tooltip content={Items} hasArrow={false} trigger="click" placement="bottom">
      <Button
        size="small"
        variant="secondary"
        icon={<Icon name={type === 'code' ? 'Code' : 'Box'} size={16} />}
        className={styles.tooltipTrigger}>
        {type === 'code' ? 'Code' : 'Templates'}
        <Icon name="ChevronDown" />
      </Button>
    </Tooltip>
  );
};
