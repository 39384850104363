import { useState, useEffect, createContext, useContext, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useScrollLock } from 'usehooks-ts';

import { useScreenSize, Breakpoint } from '@/lib/hooks';

interface LayoutContext {
  isLeftSidebarOpen: boolean;
  setIsLeftSidebarOpen: (newVal: boolean) => void;
  toggleLeftSidebar: (newVal?: boolean) => void;
  isRightSidebarOpen: boolean;
  setIsRightSidebarOpen: (newVal: boolean) => void;
  toggleRightSidebar: (newVal?: boolean) => void;
  activeExplorationSourceId: string | null;
  setActiveExplorationSourceId: (explorationId: string | null) => void;
  isEmbeddedMode: boolean;
  scrollAreaRef?: React.RefObject<HTMLDivElement | null>;
  scrollToTop: () => void;
}

const defaultContextValue = Symbol();

const LayoutContext = createContext<LayoutContext | typeof defaultContextValue>(
  defaultContextValue,
);

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === defaultContextValue) {
    throw new Error('useLayoutContext must be used within a LayoutContextProvider');
  }
  return context;
};

export function LayoutContextProvider({ children }: { children: React.ReactNode }) {
  const [searchParams] = useSearchParams();
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState<boolean | null>(null);
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);
  const [activeExplorationSourceId, setActiveExplorationSourceId] = useState<string | null>(null);
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();
  const screenSize = useScreenSize();

  const isLeftSidebarOpenByDefault = screenSize.breakpoint > Breakpoint.lg;

  // TODO: Add keyboard shortcuts to toggle sidebar
  const toggleLeftSidebar = (newVal?: boolean) =>
    setIsLeftSidebarOpen((prev) => newVal ?? !(prev ?? isLeftSidebarOpenByDefault));
  const toggleRightSidebar = (newVal?: boolean) => setIsRightSidebarOpen((prev) => newVal ?? !prev);

  // Scroll lock for mobile
  const { lock, unlock, isLocked } = useScrollLock({
    autoLock: false,
  });
  useEffect(() => {
    if (screenSize.breakpoint <= Breakpoint.lg) {
      isLeftSidebarOpen === true ? lock() : unlock();
    } else if (screenSize.breakpoint > Breakpoint.lg && isLocked) {
      unlock();
    }
  }, [isLeftSidebarOpen, lock, unlock, isLocked, screenSize.breakpoint]);

  useEffect(() => {
    if (!location.pathname.includes('/explore/')) {
      setActiveExplorationSourceId(null);
    }
  }, [location.pathname]);

  const embeddedModeParam = searchParams.get('embed');
  useEffect(() => {
    if (embeddedModeParam === 'true') {
      sessionStorage.setItem('embed', 'true');
    }
  }, [embeddedModeParam]);
  const isEmbeddedMode = embeddedModeParam === 'true' || sessionStorage.getItem('embed') === 'true';

  const scrollToTop = () => {
    scrollAreaRef.current?.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };

  return (
    <LayoutContext.Provider
      value={{
        isLeftSidebarOpen: isLeftSidebarOpen ?? isLeftSidebarOpenByDefault,
        setIsLeftSidebarOpen,
        toggleLeftSidebar,
        isRightSidebarOpen,
        setIsRightSidebarOpen,
        toggleRightSidebar,
        activeExplorationSourceId,
        setActiveExplorationSourceId,
        isEmbeddedMode,
        scrollAreaRef,
        scrollToTop,
      }}>
      {children}
    </LayoutContext.Provider>
  );
}
