import classNames from 'classnames';
import { first, isBoolean } from 'lodash';
import { useEffect, useState } from 'react';

import { IconButton } from '@/components/button';
import { Dropdown, DropdownMenuItem } from '@/components/dropdown';
import { DateTimeInput } from '@/components/form/datetime-input';
import { Input } from '@/components/form/input';
import { PenguinoInput, PenguinoVersion } from '@/components/form/penguino-input';
import { SearchInput } from '@/components/form/search-input';
import { Select } from '@/components/form/select';
import { Icon } from '@/components/icon';
import { useTrackEvent } from '@/lib/analytics';
import { DateRange, getDefaultFormattedLocalDate, isDateRange } from '@/lib/date';
import { TagInput } from '@/components/form/tag-input';
import { VariableReferenceBadge } from '@/explore/components/variable-reference-badge';
import { variableColors } from '@/explore/components/variable-color';
import { useExplorationContext } from '@/explore/exploration/exploration-context';
import { useMetadataContext } from '@/explore/metadata-context';
import { isValueExpression } from '@/explore/pipeline/operation';
import { FieldGroup } from '@/explore/pipeline/utils';
import { getVariableColor, getVariableExpression, isNumberType } from '@/explore/utils';

import type {
  EnumOptions,
  Field,
  Fields,
  FilterCondition,
  FilterOperator,
  FilterValue,
  Model,
  VariableDefinition,
} from '../../types';
import { fieldToOption } from '../utils';
import {
  ensureStableValue,
  filterOperators,
  filterUnaryOperators,
  isBinaryFilterOperator,
  parseFilterValue,
  requiresArrayValue,
  stringifyFilterValue,
  UIFilterOperator,
  getOperatorLabel,
} from '../utils/filter';

import form from '@/components/form/form.module.scss';
import styles from '../pipeline.module.scss';

/**
 * Since the UI supports some operators that the backend doesn't, we need to do
 * a bit of back-and-forth conversion between the two filter shapes.
 * See FilterOperatorExtended vs FilterOperator under types.
 */
const convertFilterToOperationParameters = (
  field: Field,
  operator: UIFilterOperator,
  value: FilterValue,
): FilterCondition => {
  if (operator === 'istrue' || operator === 'isfalse') {
    value = operator === 'istrue' ? true : false;
    operator = '==';
  }
  return isBinaryFilterOperator(operator)
    ? { key: field.key, operator, value }
    : { key: field.key, operator };
};

const convertOperationParametersToFilter = (
  parameters: FilterCondition,
  fields: Fields,
): {
  key: string;
  operator: UIFilterOperator;
  value: FilterValue;
} => {
  const field = fields.find((f) => f.key === parameters.key) ?? first(fields);
  if (field === undefined) {
    throw new Error(`No fields to filter by`);
  }

  let operator = parameters.operator as UIFilterOperator;
  let value = parameters.value ?? '';

  if (field.type === 'Boolean' && operator === '==' && isBoolean(value)) {
    operator = parameters.value === true ? 'istrue' : 'isfalse';
    value = '';
  }

  return { key: field.key, operator, value: value };
};

const isEmptyOperationValueAllowed = (operator: UIFilterOperator | FilterOperator) =>
  !isBinaryFilterOperator(operator) || operator === '==' || operator === '!=';

const getOperatorsForField = (field: Field): readonly UIFilterOperator[] => {
  switch (field.type) {
    case 'Date':
      return filterOperators.filter(
        (operator) =>
          ![
            'arrcontains',
            'noticontains',
            'icontains',
            'istrue',
            'isfalse',
            'in',
            'notin',
          ].includes(operator),
      );
    case 'Number':
    case 'Integer':
    case 'Float':
      return filterOperators.filter(
        (operator) =>
          !['arrcontains', 'noticontains', 'icontains', 'istrue', 'isfalse'].includes(operator),
      );
    case 'Boolean':
      return filterUnaryOperators;
    case 'Object':
      return ['==', '!=', 'isnull', 'isnotnull', 'icontains', 'noticontains'];
    case 'Array':
      return ['arrcontains', ...filterUnaryOperators];
    case 'String':
      return filterOperators.filter(
        (operator) =>
          !['arrcontains', 'istrue', 'isfalse', '>', '>=', '<', '<='].includes(operator),
      );
    case 'Enum':
    case 'Interval':
    case null:
      return filterOperators.filter(
        (operator) => !['arrcontains', 'istrue', 'isfalse'].includes(operator),
      );
  }
};

interface FieldInputProps {
  fields: Fields;
  fieldKey: string;
  setFieldKey: (field: string) => void;
}

const FieldInput = (props: FieldInputProps) => {
  const { fields, fieldKey, setFieldKey } = props;
  return (
    <SearchInput
      options={fields.map(fieldToOption)}
      value={fieldKey}
      onChange={setFieldKey}
      autoFocus
    />
  );
};

interface OperatorInputProps {
  field: Field;
  operator: UIFilterOperator;
  setOperator: (operator: string) => void;
}

const OperatorInput = (props: OperatorInputProps) => {
  const { field, operator, setOperator } = props;
  const options = getOperatorsForField(field).map((operator) => ({
    value: operator,
    label: getOperatorLabel(operator, field.type),
  }));
  return <Select options={options} value={operator} onChange={setOperator} />;
};

interface ValueInputProps {
  fields: Fields;
  field: Field;
  fieldsForExpression: (Field | FieldGroup)[];
  enumOptions?: EnumOptions;
  operator: UIFilterOperator;
  value: FilterValue;
  setValue: (value: FilterValue) => void;
  model?: Model;
}

const ValueInput = (props: ValueInputProps) => {
  const { field, enumOptions, operator, value, setValue } = props;
  const { getVariables } = useExplorationContext();

  if (!isBinaryFilterOperator(operator)) {
    return null;
  }

  if (isValueExpression(value)) {
    return (
      <div className={form.formRowInner}>
        <PenguinoInput
          fields={props.fieldsForExpression}
          model={props.model}
          variables={getVariables()}
          placeholder="Enter custom formula..."
          value={value.expression}
          onChange={(expression) => setValue({ expression, version: PenguinoVersion })}
          requiredType={field.type ?? undefined}
          // Keep field order as-is
          autocompleteSort={() => 1}
        />
      </div>
    );
  }

  if (field.type === 'Date') {
    const defaultValue = getDefaultFormattedLocalDate();
    const mode = ['==', '!='].includes(operator) ? 'range' : 'single';

    const val =
      value === undefined
        ? { from: defaultValue, to: defaultValue }
        : isDateRange(value)
          ? value
          : { from: String(value), to: String(value) };

    const handleChange = (range: Partial<DateRange>) => {
      if (isDateRange(range) && mode === 'range') {
        return setValue(range);
      }

      if (mode === 'single') {
        return setValue(range.from);
      }

      throw new Error('Invalid date range');
    };

    return <DateTimeInput mode={mode} isRequired value={val} onChange={handleChange} />;
  }

  if (field.type === 'Enum' && !requiresArrayValue(operator)) {
    return <Select options={enumOptions ?? []} value={value?.toString()} onChange={setValue} />;
  }

  if (Array.isArray(value)) {
    return (
      <TagInput
        required={!isEmptyOperationValueAllowed(operator)}
        value={value.map((v) => stringifyFilterValue(v)).filter((v) => v !== '')}
        placeholder={'Value1, Value2, ...'}
        onChange={(value) =>
          setValue(
            isNumberType(field.type) &&
              value.every((v) => v !== '' && !isNaN(Number(v)) && String(v).at(-1) !== '.')
              ? value.map(Number)
              : value,
          )
        }
      />
    );
  }

  const isNumberField = isNumberType(field.type);

  return (
    <Input
      required={!isEmptyOperationValueAllowed(operator)}
      type={isNumberField ? 'number' : 'text'}
      step="any"
      value={stringifyFilterValue(value)}
      placeholder={isNumberField ? 'Number' : 'Value'}
      onChange={(e) => setValue(parseFilterValue(e.target.value, field.type, operator))}
    />
  );
};

interface FilterConditionFormProps {
  fields: Fields;
  fieldsForExpression: (Field | FieldGroup)[];
  condition: FilterCondition;
  setCondition: (condition: FilterCondition) => void;
  variables: VariableDefinition[];
  model?: Model;
  onRemove?: () => void;
}

export const FilterConditionForm = (props: FilterConditionFormProps) => {
  const { fields, model, fieldsForExpression, condition, setCondition, variables, onRemove } =
    props;
  const { getEnumOptions } = useMetadataContext();
  const [enumOptions, setEnumOptions] = useState<EnumOptions | undefined>();
  const { exploration } = useExplorationContext();
  const trackEvent = useTrackEvent();

  const { key, operator, value } = convertOperationParametersToFilter(condition, fields);
  const valueIsExpression = isValueExpression(value);

  const valueIsVariableReference =
    valueIsExpression &&
    variables.some((variable) => getVariableExpression(variable.key) === value.expression);

  const field = fields.find((f) => f.key === key);

  // Asynchronously fetch enum options
  useEffect(() => {
    const model = field?.model;
    if (field?.type !== 'Enum' || model === undefined || requiresArrayValue(condition.operator)) {
      return;
    }

    const fetchEnumOptions = async () => {
      const options = await getEnumOptions(model.modelId, model.propertyKey);
      setEnumOptions(options);
      if (
        field?.type !== 'Enum' ||
        valueIsVariableReference ||
        valueIsExpression ||
        requiresArrayValue(condition.operator) ||
        options === undefined ||
        options.length === 0
      ) {
        return;
      }

      const nextValue = (options.find((option) => option.value === value) ?? first(options))?.value;
      if (value !== nextValue) {
        setCondition({ ...condition, value: nextValue });
      }
    };

    fetchEnumOptions();
  }, [
    getEnumOptions,
    field,
    value,
    setCondition,
    condition,
    valueIsVariableReference,
    valueIsExpression,
  ]);

  if (field === undefined) {
    throw new Error(`FilterForm passed an unknown field ${key}.`);
  }

  const variableMenuItems: DropdownMenuItem[] =
    variables.length > 0
      ? [
          {
            type: 'divider',
          },
          ...variables.map((variable) => ({
            label: variable.key,
            icon: <Icon name="VariableInstance" size={16} />,
            color: getVariableColor(variable.key, variables, variableColors),
            onClick: () => {
              trackEvent('Variable Assigned', {
                exploration,
                variable: value,
              });

              return handleChange(field, operator, {
                expression: getVariableExpression(variable.key),
                version: PenguinoVersion,
              });
            },
          })),
        ]
      : [];

  const menuItems: DropdownMenuItem[] = [
    {
      icon: <Icon name="CustomFormula" size={16} />,
      label: 'Custom Formula',
      className: classNames({ [styles.customFormulaActive]: valueIsExpression }),
      onClick: () =>
        handleChange(
          field,
          operator,
          valueIsExpression ? '' : { expression: '', version: PenguinoVersion },
        ),
      disabled: !isBinaryFilterOperator(operator),
    },
    ...variableMenuItems,
  ];

  if (onRemove !== undefined) {
    menuItems.push({
      label: 'Delete',
      onClick: onRemove,
      icon: <Icon name="Trash2" size={16} />,
    });
  }

  const handleChange = (field: Field, operator: UIFilterOperator, value: FilterValue) => {
    setCondition(convertFilterToOperationParameters(field, operator, value));
  };

  if (field.type === 'Boolean') {
    return (
      <BooleanFilterConditionForm
        operator={operator}
        value={value}
        field={field}
        fields={fields}
        variables={variables}
        onChange={handleChange}
        onRemove={onRemove}
      />
    );
  }

  return (
    <>
      <div className={form.formRow}>
        <FieldInput
          fields={fields}
          fieldKey={key}
          setFieldKey={(v) => {
            const newField = fields.find((f) => f.key === v) as Field;
            const newOperators = getOperatorsForField(newField);
            const newOperator = newOperators.includes(operator)
              ? operator
              : (newOperators.at(0) ?? '==');
            handleChange(newField, newOperator, ensureStableValue(newField, newOperator, value));
          }}
        />
        <div className={form.narrow}>
          <OperatorInput
            field={field}
            operator={operator}
            setOperator={(v) => {
              handleChange(
                field,
                v as UIFilterOperator,
                ensureStableValue(field, v as UIFilterOperator, value),
              );
            }}
          />
        </div>
      </div>
      <div className={classNames(form.formRow, form.alignTop)}>
        {valueIsVariableReference ? (
          <VariableReferenceBadge
            value={value.expression}
            variables={variables}
            onRemove={() => {
              trackEvent('Variable Unassigned', { exploration, variable: value });

              return handleChange(field, operator, field.type === 'Boolean' ? true : '');
            }}
          />
        ) : (
          <ValueInput
            fields={fields}
            field={field}
            model={model}
            fieldsForExpression={fieldsForExpression}
            enumOptions={enumOptions}
            operator={operator}
            value={value}
            setValue={(value) => handleChange(field, operator, value)}
          />
        )}

        {
          <Dropdown
            align="right"
            trigger={(isOpen, setIsOpen) => (
              <IconButton
                icon="MoreHorizontal"
                size="small"
                title="More..."
                type="gray"
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
            items={menuItems}
          />
        }
      </div>
    </>
  );
};

interface BooleanFilterConditionFormProps {
  operator: UIFilterOperator;
  value: FilterValue;
  field: Field;
  fields: Fields;
  onChange: (field: Field, operator: UIFilterOperator, value: FilterValue) => void;
  onRemove?: () => void;
  variables: VariableDefinition[];
}

const BooleanFilterConditionForm = (props: BooleanFilterConditionFormProps) => {
  const { operator, value, field, fields, onChange, onRemove, variables } = props;
  const trackEvent = useTrackEvent();
  const { exploration } = useExplorationContext();
  const menuItems: DropdownMenuItem[] = [
    ...(onRemove !== undefined
      ? [
          {
            label: 'Delete',
            onClick: onRemove,
            icon: <Icon name="Trash2" size={16} />,
          },
        ]
      : []),
    ...variables.map((variable) => ({
      label: variable.key,
      icon: <Icon name="VariableInstance" size={16} />,
      color: getVariableColor(variable.key, variables, variableColors),
      onClick: () => {
        trackEvent('Variable Assigned', {
          explorationId: exploration.explorationId,
          variable: value,
        });

        return onChange(field, '==', {
          expression: getVariableExpression(variable.key),
          version: PenguinoVersion,
        });
      },
    })),
  ];

  return (
    <div className={form.formRow}>
      <FieldInput
        fields={fields}
        fieldKey={field.key}
        setFieldKey={(v) => {
          const newField = fields.find((f) => f.key === v) as Field;
          let newOperator = operator;
          let newValue = value;
          const newOperators = getOperatorsForField(newField);
          if (!newOperators.includes(operator)) {
            newValue = '';
            newOperator = first(newOperators) ?? '==';
          }
          onChange(newField, newOperator, newValue);
        }}
      />
      {isValueExpression(value) ? (
        <VariableReferenceBadge
          value={value.expression}
          variables={variables}
          onRemove={() => {
            trackEvent('Variable Unassigned', {
              explorationId: exploration.explorationId,
              variable: value,
            });
            return onChange(field, operator, true);
          }}
        />
      ) : (
        <div className={form.narrow}>
          <OperatorInput
            field={field}
            operator={operator}
            setOperator={(operator) => onChange(field, operator as UIFilterOperator, value)}
          />
        </div>
      )}

      {menuItems.length > 0 && (
        <Dropdown
          align="right"
          trigger={(isOpen, setIsOpen) => (
            <IconButton
              icon="MoreHorizontal"
              size="small"
              title="More..."
              type="gray"
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          items={menuItems}
        />
      )}
    </div>
  );
};
