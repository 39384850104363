import { first } from 'lodash';

import { useExplorationContext } from '../exploration/exploration-context';
import {
  AggregatedVisualisation,
  Metric,
  Model,
  Pipeline,
  QueryVariables,
  SimpleVisualisation,
  Visualisation,
  isAggregatedVisualisation,
} from '../types';
import { Toggle } from '../../components/form/toggle';
import {
  disableShowValues,
  enableShowValues,
  getActiveSeriesAxes,
  getActiveSeriesChartTypes,
  getActiveSeriesShowValues,
  getBigNumberFields,
  getChartType,
  getDefaultSortForVisualisation,
  getQueryForVisualisation,
  isBigNumberToggleable,
  isNumericField,
  resetSeriesOptions,
  setBigNumberOptions,
  setSecondaryAxisKey,
  setSeriesChartType,
  setSeriesColor,
  setVisualisationValueKeys,
  toggleVisualisationAggregation,
} from '../components/visualisation/utils';
import { getDereferencedPipelineFields } from '../pipeline/state';
import { dereferencePipeline } from '../pipeline/utils';
import { EditAggregatedVisualisation } from './edit-aggregated-visualisation';
import { EditSimpleVisualisation } from './edit-simple-visualisation';
import { EditBigNumberVisualisation } from './edit-big-number-visualisation';

import { buildSeriesMenuItems } from './utils';
import { SortingEditor } from '../edit-pipeline/sorting-editor';

import form from '../../components/form/form.module.scss';

interface EditVisualisationProps {
  visualisation: Visualisation;
  pipeline: Pipeline;
  models: Model[];
  metrics: Metric[];
  variables: QueryVariables;
  onChange: (visualisation: Visualisation) => void;
}

export const EditVisualisation = (props: EditVisualisationProps) => {
  const { visualisation, pipeline, models, metrics, variables, onChange } = props;
  const { exploration } = useExplorationContext();

  const isAggregated = isAggregatedVisualisation(visualisation);
  const stateContext = { models, variables, metrics };
  const dereferencedPipeline = dereferencePipeline(pipeline, exploration);
  const fields = getDereferencedPipelineFields(dereferencedPipeline, stateContext);
  const options = visualisation.viewOptions ?? {};
  const bigNumberOptions = options.bigNumber;
  const bigNumberFieldKey = bigNumberOptions?.key;
  const { pipeline: visualisationPipeline, groups } = getQueryForVisualisation(
    dereferencedPipeline,
    fields,
    visualisation,
  );
  const isBigNumberVisible = bigNumberFieldKey !== undefined;
  const visualisationFields = getDereferencedPipelineFields(visualisationPipeline, stateContext);
  const chartType = getChartType(visualisation, fields);

  const sortableFields = visualisationFields.filter(
    ({ key }) => key === visualisation.mainAxisKey || visualisation.valueKeys.includes(key),
  );

  const handleAggregationToggleChange = (checked: boolean) => {
    onChange(toggleVisualisationAggregation(checked, visualisation, fields));
  };

  const handleBigNumberToggleChange = (checked: boolean) => {
    const key = checked ? first(getBigNumberFields(visualisationFields))?.key : undefined;

    onChange({
      ...visualisation,
      viewOptions: setBigNumberOptions(
        { bigNumber: key !== undefined ? { key } : undefined },
        visualisation.viewOptions,
        {
          mainAxisKey: visualisation.mainAxisKey,
        },
      ),
    });
  };

  const hasCombinedSeries = groups.length > 1;

  const getValueKeyMenuOptions = (key: string) => {
    const seriesOptions = visualisation.viewOptions?.series?.[key];
    return buildSeriesMenuItems({
      color: seriesOptions?.color ?? '',
      chartTypes: getActiveSeriesChartTypes(key, visualisation.viewOptions),
      axes: getActiveSeriesAxes(key, visualisation.viewOptions),
      showValues: getActiveSeriesShowValues(key, visualisation.viewOptions),
      isChartTypeDisabled: chartType !== 'grouped-timeseries',
      isSecondaryAxisDisabled: chartType !== 'grouped-timeseries',
      onShowValuesChange: (showValues) => {
        onChange({
          ...visualisation,
          viewOptions: showValues
            ? enableShowValues(key, visualisation.viewOptions)
            : disableShowValues(key, visualisation.viewOptions),
        });
      },
      onColorChange: !hasCombinedSeries
        ? (color) =>
            onChange({
              ...visualisation,
              viewOptions: setSeriesColor(key, color, visualisation.viewOptions),
            })
        : undefined,
      onChartTypeChange: (chartType) =>
        onChange({
          ...visualisation,
          viewOptions: setSeriesChartType(key, chartType, visualisation.viewOptions),
        }),
      onSecondaryAxisChange: (checked) => {
        onChange({
          ...visualisation,
          viewOptions: setSecondaryAxisKey(key, checked, visualisation.viewOptions),
        });
      },
      onRemove:
        !isAggregated && visualisation.valueKeys.length > 1
          ? () =>
              onChange(
                setVisualisationValueKeys(
                  visualisation,
                  visualisation.valueKeys.filter((k) => k !== key),
                ),
              )
          : undefined, //Aggregation editor handles removing
      onReset: () => {
        onChange({
          ...visualisation,
          viewOptions: resetSeriesOptions(key, visualisation.viewOptions),
        });
      },
    });
  };

  const handleSortingToggleChange = (checked: boolean) => {
    onChange({
      ...visualisation,
      sort: checked
        ? getDefaultSortForVisualisation(visualisation, visualisationFields)
        : undefined,
    });
  };

  return (
    <div className={form.formHorizontal}>
      <Toggle
        checked={isAggregated}
        disabled={!fields.some(isNumericField)}
        onChange={handleAggregationToggleChange}
        size="small">
        Aggregate
      </Toggle>
      {isAggregated ? (
        <EditAggregatedVisualisation
          visualisation={visualisation as AggregatedVisualisation}
          pipeline={dereferencedPipeline}
          models={models}
          metrics={metrics}
          variables={variables}
          getValueKeyMenuOptions={getValueKeyMenuOptions}
          onChange={onChange}
        />
      ) : (
        <EditSimpleVisualisation
          visualisation={visualisation as SimpleVisualisation}
          pipeline={dereferencedPipeline}
          models={models}
          metrics={metrics}
          variables={variables}
          getValueKeyMenuOptions={getValueKeyMenuOptions}
          groups={groups}
          onChange={onChange}
        />
      )}
      <hr className={form.dashed} />
      <Toggle
        checked={visualisation.sort !== undefined}
        disabled={chartType !== 'bar'}
        onChange={handleSortingToggleChange}
        size="small">
        Sort manually
      </Toggle>
      {visualisation.sort !== undefined && (
        <div className={form.formRow}>
          <label className={form.formLabel}>Sorted by</label>
          <SortingEditor
            fields={sortableFields}
            sorting={visualisation.sort}
            setSorting={(sort) => {
              onChange({
                ...visualisation,
                sort,
              });
            }}
          />
        </div>
      )}
      <hr className={form.dashed} />
      <Toggle
        checked={isBigNumberVisible}
        disabled={!isBigNumberToggleable(visualisation, visualisationFields, groups)}
        onChange={handleBigNumberToggleChange}
        size="small">
        Show first row as big number
      </Toggle>
      {isBigNumberVisible ? (
        <EditBigNumberVisualisation
          visualisation={visualisation}
          fields={fields}
          visualisationFields={visualisationFields}
          onChange={onChange}
        />
      ) : null}
    </div>
  );
};
