import { first } from 'lodash';

import { model } from '@gosupersimple/types';

import { createBasePipeline, createPipelineWithParent } from '@/core/pipeline';
import { generateCellId, isRecordsLikeCell } from '@/core/cell';
import { addCells, addCellsAt } from '@/core/exploration';

import { CombineItemState, CombineSide, CombineState } from './types';
import { replacePipeline } from '../../edit-pipeline/utils';
import {
  Cell,
  Exploration,
  Field,
  JoinPipelineOperation,
  Pipeline,
  RecordsCell,
} from '../../types';
import { getCell, getCellIndex } from '../utils';

export const getDefaultJoinKey = (fields: Field[], otherItem?: CombineItemState) =>
  fields.find((field) => field.key === otherItem?.joinKey)?.key ??
  fields.find((field) => field.pk ?? false)?.key ??
  first(fields)?.key ??
  '';

export const addCombineToExploration = (
  initialExploration: Exploration,
  combine: CombineState,
  cellId: string,
): { cell: Cell; exploration: Exploration } | undefined => {
  const result = combinePipeline(initialExploration, combine, cellId);

  if (result === undefined) {
    return;
  }

  const { pipeline, exploration } = result;
  const addedCellCount = exploration.view.cells.length - initialExploration.view.cells.length;

  const cell: RecordsCell = {
    id: generateCellId(),
    title: 'Combined data',
    kind: 'records',
    pipeline,
  };

  return {
    cell,
    exploration: addCellsAt(
      exploration,
      [cell],
      getCellIndex(cellId, result.exploration) + addedCellCount + 1,
    ),
  };
};

export const updateCombineInExploration = (
  exploration: Exploration,
  combine: CombineState,
  cellId: string,
): Exploration | undefined => {
  const result = combinePipeline(exploration, combine, cellId);

  if (result === undefined) {
    return;
  }

  return replacePipeline(result.exploration, cellId, result.pipeline);
};

// TODO: Reuse part of previous pipeline when updating existing one
const combinePipeline = (
  initialExploration: Exploration,
  combine: CombineState,
  cellId: string,
) => {
  const { left, right } = combine;
  let exploration: Exploration = initialExploration;
  let pipeline: Pipeline | undefined = undefined;
  let operation: JoinPipelineOperation | undefined = undefined;

  if (right.kind === 'cell') {
    const isFromCurrentExploration = getCell(right.cellId, exploration) !== undefined;
    if (!isFromCurrentExploration) {
      exploration = addCells(exploration, right.cells, cellId);
    }
    const cell = getRecordsLikeCell(right.cellId, exploration);
    if ('pipelineId' in cell.pipeline && cell.pipeline.pipelineId !== undefined) {
      operation = {
        operation: 'joinPipeline',
        parameters: {
          pipeline: createPipelineWithParent({
            parentId: cell.pipeline.pipelineId,
            operations: [],
          }),
          joinStrategy: { joinKeyOnBase: left.key, joinKeyOnRelated: right.key },
        },
      };
    }
  }

  if (right.kind === 'model') {
    operation = {
      operation: 'joinPipeline',
      parameters: {
        pipeline: createBasePipeline({ baseModelId: right.modelId, operations: [] }),
        joinStrategy: { joinKeyOnBase: left.key, joinKeyOnRelated: right.key },
      },
    };
  }

  if (operation !== undefined) {
    if (left.kind === 'cell') {
      const isFromCurrentExploration = getCell(left.cellId, exploration) !== undefined;
      if (!isFromCurrentExploration) {
        exploration = addCells(exploration, left.cells, cellId);
      }
      const cell = getRecordsLikeCell(left.cellId, exploration);

      if ('pipelineId' in cell.pipeline && cell.pipeline.pipelineId !== undefined) {
        pipeline = createPipelineWithParent({
          parentId: cell.pipeline.pipelineId,
          operations: [operation],
        });
      }
    }

    if (left.kind === 'model') {
      pipeline = createBasePipeline({
        baseModelId: left.modelId,
        operations: [operation],
      });
    }
  }

  if (pipeline !== undefined) {
    return { pipeline, exploration };
  }
};

const getRecordsLikeCell = (cellId: string, exploration: Exploration) => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined || !isRecordsLikeCell(cell)) {
    throw new Error(`Cell ${cellId} not found or is not RecordsCell`);
  }
  return cell;
};

const JoinableStringTypes = ['String', 'Enum'];
const JoinableNumberTypes = ['Number', 'Integer', 'Float'];

function isJoinTypeCompatible(left: model.PropertyType | null, right: model.PropertyType | null) {
  if (JoinableStringTypes.includes(left ?? '') && JoinableStringTypes.includes(right ?? '')) {
    return true;
  }
  if (JoinableNumberTypes.includes(left ?? '') && JoinableNumberTypes.includes(right ?? '')) {
    return true;
  }
  return left === right;
}

export function buildOtherItem(leftItem?: CombineItemState, rightItem?: CombineItemState) {
  const fieldType = leftItem?.fields.find(({ key }) => key === leftItem.joinKey)?.type;

  const fields =
    leftItem === undefined || fieldType === undefined
      ? (rightItem?.allFields ?? [])
      : (rightItem?.allFields.filter((field) => isJoinTypeCompatible(fieldType, field.type)) ?? []);

  if (rightItem === undefined) {
    return;
  }

  return {
    ...rightItem,
    fields,
    joinKey: fields.map((f) => f.key).includes(rightItem.joinKey)
      ? rightItem.joinKey
      : getDefaultJoinKey(fields, leftItem),
  };
}

export function convertItemToSide(item: CombineItemState | undefined): CombineSide | undefined {
  if (item === undefined) {
    return;
  }

  if (item.kind === 'model') {
    return { kind: 'model', modelId: item.modelId, key: item.joinKey };
  }

  return { kind: 'cell', cells: item.cells, cellId: item.cellId, key: item.joinKey };
}
