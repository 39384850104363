import { generateUUID } from '@/lib/utils';
import {
  Cell,
  CellViewOptions,
  Pipeline,
  RecordsCell,
  RecordsLikeCell,
  Sort,
  SqlCell,
  Visualisation,
} from '@/explore/types';
import { isVariableCell } from '@/explore/utils';

export * from './chat-cell';
export * from './conversation-cell';

export const isRecordsCell = (cell: Cell): cell is RecordsCell => cell.kind === 'records';

export const isSqlCell = (cell: Cell): cell is SqlCell => cell.kind === 'sql';

// Returns true for any cell that results with table like data in its query.
export const isRecordsLikeCell = (cell: Cell): cell is RecordsLikeCell =>
  isRecordsCell(cell) || isSqlCell(cell);

export type CellWithTitle = Extract<Cell, { title?: string | null }>;
export function isCellWithTitle(cell: Cell): cell is CellWithTitle {
  return 'title' in cell;
}

export type CellWithPipeline = Extract<Cell, { pipeline?: Pipeline }>;
export function isCellWithPipeline(cell: Cell): cell is CellWithPipeline {
  return 'pipeline' in cell;
}

export type CellWithVisualisations = Extract<Cell, { visualisations?: Visualisation[] }>;
export function isCellWithVisualisations(cell: Cell): cell is CellWithVisualisations {
  return 'visualisations' in cell;
}

export type CellWithSort = Extract<Cell, { sort?: Sort }>;

export type ReplicableCell = RecordsLikeCell;

export const isReplicableCell = (cell: Cell): cell is ReplicableCell => isRecordsLikeCell(cell);

export const generateCellId = generateUUID;

export function setCellTitle(cell: CellWithTitle, title: string): CellWithTitle {
  return { ...cell, title };
}

export function setCellViewOptions<T extends Cell>(cell: T, viewOptions?: CellViewOptions): T {
  return { ...cell, viewOptions };
}

export function setCellPipeline(cell: CellWithPipeline, pipeline: Pipeline): CellWithPipeline {
  return { ...cell, pipeline };
}

export function setCellVisualisations(
  cell: CellWithVisualisations,
  visualisations: Visualisation[],
): CellWithVisualisations {
  return { ...cell, visualisations };
}

export function setCellSort(cell: CellWithSort, sort?: Sort): CellWithSort {
  return { ...cell, sort };
}

/**
 * Insert cells at a specific index. This function will filter out duplicate variable cells.
 * @param cells Existing cells to insert into.
 * @param insertedCells Cells to insert.
 * @param idx Index to insert at.
 * @returns New array of cells with the inserted cells.
 */
export function insertCellsAtIdx(cells: Cell[], insertedCells: Cell[], idx: number): Cell[] {
  return [
    ...cells.slice(0, idx),
    ...insertedCells.filter(filterVariableCellDuplicates(cells)),
    ...cells.slice(idx),
  ];
}

export function insertCellAfter(cells: Cell[], insertedCell: Cell, afterCellId: string): Cell[] {
  const idx = cells.findIndex((cell) => cell.id === afterCellId);
  return idx === -1 ? cells : insertCellAtIdx(cells, insertedCell, idx + 1);
}

export function insertCellsAfter(cells: Cell[], insert: Cell[], afterCellId: string): Cell[] {
  const idx = cells.findIndex((cell) => cell.id === afterCellId);
  return idx === -1 ? cells : insertCellsAtIdx(cells, insert, idx + 1);
}

export function insertCellAtIdx(cells: Cell[], insertedCell: Cell, idx: number): Cell[] {
  return insertCellsAtIdx(cells, [insertedCell], idx);
}

export function replaceCell(cells: Cell[], replacedCell: Cell): Cell[] {
  return cells.map((cell) => (cell.id === replacedCell.id ? replacedCell : cell));
}

/**
 * Filter function to prevent adding duplicate variable cells.
 * @param existingCells Existing cells to compare against.
 * @returns A function that returns true if it is safe to add the new cell.
 */
function filterVariableCellDuplicates(existingCells: Cell[]) {
  return function (newCell: Cell): boolean {
    return isVariableCell(newCell)
      ? !existingCells.some(
          (existingCell) =>
            isVariableCell(existingCell) && existingCell.definition.key === newCell.definition.key,
        )
      : true;
  };
}
