import { Exploration } from '@/explore/types';

import { CellWithPipeline, isCellWithPipeline } from '../cell';
import { isPipelineChildOf, isPipelineWithParent } from '../pipeline';

export const getRootPipelineCell = (
  exploration: Exploration,
  cell: CellWithPipeline,
): CellWithPipeline | null => {
  if (!isPipelineWithParent(cell.pipeline)) {
    return cell;
  }

  const parent = exploration.view.cells.find(
    (c) => isCellWithPipeline(c) && isPipelineChildOf(cell.pipeline, c.pipeline),
  );

  if (parent === undefined || !isCellWithPipeline(parent)) {
    return null;
  }

  return getRootPipelineCell(exploration, parent);
};
