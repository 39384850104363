import { Extension } from '@tiptap/core';

export const CustomKeyboardShortcuts = Extension.create({
  name: 'customKeyboardShortcuts',

  addKeyboardShortcuts() {
    return {
      /**
       * If editor has an active selection, only remove the selection (to hide PopupMenu) and do not propagate event.
       * Returning true tells tiptap that an extension has handled the event; otherwise continue with
       * default behaviour, which allows users to deselect the text block
       */
      Escape: ({ editor }) => {
        const event = editor.view.dom.ownerDocument.defaultView?.event;
        const {
          state: {
            selection: { from, to },
          },
        } = editor.view;

        if (event && from !== to) {
          event.stopPropagation();
          editor.chain().focus().setTextSelection(to).run();
          return true;
        }

        return false;
      },
    };
  },
});
