import { useState } from 'react';

import { Icon } from '@/components/icon';
import { SegmentedControl } from '@/components/segmented-control';

import type {
  DeriveFieldOperation,
  Exploration,
  Fields,
  Metric,
  Model,
  PipelineStateRelation,
  RelationAggregateOperation,
  VariableDefinition,
} from '../types';
import { DeriveFieldForm } from './edit-derive-field';
import { RelationColumnForm } from './edit-relation-columns';

import panelStyles from '@/components/panel/panel.module.scss';
import form from '@/components/form/form.module.scss';

interface NewColumnFormProps {
  fields: Fields;
  model: Model;
  exploration: Exploration;
  models: Model[];
  metrics: Metric[];
  setOperation(operation: any): void;
  onClose(): void;
  variables: VariableDefinition[];
  relations: PipelineStateRelation[];
  operation?: RelationAggregateOperation | DeriveFieldOperation;
}

export const NewColumnForm = (props: NewColumnFormProps) => {
  const modelHasRelations = props.relations.length > 0;
  const [isCustomFormula, setIsCustomFormula] = useState(
    !modelHasRelations || props.operation?.operation === 'deriveField',
  );

  return (
    <div className={form.formHorizontal}>
      <SegmentedControl
        options={[
          {
            label: 'Related data',
            selected: !isCustomFormula,
            onClick: () => setIsCustomFormula(false),
          },
          {
            label: (
              <>
                Calculation
                <Icon name="CustomFormula" size={14} />
              </>
            ),
            selected: isCustomFormula,
            onClick: () => setIsCustomFormula(true),
          },
        ]}
      />

      {isCustomFormula ? (
        <>
          <div className={form.helpText}>
            Perform custom calculations and apply logic using existing fields in the table
          </div>
          <hr className={panelStyles.fullWidth} />
          <DeriveFieldForm
            fields={props.fields}
            setOperation={props.setOperation}
            onClose={props.onClose}
            operation={props.operation?.operation === 'deriveField' ? props.operation : undefined}
          />
        </>
      ) : (
        <>
          {props.relations.length > 0 && (
            <>
              <div className={form.helpText}>Add columns from available relations</div>
              <hr className={panelStyles.fullWidth} />
            </>
          )}

          <RelationColumnForm
            fields={props.fields}
            models={props.models}
            model={props.model}
            relations={props.relations}
            exploration={props.exploration}
            onSubmit={props.setOperation}
            onClose={props.onClose}
            variables={props.variables}
            operation={props.operation?.operation !== 'deriveField' ? props.operation : undefined}
          />
        </>
      )}
    </div>
  );
};
