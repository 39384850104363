import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import { MouseEvent } from 'react';
import { isNil } from 'lodash';

import { getDomain } from '@/public/utils/url';

export interface FootnoteProps {
  href: string;
  label?: string;
  citeId: string;
}

export const Footnote = ({ node }: NodeViewProps) => {
  const props = node.attrs as FootnoteProps;
  const { label, citeId, href } = props;
  const domain = isNil(href) || href.length === 0 ? null : getDomain(href);

  if (domain === null) {
    return null;
  }

  const handleClick = (event: MouseEvent) => {
    // Prevent selecting cell
    event.stopPropagation();
  };

  return (
    <NodeViewWrapper className="footnote-node">
      <a
        href={`#cite-${citeId}`}
        id={`cite-ref-${citeId}`}
        aria-describedby="footnote-label"
        onClick={handleClick}>
        {label}
      </a>
    </NodeViewWrapper>
  );
};
