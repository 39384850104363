import { Fragment } from 'react';
import classNames from 'classnames';
import { isArray, isNumber, isString } from 'lodash';

import { includesTime } from '@/lib/date';
import { formatPropertyValue } from '@/explore/utils/format';

export type Token =
  | string
  | number
  | { value: string | number; kind: string; label?: string }
  | Token[];

interface OperationDescriptionProps {
  description: Token;
  classNames?: { [key: string]: classNames.Argument };
}

export const descriptionToString = (token: Token): string => {
  if (isString(token) || isNumber(token)) {
    return token.toString();
  }

  if (isArray(token)) {
    return token.map(descriptionToString).join(' ');
  }

  if (token.kind === 'date') {
    const date = token.value.toString();
    return formatPropertyValue(date, {
      type: 'Date',
      format: includesTime(date) ? undefined : 'date',
    });
  }

  if (token.kind === 'value') {
    return `"${token.value}"`;
  }

  return token.value.toString();
};

export const OperationDescription = (props: OperationDescriptionProps) => (
  <OperationDescriptionItem description={props.description} classNames={props.classNames} />
);

const OperationDescriptionItem = (props: OperationDescriptionProps) => {
  const { description } = props;

  if (isString(description) || isNumber(description)) {
    return <>{description}</>;
  }

  if (isArray(description)) {
    return (
      <>
        {description.map((o, i) => (
          <Fragment key={i}>
            <OperationDescriptionItem description={o} classNames={props.classNames} />{' '}
          </Fragment>
        ))}
      </>
    );
  }

  if (description.kind === 'expression') {
    return (
      <>
        {description.label !== undefined ? <span>{description.label}: </span> : null}
        <span className={classNames(props.classNames?.expression)}>{description.value}</span>
      </>
    );
  }

  if (description.kind === 'date') {
    const date = description.value.toString();

    return (
      <>
        {formatPropertyValue(date, {
          type: 'Date',
          format: includesTime(date) ? undefined : 'date',
        })}
      </>
    );
  }

  if (description.kind === 'value' && isString(description.value)) {
    return <>&quot;{description.value}&quot;</>;
  }

  return <>{description.value}</>;
};
