export const starterCode = `
# This code runs in your browser through Pyodide.
# The last line of the cell is displayed as the output of the cell.
# Read more about included packages here: https://pyodide.org/en/stable/usage/packages-in-pyodide.html


# Couple of examples:
# 1. Load data from Supersimple
from supersimple import getBlock

df = (
    await getBlock("User") # data model name or exploration title
    .fetchAll(page_size=100, limit=250, columns=['User ID', 'Account ID', 'Role', 'Email', 'Created At'])  # all arguments are optional
    # .as_json()
    .as_df()  # to fetch as pandas dataframe with correct column types
)
df


# 2. Create custom plots through pandas
import matplotlib.pyplot as plt

df.Role.hist(figsize=(8, 3), xrot=90)


# 3. Create custom plots with matplotlib
import matplotlib.pyplot as plt

plt.figure(figsize=(8, 3))
plt.title('Lines')
plt.plot([1, 2, 3], label="line 1")
plt.plot([3, 2, 1], label="line 2")
plt.legend()
plt


# 4. Install new packages from pypi (only non-native packages are supported)
!pip install folium


# 5. Display interactive maps
!pip install folium

import folium
import numpy as np

lat = 58.76315484968047
lng = 25.28096507099963

map = folium.Map(
    location=[lat, lng], zoom_start=8, control_scale=True
)

n = 100
for lat, lng in zip(
    np.random.normal(lat, 0.4, n),
    np.random.normal(lng, 0.8, n),
):
    folium.CircleMarker(
        location=[lat, lng], radius=2, weight=5, tooltip=f"Point lat: {lat} lng: {lng}"
    ).add_to(map)
map

# 6. Display interactive plots with Plotly
!pip install plotly

import plotly.graph_objects as go

fig = go.Figure(data=[go.Sankey(
    node = dict(
      pad = 15,
      thickness = 20,
      line = dict(color = "black", width = 0.5),
      label = ["A1", "A2", "B1", "B2", "C1", "C2"],
      color = "blue"
    ),
    link = dict(
      source = [0, 1, 0, 2, 3, 3], # indices correspond to labels, eg A1, A2, A1, B1, ...
      target = [2, 3, 3, 4, 4, 5],
      value = [8, 4, 2, 8, 4, 2]
  ))])

fig.update_layout(title_text="Basic Sankey Diagram", font_size=10)
fig
`;
