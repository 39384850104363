import { useState } from 'react';
import classNames from 'classnames';

import { IconButton, InlineButton } from '@/components/button';
import { Dropdown, DropdownMenuItem } from '@/components/dropdown';
import { SearchInput } from '@/components/form/search-input';
import { Icon } from '@/components/icon';
import {
  isDateField,
  isNumericField,
  setVisualisationValueKeys,
} from '@/explore/components/visualisation/utils';
import { fieldToOption } from '@/explore/edit-pipeline/utils';
import { getDereferencedPipelineFields } from '@/explore/pipeline/state';
import {
  DereferencedPipeline,
  Grouping,
  Metric,
  Model,
  QueryVariables,
  SimpleVisualisation,
} from '@/explore/types';
import { SortableItem } from '@/explore/components/sortable-item';

import formStyles from '../../../components/form/form.module.scss';

interface EditSimpleVisualisationProps {
  visualisation: SimpleVisualisation;
  pipeline: DereferencedPipeline;
  models: Model[];
  metrics: Metric[];
  variables: QueryVariables;
  getValueKeyMenuOptions: (key: string, idx: number) => DropdownMenuItem[];
  groups: Grouping[];
  onChange: (visualisation: SimpleVisualisation) => void;
}

export const EditSimpleVisualisation = (props: EditSimpleVisualisationProps) => {
  const {
    visualisation,
    pipeline,
    models,
    metrics,
    variables,
    getValueKeyMenuOptions,
    groups,
    onChange,
  } = props;
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const stateContext = { models, variables, metrics };
  const fields = getDereferencedPipelineFields(pipeline, stateContext);
  const options = visualisation.viewOptions ?? {};
  const bigNumberOptions = options.bigNumber;
  bigNumberOptions && 'comparisonKey' in bigNumberOptions
    ? bigNumberOptions?.comparisonKey
    : undefined;

  const handleValueKeysChange = (keys: string[]) => {
    onChange(setVisualisationValueKeys(visualisation, keys));
  };

  const handleMainAxisKeyChange = (key: string) => {
    onChange({
      ...visualisation,
      mainAxisKey: key,
    });
  };

  const handleMainAxisRemove = () => {
    onChange({
      ...visualisation,
      valueKeys: [],
      mainAxisKey: undefined,
    });
  };

  const handleValueKeysReorder = (fromIndex: number, toIndex: number) => {
    const tempKeys = [
      ...visualisation.valueKeys.slice(0, fromIndex),
      ...visualisation.valueKeys.slice(fromIndex + 1),
    ];
    return handleValueKeysChange([
      ...tempKeys.slice(0, toIndex),
      visualisation.valueKeys[fromIndex],
      ...tempKeys.slice(toIndex),
    ]);
  };

  const sortable = visualisation.valueKeys.length > 1;

  return (
    <div className={formStyles.formHorizontal}>
      {
        <>
          {visualisation.valueKeys.length > 0 &&
            visualisation.valueKeys.map((valueKey, idx) => {
              const dropdownItems = getValueKeyMenuOptions(valueKey, idx);
              return (
                <SortableItem
                  scope={'valuekeys'}
                  key={idx}
                  index={idx}
                  draggedIndex={draggedIndex}
                  setDraggedIndex={setDraggedIndex}
                  onReorder={handleValueKeysReorder}
                  className={classNames(formStyles.formHorizontal, {
                    [formStyles.sortableRow]: sortable,
                  })}
                  draggable={sortable}
                  classNames={{
                    dragBefore: formStyles.dragBefore,
                    dragAfter: formStyles.dragAfter,
                  }}>
                  {sortable && (
                    <Icon name="DragHandle" size={10} className={formStyles.sortHandle} />
                  )}
                  <div className={formStyles.formRow}>
                    <div className={formStyles.formLabel}>Field</div>
                    <SearchInput
                      options={fields.filter(isNumericField).map(fieldToOption)}
                      value={valueKey}
                      onChange={(key) => {
                        const valueKeys = visualisation.valueKeys.map((valueKey, i) =>
                          i === idx ? key : valueKey,
                        );
                        handleValueKeysChange(valueKeys);
                      }}
                    />
                    {dropdownItems.length > 0 ? (
                      <Dropdown
                        align="right"
                        trigger={(isOpen, setIsOpen) => (
                          <IconButton
                            icon="MoreHorizontal"
                            size="small"
                            title="More..."
                            type="gray"
                            onClick={() => setIsOpen(!isOpen)}
                          />
                        )}
                        items={dropdownItems}
                      />
                    ) : null}
                  </div>
                </SortableItem>
              );
            })}

          {visualisation.mainAxisKey !== undefined &&
          fields.some(
            (field) => !visualisation.valueKeys.includes(field.key) && isNumericField(field),
          ) ? (
            <div>
              <InlineButton
                size="small"
                onClick={() => {
                  const field = fields
                    .filter((field) =>
                      visualisation.valueKeys.every((valueKey) => valueKey !== field.key),
                    )
                    .find(isNumericField);

                  if (field === undefined) {
                    return;
                  }

                  handleValueKeysChange([...visualisation.valueKeys, field.key]);
                }}>
                <Icon name="Plus" size={15} /> Add field
              </InlineButton>
            </div>
          ) : null}

          {visualisation.mainAxisKey !== undefined ? (
            <div className={formStyles.formRow}>
              <div className={formStyles.formLabel}>Main axis</div>
              <SearchInput
                options={fields.map(fieldToOption)}
                value={visualisation.mainAxisKey}
                onChange={handleMainAxisKeyChange}
                disabled={groups.length > 0}
              />
              {groups.length === 0 ? (
                <IconButton
                  icon="Trash2"
                  title="Remove main axis"
                  size="small"
                  onClick={handleMainAxisRemove}
                />
              ) : null}
            </div>
          ) : null}
          {groups
            .filter((group) => group.key !== visualisation.mainAxisKey)
            .map((group, idx) => {
              return (
                <div key={idx} className={formStyles.formRow}>
                  <div className={formStyles.formLabel}>Grouped by</div>
                  <SearchInput
                    options={fields.map(fieldToOption)}
                    value={group.key}
                    onChange={() => {}}
                    disabled
                  />
                </div>
              );
            })}
          <div className={formStyles.formRow}>
            {visualisation.mainAxisKey === undefined ? (
              <InlineButton
                size="small"
                onClick={() => {
                  handleMainAxisKeyChange((fields.find(isDateField) ?? fields[0])?.key);
                }}>
                <Icon name="Plus" size={15} /> Add main axis
              </InlineButton>
            ) : null}
          </div>
        </>
      }
    </div>
  );
};
