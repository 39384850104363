import classNames from 'classnames';
import { formatDistance, isValid, parseISO } from 'date-fns';

import { Icon } from '@/components/icon';

import styles from './reference-block.module.scss';

export interface SlackReferenceProps {
  source: 'slack';
  url: string;
  avatar: string;
  username: string;
  date: string;
  content?: string;
}

export const SlackReference = ({ avatar, username, content, date }: SlackReferenceProps) => {
  const relativeTime = isValid(parseISO(date))
    ? formatDistance(new Date(date), new Date(), { addSuffix: true })
    : '';

  return (
    <div className={classNames([styles.layout, styles.slackBlock])}>
      <img src={avatar} className={styles.avatar} />
      <div className={styles.icon}>
        <Icon name="SlackIcon" size={16} />
      </div>
      <div className={styles.title}>
        @{username} wrote {content}
      </div>
      <div className={styles.time}>{relativeTime}</div>
    </div>
  );
};
