import { useRef, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import type { EditorState } from '@codemirror/state';
import { type EditorView, keymap } from '@codemirror/view';
import { indentWithTab } from '@codemirror/commands';

import { useCodeMirror, UseCodemirrorProps } from './use-code-mirror';

import styles from './codemirror.module.scss';

export interface CodeMirrorRef {
  editor?: HTMLDivElement | null;
  state?: EditorState;
  view?: EditorView;
}

export const CodeMirror = forwardRef<CodeMirrorRef, UseCodemirrorProps>((props, ref) => {
  const { className } = props;

  const editor = useRef<HTMLDivElement>(null);

  const { state, view } = useCodeMirror({
    // eslint-disable-next-line react-compiler/react-compiler
    container: editor.current,
    value: props.value,
    autoFocus: props.autoFocus,
    placeholder: props.placeholder,
    onChange: props.onChange,
    onKeyDown: props.onKeyDown,
    onInitialize: props.onInitialize,
    extensions: props.extensions,
  });

  useImperativeHandle(ref, () => ({ editor: editor.current, state: state, view: view }), [
    editor,
    state,
    view,
  ]);

  // This is needed for Esc+Tab to focus from editor to the next input in focus ring.
  const preventEscapeKeyHandling = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
    }
  };

  return (
    <div
      ref={editor}
      onKeyDown={preventEscapeKeyHandling}
      onKeyUp={preventEscapeKeyHandling}
      className={classNames(styles.editableInput, className)}
    />
  );
});

CodeMirror.displayName = 'CodeMirror';

export const tabIndentExtension = keymap.of([indentWithTab]);
export { lineNumbers } from '@codemirror/view';
