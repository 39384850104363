import { useMemo, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';

import classNames from 'classnames';

import styles from './skeleton-loader.module.scss';

export const Skeleton = ({ delay = 0, light = false }) => (
  <div
    className={classNames(styles.loader, {
      [styles.light]: light,
    })}
    style={{ animationDelay: `${delay}ms` }}
  />
);
const rowHeight = 44; // height 16 + gap 28

const columnWidths = [1, 3, 2, 1, 2, 1, 2, 1, 2, 1];

export interface TableSkeletonProps {
  rows: number;
  cols: number;
  type?: 'table' | 'list';
}

export const TableSkeleton = ({ rows, cols, type = 'list' }: TableSkeletonProps) => {
  const paddingUnit = Math.floor((1 / (cols / 5)) * 10);

  const tableRef = useRef<HTMLTableSectionElement>(null!);
  const { height: tableHeight = 0 } = useResizeObserver({
    ref: tableRef,
    box: 'content-box',
  });

  const calculatedRows = useMemo(() => {
    return Math.max(rows, Math.floor(tableHeight / rowHeight));
  }, [tableHeight, rows]);

  const gridTemplateColumns = useMemo(() => {
    return columnWidths
      .slice(0, cols)
      .map((w) => `${w}fr`)
      .join(' ');
  }, [cols]);

  return type === 'list' ? (
    <div className={styles.tableSkeleton} ref={tableRef}>
      {Array.from(Array(calculatedRows)).map((_, idx) => (
        <div
          className={styles.tableSkeletonRow}
          key={idx}
          style={{
            gridTemplateColumns: gridTemplateColumns,
          }}>
          {Array.from(Array(cols)).map((_, idy) => (
            <div
              key={idy}
              style={{
                paddingRight: `${
                  idy % cols === cols - 1 ? 0 : columnWidths[idy % cols] * paddingUnit
                }px`,
              }}>
              <Skeleton delay={idy * 50} />
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : (
    <tbody ref={tableRef}>
      {Array.from(Array(calculatedRows)).map((_, idx) => (
        <tr key={idx} className={styles.tableSkeletonTableRow}>
          {Array.from(Array(cols)).map((_, idy) => (
            <td key={idy} className={styles.tableSkeletonCell}>
              <Skeleton delay={idy * 50} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
