import { useEffect, useMemo, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useOnClickOutside, useResizeObserver } from 'usehooks-ts';
import classNames from 'classnames';

import { useScreenSize } from '@/lib/hooks';

import { Overlay } from '@/components/overlay';
import { InlineButton } from '@/components/button';

import { FormInputSize } from '../types';

import styles from './color-input.module.scss';

interface ColorPickerProps {
  color: string;
  presetColors?: string[];
  size?: FormInputSize;
  label?: string;
  onChange: (color: string | undefined) => void;
  className?: string;
}

const PositionPadding = 40;

type Position = {
  top: number;
  left: number;
};

const Popover = (props: {
  color: string;
  presetColors?: string[];
  position: Position;
  onChange: (color: string | undefined) => void;
  onClose: () => void;
}) => {
  const popover = useRef<HTMLDivElement>(null!);
  const isSelecting = useRef(false);

  const screenSize = useScreenSize();
  useOnClickOutside(popover, () => props.onClose());
  const popoverSize = useResizeObserver({
    ref: popover,
    box: 'content-box',
  });
  const [color, setColor] = useState(props.color);

  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  const position = useMemo(() => {
    if (popoverSize.width === undefined || popoverSize.height === undefined) {
      return { top: 0, left: 0, opacity: 0 };
    }
    const left =
      props.position.left + popoverSize.width + PositionPadding > screenSize.width
        ? screenSize.width - popoverSize.width - PositionPadding
        : props.position.left;
    const top =
      props.position.top + popoverSize.height + PositionPadding > screenSize.height
        ? screenSize.height - popoverSize.height - PositionPadding
        : props.position.top;
    return { top, left };
  }, [popoverSize.width, popoverSize.height, props.position, screenSize.width, screenSize.height]);

  function handleChange(color: string) {
    props.color !== color && props.onChange(color);
  }
  function handleReset() {
    props.color !== undefined && props.onChange(undefined);
  }

  return (
    <div
      ref={popover}
      className={styles.colorPopover}
      style={position}
      onMouseLeave={() => !isSelecting.current && props.onClose()}>
      <HexColorPicker
        color={props.color}
        onChange={setColor}
        onMouseDown={() => {
          isSelecting.current = true;
        }}
        onMouseUp={() => {
          isSelecting.current = false;
          handleChange(color);
        }}
      />
      {props.presetColors?.length !== undefined && props.presetColors.length > 0 && (
        <div className={styles.colorSwatches}>
          {props.presetColors.map((presetColor) => (
            <button
              key={presetColor}
              className={classNames(styles.colorSwatch, {
                [styles.selected]: props.color === presetColor,
              })}
              style={{ background: presetColor }}
              onClick={() => handleChange(presetColor)}
            />
          ))}
        </div>
      )}
      <InlineButton size="small" onClick={handleReset} className={styles.resetBtn}>
        Reset
      </InlineButton>
    </div>
  );
};

export const ColorPicker = (props: ColorPickerProps) => {
  const { color, presetColors, size = 'medium', label, onChange, className } = props;
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  return (
    <div className={classNames(styles.colorPicker, className)}>
      <div
        className={styles.colorLabel}
        onClick={({ clientX, clientY }) => setPosition({ top: clientY, left: clientX })}>
        <div
          className={classNames(styles.colorToggle, {
            [styles.toggleSmall]: size === 'small',
            [styles.toggleLarge]: size === 'large',
          })}
          style={{ backgroundColor: color }}
        />
        {label}
      </div>
      {position !== null && (
        <Overlay>
          <Popover
            color={color}
            presetColors={presetColors}
            position={position}
            onChange={onChange}
            onClose={() => setPosition(null)}
          />
        </Overlay>
      )}
    </div>
  );
};
