import { useEffect, useMemo, useState } from 'react';
import { first } from 'lodash';
import classNames from 'classnames';
import { sql as sqlLanguage } from '@codemirror/lang-sql';
import { model } from '@gosupersimple/types';

import { useAccountContext } from '@/lib/accounts/context';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { Loader } from '@/components/loader';
import { CodeMirror, lineNumbers, tabIndentExtension } from '@/components/codemirror';
import { ErrorBoundary, GenericFallback } from '@/lib/error';
import { useIsInView, useLoadingStatus, useQueryLoadCondition } from '@/lib/hooks';
import { useTrackEvent } from '@/lib/analytics';
import { filterVariablesForPipeline, sortProperties } from '@/explore/utils';
import { setCellTitle } from '@/core/cell';

import { HideInEmbedded } from '@/components/layout/hide-in-embedded';

import { DereferencedPipeline, Model, Sort, SortItem, SqlCell } from '../../types';
import {
  getNodes,
  mapSort,
  useExplorationDataLazyQuery,
  useExplorationDataQuery,
} from '../../../graphql';

import { PaginatedRecords } from '../../components/paginated-records';
import { HorizontalScrollTable } from '../../components/horizontal-scroll-table';
import { MasterBadge } from '../../components/master-badge';
import { DataTableProperty, DataTableRow } from '../../components/datatable';
import { CollapseButton, CollapsibleContainer, CollapsibleContent } from '../collapsible-cell';
import { CellTitle } from '../cell-title';
import { CellControls } from '../cell-controls';
import { useExplorationContext } from '../exploration-context';
import { useExplorationCellContext } from '../exploration-cell-context';
import { convertRecordTypeTypes } from '../../input';
import { useMetadataContext } from '../../metadata-context';
import { getSqlOperation } from './utils';
import { dereferencePipeline, getNumberOfChildPipelines } from '../../pipeline/utils';

import container from '../exploration.module.scss';
import style from './sqlcell.module.scss';
import editorStyle from '../code-editor.module.scss';
import table from '../../../components/table/table.module.scss';

interface SqlCellViewProps {
  cell: SqlCell;
  onSelectCell?: () => void;
  onSetDraggable: (value: boolean) => void;
}

export const SqlCellView = (props: SqlCellViewProps) => {
  const { exploration, createCellInstance, scrollToCell, selectCell } = useExplorationContext();
  const cell = props.cell;
  const { setCell, isCollapsible, isConversationCell, startConversation } =
    useExplorationCellContext();
  const [isDragHovered, setIsDragHovered] = useState(false);
  const trackEvent = useTrackEvent();

  const [containerRef, isInView] = useIsInView();

  const handleCreateCellInstance = () => {
    const { cell: cellInstance } = createCellInstance(cell.id);

    if (cellInstance !== undefined) {
      selectCell(cellInstance.id);
      scrollToCell(cellInstance.id);
    }

    trackEvent('Exploration Cell Instance Created', {
      explorationId: exploration.explorationId,
      explorationName: exploration.name,
      cell,
    });
  };

  const handleSetIsDragHovered = (value: boolean) => {
    props.onSetDraggable(value);
    setIsDragHovered(value);
  };

  const deleteAllowed = getNumberOfChildPipelines(exploration, cell.pipeline) === 0;

  return (
    <CollapsibleContainer
      className={container.cohortViewCell}
      onClick={props.onSelectCell}
      ref={containerRef}>
      <div className={container.cellHeader}>
        <div className={container.cellControlsContainer}>
          <HideInEmbedded>
            {!isConversationCell && (
              <Icon
                name="DragHandle"
                size={10}
                className={container.dragHandle}
                onMouseOver={() => handleSetIsDragHovered(true)}
                onMouseOut={() => handleSetIsDragHovered(false)}
              />
            )}
          </HideInEmbedded>
          <MasterBadge exploration={exploration} pipeline={props.cell.pipeline} />
          <CellTitle
            exploration={exploration}
            value={cell.title ?? '(Untitled)'}
            onChange={(value) => setCell(setCellTitle(cell, value))}
          />
          <CellControls
            exploration={exploration}
            editButtonVisible={false}
            canDelete={deleteAllowed}
            options={(defaultOptions) => [
              ...defaultOptions,
              {
                label: 'Ask about this block',
                icon: <Icon name="Zap" size={16} />,
                onClick: startConversation,
                sort: 1,
                disabled: isConversationCell,
                tooltip: isConversationCell
                  ? 'This block is already part of a conversation'
                  : undefined,
              },
              {
                type: 'divider',
                sort: 1,
              },
              {
                key: 'duplicate_as_instance',
                label: 'Duplicate as instance',
                icon: <Icon name="Instance" size={16} />,
                onClick: handleCreateCellInstance,
                sort: 15,
              },
            ]}
          />
          {isCollapsible && <CollapseButton />}
        </div>
      </div>
      <CollapsibleContent isDragHovered={isDragHovered}>
        <ErrorBoundary fallback={(errorData) => <GenericFallback {...errorData} />}>
          <SqlView cell={cell} setCell={setCell} isInView={isInView} />
        </ErrorBoundary>
      </CollapsibleContent>
    </CollapsibleContainer>
  );
};

interface SqlViewProps {
  cell: SqlCell;
  setCell: (cell: SqlCell) => void;
  isInView: boolean;
}

const SqlView = (props: SqlViewProps) => {
  const trackEvent = useTrackEvent();

  const sqlOperation = getSqlOperation(props.cell.pipeline);
  const sql = sqlOperation?.parameters.sql ?? '';

  const { account, isFeatureEnabled } = useAccountContext();
  const { models } = useMetadataContext();
  const { exploration, getVariables } = useExplorationContext();
  const { cell, isCollapsed } = useExplorationCellContext();
  const [onCompleted, onError, skip] = useQueryLoadCondition(
    props.isInView,
    !isCollapsed,
    sql !== '',
  );

  const baseModelId = first(models)?.modelId ?? '';

  const [sort, setSort] = useState<SortItem | null>(null);
  const [recordType, setRecordType] = useState<DataTableProperty[]>([]);

  const pipeline = useMemo(
    () => dereferencePipeline(props.cell.pipeline, exploration),
    [props.cell.pipeline, exploration],
  );
  const variables = filterVariablesForPipeline(pipeline, getVariables());

  const queryVariables = useMemo(
    () => ({
      accountId: account.accountId,
      baseModelId: pipeline.baseModelId,
      pipeline: pipeline.operations,
      sort: mapSort(sort === null ? [] : [sort]),
      variables,
      options: {
        labels: { exploration: exploration.explorationId, block: cell.id },
        optimize: isFeatureEnabled('optimizeQuery'),
      },
    }),
    [
      account.accountId,
      pipeline.baseModelId,
      pipeline.operations,
      sort,
      variables,
      exploration,
      cell,
      isFeatureEnabled,
    ],
  );

  const { data, loading, error, fetchMore } = useExplorationDataQuery({
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    skip,
    onCompleted: (data) => {
      const grouping = data.account?.query?.grouping;
      const groupedKeys = (grouping ?? []).map(({ key }) => key);

      setRecordType(
        sortProperties(convertRecordTypeTypes(data.account?.query?.recordType) ?? [], groupedKeys),
      );
      setPreLoading(false);
      onCompleted();

      trackEvent('Exploration Sql Cell Query Complete', {
        explorationId: exploration.explorationId,
        explorationName: exploration.name,
        cellId: props.cell.id,
        sql,
      });
    },
    onError: () => {
      setPreLoading(false);
      onError();

      trackEvent('Exploration Sql Cell Query Error', {
        explorationId: exploration.explorationId,
        name: exploration.name,
        cellId: props.cell.id,
        sql,
        error,
      });
    },
  });

  useEffect(() => {
    if (loading) {
      trackEvent('Exploration Sql Cell Query Loading', {
        explorationId: exploration.explorationId,
        explorationName: exploration.name,
        cellId: props.cell.id,
        sql,
      });
    }
  }, [loading, exploration.explorationId, exploration.name, props.cell.id, sql, trackEvent]);

  const loaded = data?.account?.query !== undefined;
  const records = getNodes(data?.account?.query);
  const groupedKeys = (data?.account?.query?.grouping ?? []).map(({ key }) => key);

  const handleFetchMore = async () => {
    if (loading || !(data?.account?.query?.pageInfo.hasNextPage ?? false)) {
      return;
    }

    fetchMore({ variables: { after: data?.account?.query?.pageInfo.endCursor } });

    trackEvent('Exploration Sql Cell Fetch More', {
      explorationId: exploration.explorationId,
      explorationName: exploration.name,
      cellId: props.cell.id,
      sql,
    });
  };

  const handleSortChange = (sort: SortItem | null) => {
    setSort(sort);

    trackEvent('Exploration Sql Cell Sort Change', {
      explorationId: exploration.explorationId,
      explorationName: exploration.name,
      cellId: props.cell.id,
      sql,
      sort,
    });
  };

  const [preLoading, setPreLoading] = useState(false);
  const [lazyExplorationDataQuery] = useExplorationDataLazyQuery();
  // When sql has changed, we need to know the fields of the new query to update the pipeline. Unfortunately
  // it needs extra exploration data query but it only retrieves the first record to get the fields.
  //
  // There is describeQuery GraphQL query that would be more suitable for this but it does not work with SQL
  // queries yet.
  const handleSqlChange = async (sql: string) => {
    const [sqlOperation, ...operations] = props.cell.pipeline.operations;
    if (sqlOperation.operation !== 'sql' || sqlOperation.parameters.sql === sql) {
      return;
    }

    setPreLoading(true);

    const { data } = await lazyExplorationDataQuery({
      variables: {
        accountId: account.accountId,
        baseModelId,
        pipeline: [{ operation: 'sql', parameters: { sql } }],
        first: 1,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });

    const fields =
      convertRecordTypeTypes(data?.account?.query?.recordType)
        ?.map(({ key, type }) => ({ key, type }))
        .filter((item): item is { key: string; type: model.PropertyType } => item.type !== null) ??
      [];

    props.setCell({
      ...props.cell,
      pipeline: {
        ...props.cell.pipeline,
        baseModelId,
        operations: [{ operation: 'sql', parameters: { sql, fields } }, ...operations],
      },
    });

    trackEvent('Exploration Sql Cell Sql Change', {
      explorationId: exploration.explorationId,
      explorationName: exploration.name,
      cellId: props.cell.id,
      sql,
    });
  };

  return (
    <>
      <div className={classNames(container.cellSection, style.inputSection)}>
        <SQLInputView
          sql={sql}
          setSql={handleSqlChange}
          sqlLoaded={loaded}
          error={error?.message}
        />
      </div>

      <div
        className={classNames(container.cellSection, container.tableSection, {
          [table.groupedTable]: groupedKeys.length > 0,
        })}>
        <SQLResponseView
          records={records}
          pipeline={pipeline}
          fetchMore={handleFetchMore}
          properties={recordType}
          sort={sort === null ? [] : [sort]}
          setSort={handleSortChange}
          loading={loading || preLoading}
          loaded={loaded && !preLoading}
        />
      </div>
    </>
  );
};

interface SQLInputViewProps {
  sql: string;
  setSql: (sql: string) => void;
  sqlLoaded: boolean;
  error?: string;
}

const SQLInputView = (props: SQLInputViewProps) => {
  const [sql, setSql] = useState(props.sql);
  const [sqlError, setSqlError] = useState<string | undefined>();
  const { isSelectedCell } = useExplorationCellContext();
  // Autofocus only on new empty blocks
  const autofocus = isSelectedCell && sql === '';

  useEffect(() => setSqlError(props.error), [props.error]);
  useEffect(() => setSql(props.sql), [props.sql]);

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setSqlError(undefined);

    if (sql === '') {
      return;
    }

    if (
      !sql.trimStart().toLowerCase().startsWith('select') &&
      !sql.trimStart().toLowerCase().startsWith('with')
    ) {
      setSqlError('Query must start with "SELECT" or "WITH"');
      return;
    }

    props.setSql(sql);
  };

  return (
    <div className={editorStyle.inputContainer}>
      <CodeMirror
        autoFocus={autofocus}
        value={sql}
        onChange={setSql.bind(this)} // Not sure why this is needed since React 19
        onKeyDown={onKeyDown.bind(this)} // Not sure why this is needed since React 19
        extensions={[sqlLanguage(), lineNumbers(), tabIndentExtension]}
        className={classNames(editorStyle.codeInput, {
          [editorStyle.hasError]: sqlError !== undefined,
        })}
      />

      <div className={editorStyle.buttons}>
        <Button variant="primary" size="small" onClick={handleSubmit}>
          Execute
        </Button>
      </div>

      {sqlError !== undefined && (
        <div className={style.errorContainer}>
          <Icon name="Alert" /> {sqlError}
        </div>
      )}
    </div>
  );
};

const createModelFromProperties = (properties: DataTableProperty[]): Model => ({
  modelId: 'sql-results',
  name: 'SQL',
  primaryKey: [],
  properties: properties.map((property) => ({
    ...property,
    type: property.type ?? 'String',
  })),
  relations: [],
  labels: {},
});

interface SQLResponseViewProps {
  records: DataTableRow[];
  pipeline: DereferencedPipeline;
  fetchMore: () => Promise<void>;
  properties: DataTableProperty[];
  sort: Sort;
  setSort: (sort: SortItem | null) => void;
  loaded: boolean;
  loading: boolean;
}

const SQLResponseView = (props: SQLResponseViewProps) => {
  const { cell } = useExplorationCellContext();
  const { isFirstLoad, isSubsequentLoad } = useLoadingStatus(props.loading, props.loaded);

  if (!props.loaded && !props.loading) {
    return (
      <div className={editorStyle.empty}>
        <div className={editorStyle.text}>Execute code to see results</div>
      </div>
    );
  }

  return (
    <PaginatedRecords
      properties={props.properties}
      pipeline={props.pipeline}
      sort={props.sort}
      setSort={props.setSort}
      records={props.records}
      model={createModelFromProperties(props.properties)}
      fetchMore={props.fetchMore}
      cellHeight={cell.viewOptions?.height}
      footerContent={isSubsequentLoad && <Loader />}
      canEditPipeline={false}
      loading={isFirstLoad}
      component={HorizontalScrollTable}
    />
  );
};
