import { useRef, useState } from 'react';
import classNames from 'classnames';

import { Button } from '@/components/button';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Panel } from '@/components/panel';

import styles from './consents-form.module.scss';
import formStyles from '@/components/form/form.module.scss';

export type ConsentSubject = 'TERMS_OF_SERVICE' | 'DATA_PROCESSING_AGREEMENT';

interface Consents {
  acceptedTerms: boolean;
  acceptedDpa: boolean;
}

const ConsentMap: { [key in keyof Consents]: ConsentSubject } = {
  acceptedTerms: 'TERMS_OF_SERVICE',
  acceptedDpa: 'DATA_PROCESSING_AGREEMENT',
} as const;

interface ConsentsFormProps {
  onSubmit: (acceptedSubjects: ConsentSubject[]) => void;
  loading?: boolean;
}

export const ConsentsForm = ({ onSubmit, loading }: ConsentsFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [data, setData] = useState<Consents>({
    acceptedTerms: false,
    acceptedDpa: false,
  });

  const allAccepted = data.acceptedTerms && data.acceptedDpa;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (allAccepted) {
      onSubmit(
        Object.entries(data)
          .filter(([, value]) => Boolean(value))
          .map(([key]) => ConsentMap[key as keyof Consents]),
      );
    }
  };

  return (
    <Panel className={styles.panel}>
      <div className={styles.penguin} />
      <div className={styles.content}>
        <header className={styles.header}>
          <h1 className={styles.heading}>Welcome!</h1>
          <h3 className={styles.subHeading}>Let’s get you set up</h3>
        </header>
        <form
          ref={formRef}
          className={classNames(styles.form, formStyles.formHorizontal)}
          onSubmit={handleSubmit}>
          <div className={formStyles.rowVertical}>
            <CheckboxWithLabel
              className={styles.checkboxWithLabel}
              checked={data.acceptedTerms}
              onChange={({ checked }) =>
                setData({
                  ...data,
                  acceptedTerms: checked,
                })
              }>
              <span>
                I have read and agree to the{' '}
                <a
                  className={styles.link}
                  href="https://www.supersimple.io/legal/terms"
                  target="_blank"
                  rel="noreferrer">
                  Terms of Service
                </a>
              </span>
            </CheckboxWithLabel>
          </div>
          <div className={formStyles.rowVertical}>
            <CheckboxWithLabel
              className={styles.checkboxWithLabel}
              checked={data.acceptedDpa}
              onChange={({ checked }) =>
                setData({
                  ...data,
                  acceptedDpa: checked,
                })
              }>
              <span>
                I have read and agree to the{' '}
                <a
                  className={styles.link}
                  href="https://www.supersimple.io/legal/dpa"
                  target="_blank"
                  rel="noreferrer">
                  Data Processing Agreement
                </a>
              </span>
            </CheckboxWithLabel>
          </div>
        </form>
        <Button
          variant="primary"
          size="large"
          onClick={() => formRef.current?.requestSubmit()}
          loading={loading}
          disabled={!allAccepted}>
          Continue
        </Button>
      </div>
    </Panel>
  );
};
