export const SlackIcon = ({ size = 12 }: { size?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Slack"
    role="img"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    strokeWidth="78"
    strokeLinecap="round">
    <path stroke="#36c5f0" d="m110 207h97m0-97h.1v-.1" />
    <path stroke="#2eb67d" d="m305 110v97m97 0v.1h.1" />
    <path stroke="#ecb22e" d="m402 305h-97m0 97h-.1v.1" />
    <path stroke="#e01e5a" d="M110 305h.1v.1m97 0v97" />
  </svg>
);
