import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';

import { FootnoteList } from '../../components/footnote/footnote-list';

export const FootnoteListNode = Node.create({
  name: 'footnoteListNode',
  type: 'footnoteListNode',
  group: 'block',
  atom: true,
  selectable: false,
  draggable: true,
  parseHTML() {
    return [{ tag: 'footnote-list-node' }];
  },
  renderText() {
    return ``;
  },
  renderHTML({ HTMLAttributes }) {
    return ['footnote-list-node', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(FootnoteList);
  },

  addProseMirrorPlugins() {
    const plugin = new PluginKey('autoAppendFootnotes');

    return [
      new Plugin({
        key: plugin,
        state: {
          init: () => {
            return true;
          },

          apply: (tr, value) => {
            if (!tr.docChanged) {
              return value;
            }

            const notes = [];
            const lists = [];
            tr.doc.descendants((node) => {
              if (node.type.name === 'footnoteListNode') {
                lists.push(node);
              } else if (node.type.name === 'footnoteNode') {
                notes.push(node);
              }
            });

            return lists.length > 0 || notes.length > 0;
          },
        },
        appendTransaction: (transactions, _, state) => {
          if (!transactions.some((tr) => tr.docChanged)) {
            return null;
          }

          const { tr, doc, schema } = state;

          const footnotes: any[] = [];
          const footnoteLists: number[] = [];
          doc.descendants((node, pos) => {
            if (node.type.name === 'footnoteNode') {
              footnotes.push(node);
            }
            if (node.type.name === 'footnoteListNode') {
              footnoteLists.push(pos);
            }
          });

          if (footnoteLists.length > 0) {
            footnoteLists.forEach((pos) => {
              tr.delete(pos, pos + 1);
            });
          }

          if (footnotes.length > 0) {
            const footnoteListNode = schema.nodes.footnoteListNode.create();
            tr.insert(tr.doc.content.size, footnoteListNode);
            return tr;
          }

          return null;
        },
      }),
    ];
  },
});
