import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { get, isNil } from 'lodash';

import {
  FloatingFocusManager,
  FloatingList,
  useFloating,
  useInteractions,
  useListNavigation,
} from '@floating-ui/react';

import { useTrackEvent } from '@/lib/analytics';
import { useIsScrolled } from '@/lib/hooks/use-is-scrolled';
import {
  buildExplorationFromCells,
  buildModelExploration,
  getExplorationSections,
  hasExplorationSection,
} from '@/core/exploration';
import { isReplicableCell } from '@/core/cell';
import { useBuildAccountUrl } from '@/lib/accounts/context';

import { Icon } from '../../components/icon';
import { Modal } from '../../components/modal';
import { Panel } from '../../components/panel';
import { NoMatchBanner } from '../../components/banner';
import { Cell, CellWithPipeline, Exploration, Model, ModelKind } from '../types';
import { buildExplorationHashUrl, buildExplorationUrl } from '../utils';
import { CollapsibleSection } from './collapsible-section';
import { CellList } from './cell-list';
import { ExplorationList } from './exploration-list';
import { dereferencePipeline } from '../pipeline/utils';
import { extractCellFromExploration } from '../exploration/utils';
import { getModelOrThrow, getModelKind } from '../model/utils';
import { CellListNode, ExplorationListNode, ExplorationNode, flattenLists } from '.';
import { InlineButton } from '../../components/button';
import { Tip } from '../../components/tip';
import { useMetadataContext } from '../metadata-context';

import styles from './exploration-search.module.scss';

const modelKindSectionTitles: { [key in ModelKind]: string } = {
  Account: 'Accounts',
  User: 'Users',
  Event: 'Events',
  Entity: 'Entities',
};

const isNotIgnoredCell = (cell: CellWithPipeline, ignoredPipelineIds: string[] = []) =>
  ignoredPipelineIds.length === 0 ||
  (cell.pipeline.pipelineId !== undefined &&
    !ignoredPipelineIds.includes(cell.pipeline.pipelineId));

interface PipelineSearchProps {
  explorations: Exploration[];
  exploration?: Exploration;
  onSelectModel: (model: Model) => void;
  onSelectCell: (cell: Cell, dependencies: Cell[]) => void;
  kinds?: ModelKind[];
  title?: string;
  className?: string;
  ignoredPipelineIds?: string[];
}

const PipelineSearch = (props: PipelineSearchProps) => {
  const {
    explorations,
    exploration,
    onSelectModel,
    onSelectCell,
    kinds,
    className,
    ignoredPipelineIds,
  } = props;
  const { models } = useMetadataContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [showOtherModels, setShowOtherModels] = useState(false);
  const otherModelsShown = useRef(false);
  const [isScrolled, scrollContainerRef] = useIsScrolled<HTMLDivElement>();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [collapsedLists, setCollapsedLists] = useState<Record<number, boolean>>({});
  const [collapsedItems, setCollapsedItems] = useState<Set<string>>(new Set());
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const otherModelsContainerRef = useRef<HTMLDivElement>(null);
  const trackEvent = useTrackEvent();
  const buildAccountUrl = useBuildAccountUrl();

  const isListCollapsed = (listIndex: number) => get(collapsedLists, listIndex, false);

  const toggleItemCollapse = (id: string) => {
    setCollapsedItems((collapsedItems) => {
      const set = new Set(collapsedItems);
      set.has(id) ? set.delete(id) : set.add(id);
      return set;
    });
  };

  const trimmedSearchTerm = searchTerm.trim().toLocaleLowerCase();

  const savedExplorations = useMemo(
    () =>
      explorations.filter((exploration) =>
        exploration.name.toLocaleLowerCase().includes(trimmedSearchTerm),
      ),
    [explorations, trimmedSearchTerm],
  );

  const modelExplorations = useMemo(
    () =>
      models
        .filter((model) => model.name.toLocaleLowerCase().includes(trimmedSearchTerm))
        .map((model) => buildModelExploration(model)),
    [models, trimmedSearchTerm],
  );

  const classifiedModelExplorations = useMemo(
    () =>
      modelExplorations.filter((exploration) => {
        const kind = getModelKind(exploration);
        return (kind !== null && kinds?.includes(kind)) ?? false;
      }),
    [kinds, modelExplorations],
  );

  const unclassifiedModelExplorations = useMemo(
    () =>
      modelExplorations.filter(
        (exploration) => classifiedModelExplorations.indexOf(exploration) === -1,
      ),
    [classifiedModelExplorations, modelExplorations],
  );

  const savedExplorationsWithReplicableCells = useMemo(
    () =>
      savedExplorations.filter(
        (exploration) => exploration.view.cells.filter(isReplicableCell).length > 0,
      ),
    [savedExplorations],
  );

  const modelSections = useMemo(
    () => getExplorationSections(unclassifiedModelExplorations),
    [unclassifiedModelExplorations],
  );

  const modelsWithoutSections = useMemo(
    () =>
      unclassifiedModelExplorations
        .filter((exploration) => !hasExplorationSection(exploration))
        .filter((exploration) => exploration.name.toLocaleLowerCase().includes(trimmedSearchTerm)),
    [trimmedSearchTerm, unclassifiedModelExplorations],
  );

  const onThisPageCells = useMemo(
    () =>
      exploration?.view.cells
        .filter(isReplicableCell)
        .filter((cell) => isNotIgnoredCell(cell, ignoredPipelineIds))
        .filter((cell) => cell.title?.toLocaleLowerCase().includes(trimmedSearchTerm) ?? false) ??
      [],
    [exploration?.view.cells, ignoredPipelineIds, trimmedSearchTerm],
  );

  const hasClassifiedModels = classifiedModelExplorations.length > 0;
  const hasUnclassifiedModels =
    savedExplorationsWithReplicableCells.length > 0 ||
    unclassifiedModelExplorations.length > 0 ||
    modelsWithoutSections.length > 0;

  const otherModelsButtonVisible = hasUnclassifiedModels && hasClassifiedModels;
  const otherModelsVisible = hasUnclassifiedModels && (!hasClassifiedModels || showOtherModels);

  useEffect(() => {
    trackEvent('Pipeline Search Opened');
  }, [trackEvent]);

  useEffect(() => {
    if (trimmedSearchTerm.length === 0) {
      return;
    }
    const timeout = setTimeout(() => {
      trackEvent('Pipeline Search', {
        searchTerm: trimmedSearchTerm,
        modelCount: modelExplorations.length,
      });
    }, 500);
    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedSearchTerm]);

  useEffect(() => {
    if (showOtherModels && !otherModelsShown.current) {
      otherModelsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    otherModelsShown.current = showOtherModels;
  }, [showOtherModels]);

  const handleModelExplorationClick = useCallback(
    (exploration: Exploration) => {
      if (!('pipeline' in exploration.view.cells[0])) {
        throw new Error('Cell does not have a pipeline');
      }
      const dereferencedPipeline = dereferencePipeline(
        exploration.view.cells[0].pipeline,
        exploration,
      );
      const model = getModelOrThrow(models, dereferencedPipeline.baseModelId);

      trackEvent('Pipeline Search Clicked Model', {
        model,
      });

      onSelectModel(model);
    },
    [models, onSelectModel, trackEvent],
  );

  const handleCellClick = useCallback(
    (cell: Cell, dependencies: Cell[]) => {
      trackEvent('Pipeline Search Clicked Cell', {
        cell,
        dependencies,
      });

      onSelectCell(cell, dependencies);
    },
    [onSelectCell, trackEvent],
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); //  Prevent explicit newlines in textarea
      flattenedItems.at(activeIndex !== null && activeIndex !== -1 ? activeIndex : 0)?.onClick?.();
    }
  };

  const handleToggleOtherModels = () => {
    setShowOtherModels(!showOtherModels);
    inputRef.current?.focus();
  };

  const classifiedLists: (ExplorationListNode | null)[] = useMemo(
    () =>
      (kinds ?? []).map((kind) => {
        const explorations = classifiedModelExplorations.filter(
          (exploration) => getModelKind(exploration) === kind,
        );
        if (explorations.length === 0) {
          return null;
        }
        return {
          title: modelKindSectionTitles[kind] ?? kind,
          children: explorations.map<ExplorationNode>((exploration) => {
            const url = buildAccountUrl(buildExplorationUrl(exploration));
            return {
              exploration,
              url,
              onClick: (event) => {
                event?.preventDefault();
                handleModelExplorationClick(exploration);
              },
            };
          }),
        };
      }),
    [buildAccountUrl, classifiedModelExplorations, handleModelExplorationClick, kinds],
  );

  const onThisPageList: CellListNode | null = useMemo(
    () =>
      exploration === undefined || onThisPageCells?.length === 0
        ? null
        : {
            title: 'On This Page',
            exploration,
            children: onThisPageCells.map((cell) => {
              const { cell: extractedCell, dependencies } = extractCellFromExploration(
                cell.id,
                exploration,
              );
              const outputExploration = buildExplorationFromCells([extractedCell, ...dependencies]);
              const url = buildAccountUrl(buildExplorationHashUrl(outputExploration));
              return {
                cell,
                url,
                onClick: (event) => {
                  event?.preventDefault();
                  handleCellClick(extractedCell, dependencies);
                },
              };
            }),
          },
    [buildAccountUrl, exploration, handleCellClick, onThisPageCells],
  );

  const unclassifiedLists: (ExplorationListNode | null)[] = useMemo(
    () => [
      otherModelsVisible && savedExplorationsWithReplicableCells.length
        ? {
            title: 'Saved Explorations',
            children: savedExplorationsWithReplicableCells.map((exploration) => {
              const url = buildAccountUrl(buildExplorationUrl(exploration));
              return {
                exploration,
                url,
                onClick: (event) => {
                  event?.preventDefault();
                  toggleItemCollapse(exploration.explorationId);
                },
                children: exploration.view.cells.filter(isReplicableCell).map((cell) => {
                  const { cell: extractedCell, dependencies } = extractCellFromExploration(
                    cell.id,
                    exploration,
                  );
                  const outputExploration = buildExplorationFromCells([
                    extractedCell,
                    ...dependencies,
                  ]);
                  const url = buildAccountUrl(buildExplorationHashUrl(outputExploration));
                  return {
                    cell,
                    url,
                    onClick: (event) => {
                      event?.preventDefault();
                      handleCellClick(extractedCell, dependencies);
                    },
                  };
                }),
              };
            }),
          }
        : null,
      ...(otherModelsVisible
        ? modelSections.map<ExplorationListNode>((section) => ({
            title: section,
            children: unclassifiedModelExplorations
              .filter(({ labels }) => labels.section === section)
              .map((exploration) => {
                const url = buildAccountUrl(buildExplorationUrl(exploration));
                return {
                  exploration,
                  url,
                  onClick: (event) => {
                    event?.preventDefault();
                    handleModelExplorationClick(exploration);
                  },
                };
              }),
          }))
        : []),
      otherModelsVisible
        ? {
            title: modelSections.length > 0 ? 'Other Data Models' : 'Raw Data Models',
            children: modelsWithoutSections.map((exploration) => {
              const url = buildAccountUrl(buildExplorationUrl(exploration));
              return {
                exploration,
                url,
                onClick: (event) => {
                  event?.preventDefault();
                  handleModelExplorationClick(exploration);
                },
              };
            }),
          }
        : null,
    ],
    [
      buildAccountUrl,
      handleCellClick,
      handleModelExplorationClick,
      modelSections,
      modelsWithoutSections,
      otherModelsVisible,
      savedExplorationsWithReplicableCells,
      unclassifiedModelExplorations,
    ],
  );
  const onThisPageIndex = classifiedLists.length ?? 0;
  const flattenedItems = flattenLists([...classifiedLists, onThisPageList, ...unclassifiedLists]);

  const { refs, context } = useFloating({
    open: true,
  });
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    virtual: true,
    loop: true,
    onNavigate: (index) => setActiveIndex(index),
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([listNavigation]);

  const renderList = (list: ExplorationListNode | null, index: number) => {
    if (list === null) {
      return null;
    }
    return (
      <CollapsibleSection
        key={index}
        title={list.title ?? 'Models'}
        isCollapsed={isListCollapsed(index)}
        onToggleCollapsed={() =>
          setCollapsedLists({ ...collapsedLists, [index]: !isListCollapsed(index) })
        }>
        <ExplorationList
          items={list.children}
          activeIndex={activeIndex}
          getItemProps={getItemProps}
          disabled={isListCollapsed(index)}
          collapsedItems={collapsedItems}
        />
      </CollapsibleSection>
    );
  };

  return (
    <Panel className={classNames(styles.panel, className)}>
      <section className={styles.explorationSearch} ref={refs.setFloating} {...getFloatingProps()}>
        <FloatingFocusManager context={context} initialFocus={-1}>
          <FloatingList elementsRef={listRef}>
            <div className={styles.searchHeader}>
              <h1>{props.title ?? 'Select Model'}</h1>
              <div className={styles.searchInput}>
                <Icon name="Search" size={24} className={styles.searchIcon} />
                <input
                  ref={refs.setReference}
                  value={searchTerm}
                  placeholder="Search your data models..."
                  autoFocus
                  {...getReferenceProps({
                    onKeyDown: handleKeyDown,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setSearchTerm(e.currentTarget.value);
                    },
                  })}
                />
              </div>
            </div>
            <div
              className={classNames(styles.allExplorations, {
                [styles.scrolled]: isScrolled,
              })}
              ref={scrollContainerRef}>
              {!hasClassifiedModels && (
                <Tip disableKey={'tip.modelKinds.disabledAt'}>
                  Did you know you can give additional context to your data models by labeling them
                  as events, users, accounts & entities? By doing that, Supersimple can make better
                  suggestions for you in the future.
                </Tip>
              )}
              {classifiedLists.map(renderList)}

              {exploration !== undefined && !isNil(onThisPageList) && (
                <CollapsibleSection
                  title="On This Page"
                  isCollapsed={isListCollapsed(onThisPageIndex)}
                  onToggleCollapsed={() =>
                    setCollapsedLists({
                      ...collapsedLists,
                      [onThisPageIndex]: !isListCollapsed(onThisPageIndex),
                    })
                  }>
                  <CellList
                    exploration={exploration}
                    items={onThisPageList.children}
                    activeIndex={activeIndex}
                    disabled={isListCollapsed(onThisPageIndex)}
                    getItemProps={getItemProps}
                  />
                </CollapsibleSection>
              )}

              {otherModelsButtonVisible && (
                <>
                  <hr />
                  <InlineButton
                    className={styles.otherModelsButton}
                    onClick={handleToggleOtherModels}>
                    {showOtherModels ? 'Hide other data models' : 'Other data models'}
                  </InlineButton>
                </>
              )}

              {otherModelsVisible && (
                <div ref={otherModelsContainerRef}>
                  {unclassifiedLists.map((list, i) => renderList(list, onThisPageIndex + 1 + i))}
                </div>
              )}

              {flattenedItems.length === 0 && <NoMatchBanner />}
            </div>
          </FloatingList>
        </FloatingFocusManager>
      </section>
    </Panel>
  );
};

interface PipelineSearchModalProps extends PipelineSearchProps {
  onClose: () => void;
}

export const PipelineSearchModal = ({ onClose, ...rest }: PipelineSearchModalProps) => (
  <Modal onClose={onClose} closeOnClickAway>
    <PipelineSearch {...rest} />
  </Modal>
);
