import { ApolloError } from '@apollo/client';

export const UserInitiatedAbort = 'UserAbortError';

export class AbortError extends Error {
  constructor(message = 'User cancelled the request') {
    super(message);
    this.name = UserInitiatedAbort;
  }
}

export const isAbortError = (error: ApolloError) => error.networkError?.name === UserInitiatedAbort;
