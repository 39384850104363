import { useEffect, useState } from 'react';

import { useDragHandle } from './use-drag-handle';

/**
 * A hook to handle resizing of an element by dragging a handle.
 *
 * The initial size is measured when the drag starts.
 * This allows the size to be controlled via CSS until it has been resized.
 * (This hook will return null in that case)
 *
 * @param handleRef Ref to the handle element.
 * @param targetRef Ref to the target element.
 * @param measure A function that returns the current size of the target element.
 * @param calculate A function that calculates the new size based on the current size and the delta.
 * @param minSize (optional) The minimum size of the target element.
 * @param resetOnDoubleClick (optional) Whether to reset the size (set to null) on double click of the handle.
 * @returns The current size of the target element (or null).
 */
export const useResizeHandle = <T extends HTMLElement, K extends HTMLElement>(
  handleRef: React.RefObject<T | null>,
  targetRef: React.RefObject<K | null>,
  measure: (target: K) => number,
  calculate: (size: number, delta: { dx: number; dy: number }) => number,
  apply: (target: K, size: number) => void,
  options: {
    minSize?: number;
    maxSize?: number;
    resetOnDoubleClick?: boolean;
  },
) => {
  const [size, setSize] = useState<number | null>(null);

  const minSize = options.minSize ?? 0;
  const maxSize = options.maxSize ?? Infinity;
  const resetOnDoubleClick = options.resetOnDoubleClick ?? true;

  useDragHandle<T>(
    handleRef,
    () => {
      if (targetRef.current !== null && size === null) {
        setSize(Math.min(maxSize, Math.max(minSize, measure(targetRef.current))));
      }
    },
    (dx, dy) => {
      if (targetRef.current !== null && size !== null) {
        apply?.(
          targetRef.current,
          Math.min(maxSize, Math.max(minSize, calculate(size, { dx, dy }))),
        );
      }
    },
    (dx, dy) => {
      if (size !== null) {
        setSize(Math.min(maxSize, Math.max(minSize, calculate(size, { dx, dy }))));
      }
    },
  );

  useEffect(() => {
    if (resetOnDoubleClick) {
      const handle = handleRef.current;
      const handleDoubleClick = () => {
        setSize(null);
      };
      handle?.addEventListener('dblclick', handleDoubleClick);
      return () => {
        handle?.removeEventListener('dblclick', handleDoubleClick);
      };
    }
  }, [handleRef, resetOnDoubleClick]);

  return size;
};
